import React, { useState,useEffect } from "react";
import axios from "axios";

import { Link, NavLink } from "react-router-dom";
import Logo from "../../assets/images/sidebarLogo.png";
import Home from "../../assets/icons/home.svg";
import Live from "../../assets/icons/live.svg";
import Pair from "../../assets/icons/liveexplorer.svg";
import { ReactComponent as TelegramIcon } from "../../assets/icons/telegram.svg";
import { ReactComponent as Twitter } from "../../assets/icons/twitter.svg";
import { ReactComponent as Website } from "../../assets/icons/website.svg";
import WallOfShame from "../../assets/icons/wallofshame.svg";

import Add from "../../assets/icons/add.svg";
import millify from "millify";

import Accordion from "../Accordion";
import "./Sidebar.scss";

interface PairData {
  pairAddress: string;

  baseToken: {
    symbol: string;
  };
  quoteToken: {
    symbol: string;
  };
  priceUsd: number;
  priceNative: number;

  volume: {
    h24: number;
  };
  priceChange: {
    h24: number;
  };
  // Add other properties based on the actual structure of your data
}


const Sidebar: React.FC = () => {
  const [pairData, setPairData] = useState<PairData | null>(null);

  useEffect(() => {
    const fetchPairData = async () => {
      try {
       
          const pairAddress = "0x9cc96215c993f9c8bf89d09b668041f301c5984f";
  
          const response = await axios.get(`https://openapi.dexview.com/latest/dex/pairs/bsc/${pairAddress}`);
          setPairData(response.data.pair);
        
      } catch (error) {
        console.error("Error fetching pair data:", error);
      }
    };
  
    fetchPairData();
  }, []);
  return (
    <div className="sidebar-wrapper">
      <div className="sidebar-container">
        <div className="wrapper">
          <Link to="/">
            <div className="logo flex-item">
              <img src={Logo} alt="" />
              <span>PROTRACKERS</span>
            </div>
          </Link>
          <nav>
            <NavLink to="/" className="list flex-item">
              <img src={Home} alt="" width={18} height={18} />
              <span>EagleEye Insight</span>
            </NavLink>
            <NavLink to="/live-new-pairs" className="list flex-item">
              <img src={Live} alt="" width={18} height={18} />
              <span>New Market Entries</span>
            </NavLink>
            <NavLink to="/pair-explorers?id=-O7xBwB6KeC9tlbfo9xM&chain=8bit"  className="list flex-item">
              <img src={Pair} alt="" width={18} height={18} />
              <span>Pair Analyzer</span>
            </NavLink>
            <NavLink to="/scam" className="list flex-item">
              <img src={WallOfShame} alt="" width={18} height={18} />
              <span>Wall of Shame</span>
            </NavLink>
            <NavLink to="/add" className="list flex-item">
              <img src={Add} alt="" width={18} height={18} />
              <span>List Coin</span>
            </NavLink>
            <Accordion />
          </nav>
          <div className="sidebar-footer">
          {pairData && (
            <div className="stats">
              <span>$ {millify(Number(pairData.priceUsd), { precision: 5 })}</span>
              <div></div>
            </div>
          )}
            <div className="controls">
             <button><a href="https://protrackers.finance/pair-explorers?id=-O7xBwB6KeC9tlbfo9xM&chain=8bit">Buy w8Bit </a></button> 
            </div>
            <div className="social-links">
              <a href="https://8bitchain.finance" target="_blank" rel="noopener noreferrer">
                <Website />
              </a>
              <a href="https://t.me/Official_8Bitchain" target="_blank" rel="noopener noreferrer">
                <TelegramIcon />
              </a>
              <a href="https://twitter.com/8Bit_chain" target="_blank" rel="noopener noreferrer">
                <Twitter />
              </a>
            </div>
            <p className="powered-by">
              Powered By <a href="https://8bitchain.finance">8Bit Chain</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
