import React, { useState, useEffect } from 'react';
import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";
import "./TopPairDataCard.scss";
import { ILiveData } from "../../constants/types";
import { ReactComponent as Icon } from "../../assets/icons/ai.svg"
import { initializeApp } from 'firebase/app';
import axios from "axios";
import { Link } from 'react-router-dom';

import { getDatabase, ref, onValue } from 'firebase/database';
import styled from 'styled-components';
import millify from "millify";
import { formatDistanceToNow } from 'date-fns';


interface PairData {
  pairAddress: string;

  baseToken: {
    symbol: string;
  };
  quoteToken: {
    symbol: string;
  };
  priceUsd: number;
  priceNative: number;

  volume: {
    h24: number;
  };
  priceChange: {
    h24: number;
  };

  liquidity:{
    base: number;
    quote:number;
    usd:number;
  }
  pairCreatedAt:any;

  txns:{
    h24:{
      buys:number;
      sells:number;
    }
  }
  // Add other properties based on the actual structure of your data
}

const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;
const LiveDataCard:  React.FC = () => {

  
// Firebase Configuration
const firebaseConfig = {
   apiKey: "AIzaSyBtG7oiNd9DLuA80uwsWzGG7zX7legmGPE",
  authDomain: "protracker-6c490.firebaseapp.com",
  databaseURL: "https://protracker-6c490-default-rtdb.firebaseio.com",
  projectId: "protracker-6c490",
  storageBucket: "protracker-6c490.appspot.com",
  messagingSenderId: "776220672739",
  appId: "1:776220672739:web:d71e515bb8f33673403bd6",
  measurementId: "G-GMYRXFBSRK"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const userDataRef = ref(database, 'ai');

const userDataRefs = ref(database, 'projects');


const ITEMS_PER_PAGE = 10;

const [searchTerm, setSearchTerm] = useState('');
const [searchTerms, setSearchTerms] = useState('');

const [userData, setUserData] = useState<any[]>([]);
const [userDatas, setUserDatas] = useState<any[]>([]);

const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true); // Add this line for loading state
  const [pairData, setPairData] = useState<PairData | null>(null);
  const [pairDataList, setPairDataList] = useState<PairData[]>([]);



useEffect(() => {
  onValue(userDataRef, (snapshot) => {
    const data = snapshot.val();
    const filteredData = data ? Object.values(data) : [];

    if (searchTerm) {
      const searchTermLC = searchTerm.toLowerCase();
      const searchResults = filteredData.filter((user: any) =>
        user.name.toLowerCase().includes(searchTermLC)
      );
      setUserData(searchResults.reverse());
    } else {
      setUserData(filteredData.reverse());
    }
          setLoading(false); // Data loading is complete

  });
}, [searchTerm]);


useEffect(() => {
  onValue(userDataRefs, (snapshot) => {
    const datas = snapshot.val();
    const filteredDatas = datas ? Object.values(datas) : [];

    if (searchTerms) {
      const searchTermLCs = searchTerms.toLowerCase();
      const searchResultss = filteredDatas.filter((user: any) =>
        user.name.toLowerCase().includes(searchTermLCs)
      );
      setUserDatas(searchResultss.reverse());
    } else {
      setUserDatas(filteredDatas.reverse());
    }
          setLoading(false); // Data loading is complete

  });
}, [searchTerms]);

const totalPages = Math.ceil(userData.length / ITEMS_PER_PAGE);
const lastItemIndex = currentPage * ITEMS_PER_PAGE;
const firstItemIndex = lastItemIndex - ITEMS_PER_PAGE;
const displayedUserData = userData;
const [isFocused, setIsFocused] = useState(false);

const handleFocus = () => {
  setIsFocused(true);
};

const handleBlur = () => {
  setIsFocused(false);
};

const handlePageChange = (newPage: number) => {
  setCurrentPage(newPage);
};

const handlePreviousPage = () => {
  if (currentPage > 1) {
    setCurrentPage(currentPage - 1);
  }
};

const handleNextPage = () => {
  if (currentPage < totalPages) {
    setCurrentPage(currentPage + 1);
  }
};

const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setSearchTerm(event.target.value);
};


useEffect(() => {
  const fetchPairData = async (user: any) => {
    try {
      const pairAddress = user.pairAddress;
      const chain = user.chain;

      const response = await axios.get(`https://openapi.dexview.com/latest/dex/pairs/${chain}/${pairAddress}`);
      const pairData: PairData = response.data.pair;
      setPairDataList((prevPairDataList) => [...prevPairDataList, pairData]);
    } catch (error) {
      console.error("Error fetching pair data:", error);
    }
  };

  const fetchDataSequentially = async () => {
    setPairDataList([]); // Reset pairDataList before fetching new data

    for (const user of userDatas) {
      await fetchPairData(user);
    }
    setLoading(false);
  };

  fetchDataSequentially();
}, [userDatas]);



const formatVolume = (volume:any) => {
  const abbreviations = ['K', 'M', 'B'];

  const format = (num:any, precision:any) => {
    const index = Math.floor(Math.log10(num) / 3);
    return (num / Math.pow(1000, index)).toFixed(precision) + abbreviations[index - 1];
  };

  return format(volume, 2); // You can adjust the precision as needed
};



const noProjectsMessage = (
  <tr>
    <td colSpan={4} style={{ textAlign: 'center',height:'70px' }}>
      No Project Found
    </td>
  </tr>
);

// Existing code...
const createTableRow = (user: any, index: number) => {

  const noProjects = user.nft1 === "-" && user.nft2 === "-" && user.nft3 === "-";

// If no projects found, show the no project message
if (noProjects) {
  return noProjectsMessage;
}

return (
  <tr key={user.userId || index} style={{ cursor: 'pointer' }}>
    {userData && (
      <>
     
        
          
          {/* Add the necessary data from 'projects' */}
          {userDatas.map((project: any, index: number) => {
  if (project.userId === user.nft1) {
    return (
      <tr key={project.userId}>
        {userDatas && (
          <>
          
          <td style={{color:'#ffba00'}}>#1</td>

          <td><img src={project.logoPicture} style={{width:20}} /></td>

            {pairDataList[index] && pairDataList[index].baseToken && pairDataList[index].quoteToken && (
              <td>
                <Link to={`/pair-explorers?id=${encodeURIComponent(project.userId)}`}>
                  {pairDataList[index].baseToken.symbol}/
                  <span style={{ fontSize: 12, color: '#cccccc' }}>
                    {pairDataList[index].quoteToken.symbol}
                  </span>
                
                </Link>
              </td>
            )}




            {pairDataList[index] && pairDataList[index].priceUsd != null && (
          <td >
            <Link className="percents" to={`/pair-explorers?id=${encodeURIComponent(project.userId)}`} style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red" }}>
              $ {millify(Number(pairDataList[index].priceUsd), { precision: 5 })}
            </Link>
          </td>
        )}

{pairDataList[index] && pairDataList[index].priceChange && (
          <td className="percent" style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red" }}>
  {new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
              }).format(pairDataList[index].priceChange.h24)}{" "} %          </td>
        )}
          </>
        )}
      </tr>
    );
  }
  return null;
})}


        
          {/* Add the necessary data from 'projects' */}
          {userDatas.map((project: any, index: number) => {
  if (project.userId === user.nft2) {
    return (
      <tr key={project.userId}>
        {userDatas && (
          <>
                    <td style={{color:'#ffba00'}}>#2</td>

                   <td><img src={project.logoPicture} style={{width:20}} /></td>


            {pairDataList[index] && pairDataList[index].baseToken && pairDataList[index].quoteToken && (
                              <Link to={`/pair-explorers?id=${encodeURIComponent(project.userId)}`}>

              <td style={{cursor:'pointer'}}>
                  {pairDataList[index].baseToken.symbol}/
                  <span style={{ fontSize: 12, color: '#cccccc' }}>
                    {pairDataList[index].quoteToken.symbol}
                  </span>
                
            
              </td>
              </Link>
            )}




            {pairDataList[index] && pairDataList[index].priceUsd != null && (
          <td>
            <Link className="percents" to={`/pair-explorers?id=${encodeURIComponent(project.userId)}`} style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red" }}>
              $ {millify(Number(pairDataList[index].priceUsd), { precision: 5 })}
            </Link>
          </td>
        )}

{pairDataList[index] && pairDataList[index].priceChange && (
          <td className="percent" style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red" }}>
  {new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
              }).format(pairDataList[index].priceChange.h24)}{" "} %          </td>
        )}
          </>
        )}
      </tr>
    );
  }
  return null;
})}



          {/* Add the necessary data from 'projects' */}
          {userDatas.map((project: any, index: number) => {
  if (project.userId === user.nft3) {
    return (
      <tr key={project.userId}>
        {userDatas && (
          <>
                    <td style={{color:'#ffba00'}}>#3</td>

                   <td><img src={project.logoPicture} style={{width:20}} /></td>


            {pairDataList[index] && pairDataList[index].baseToken && pairDataList[index].quoteToken && (
                              <Link to={`/pair-explorers?id=${encodeURIComponent(project.userId)}`}>

              <td style={{cursor:'pointer'}}>
                  {pairDataList[index].baseToken.symbol}/
                  <span style={{ fontSize: 12, color: '#cccccc' }}>
                    {pairDataList[index].quoteToken.symbol}
                  </span>
                
            
              </td>
              </Link>
            )}




            {pairDataList[index] && pairDataList[index].priceUsd != null && (
          <td>
            <Link className="percents" to={`/pair-explorers?id=${encodeURIComponent(project.userId)}`} style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red" }}>
              $ {millify(Number(pairDataList[index].priceUsd), { precision: 5 })}
            </Link>
          </td>
        )}

{pairDataList[index] && pairDataList[index].priceChange && (
          <td className="percent" style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red" }}>
  {new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
              }).format(pairDataList[index].priceChange.h24)}{" "} %          </td>
        )}
          </>
        )}
      </tr>
    );
  }
  return null;
})}
      </>
    )}
  </tr>
)};




  return (
    <div className="top-data-card-wrapper">
      <div className="border-animation-wrapper">
        <div className="border-animation"></div>
      </div>
      <div className="live-data-card-container">
        <div className="chat-ico">
          <div className="inner-chat"><Icon/></div>
        </div>

        <div className="live-data-card flex-item">
          <div className="daily-gainers-heading flex-item">
            <p>AI/Bots Projects</p>
          </div>
          <div className="more-content flex-item"></div>
        </div>
        
<table>
        <tbody>
              { (
                displayedUserData.map((user, index) => createTableRow(user, index))
                )}
            </tbody>

            </table>
            
        
      </div>
    </div>
  );
};

export default LiveDataCard;
