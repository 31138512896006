import React, { useState , useEffect} from "react";
import axios from "axios";
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';
import { PoolInfoData } from "../../../data/PoolInfo";
import { ReactComponent as ChevronDownIcon } from "../../../assets/icons/chevron-down.svg";
import { ReactComponent as TopArrowIcon } from "../../../assets/icons/topArrow.svg";
import "./PoolInfo.scss";
import Reserves from "../../PairsList/fetchReserves"
import FetchFirstReserve from "./fetchFirstReserves"


interface PriceData {
  priceUsd: number;
  price: number;

  priceNative: number;
  type: string;
  amount: number;
  received: number;
  time: string;
}

interface PriceResponse {
  [key: string]: PriceData; // This allows indexing by pair address
}

const PoolInfo: React.FC = () => {
  const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>({}); // Change 'any' to a more appropriate type
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyBtG7oiNd9DLuA80uwsWzGG7zX7legmGPE",
      authDomain: "protracker-6c490.firebaseapp.com",
      databaseURL: "https://protracker-6c490-default-rtdb.firebaseio.com",
      projectId: "protracker-6c490",
      storageBucket: "protracker-6c490.appspot.com",
      messagingSenderId: "776220672739",
      appId: "1:776220672739:web:d71e515bb8f33673403bd6",
      measurementId: "G-GMYRXFBSRK",
    };
  
    const app = initializeApp(firebaseConfig);
    const database = getDatabase(app);
  
    const id = new URLSearchParams(window.location.search).get('id');
  
    if (id) {
      const userDataRef = ref(database, `8bitprojects/${id}`);
      onValue(userDataRef, (snapshot) => {
        const fetchedData = snapshot.val();
        if (fetchedData) {
          console.log("Fetched Data: ", fetchedData); // Add this log
          setUserData(fetchedData);
        }
        setIsLoading(false);
      });
    }
  
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  
    return () => {
      clearTimeout(loadingTimeout);
    };
  }, []);
  
  const [priceChanges, setPriceChanges] = useState<{ [key: string]: number }>(
    {}
  ); // Store price changes

  const [prices, setPrices] = useState<{ [key: string]: number }>({});
  const [pprices, setPPrices] = useState<{ [key: string]: number }>({});
  const [pnativeprices, setPNativePrices] = useState<{ [key: string]: number }>(
    {}
  );
  const [nativeprices, setNativePrices] = useState<{ [key: string]: number }>(
    {}
  );
  const [tradingVolumes, setTradingVolumes] = useState<{
    [key: string]: number;
  }>({});

  const [buy, setBuy] = useState<{
    [key: string]: number;
  }>({});
  const [sell, setSell] = useState<{
    [key: string]: number;
  }>({});
  const [nativePrice, setNativePrice] = useState<number | null>(null);
  const fetchNativePrice = async () => {
    try {
      const response = await axios.get(
        "https://trade-c33f7-default-rtdb.firebaseio.com/nativePrice/.json"
      );
      const priceData = response.data;
      setNativePrice(priceData.price); // Assuming priceData is { price: <value> }
    } catch (error) {}
  };

  useEffect(() => {
    fetchNativePrice(); // Fetch native price on component mount
  }, []);

  const fetchTradingVolume = async (pairAddress: string) => {
    try {
      const url = pairAddress === "0xCa63D9144b4B66513298DE7b80Ff897ff4ca25C7"
      ? `https://trade-c33f7-default-rtdb.firebaseio.com/nativeTxnHistory/.json`
      : `https://trade-c33f7-default-rtdb.firebaseio.com/txnHistory/${pairAddress}/.json`;

      const response = await axios.get<PriceResponse>(url);
      const priceData = response.data;

      if (priceData) {
        const allEntries = Object.values(priceData);

        // Get today's date
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        // Filter today's entries
        const todaysEntries = allEntries.filter((entry) => {
          const entryDate = new Date(entry.time); // Ensure the date is parsed correctly
          return entryDate >= today; // Only include entries from today
        });

        return calculateVolume(todaysEntries); // Calculate and return the volume
        return calculateBuy(todaysEntries); // Calculate and return the volume
        return calculateSell(todaysEntries); // Calculate and return the volume

      }
    } catch (error) {}

    return 0; // Return 0 if there's an error or no data
  };

  const fetchBuy = async (pairAddress: string) => {
    try {
      const url = pairAddress === "0xCa63D9144b4B66513298DE7b80Ff897ff4ca25C7"
      ? `https://trade-c33f7-default-rtdb.firebaseio.com/nativeTxnHistory/.json`
      : `https://trade-c33f7-default-rtdb.firebaseio.com/txnHistory/${pairAddress}/.json`;

      const response = await axios.get<PriceResponse>(url);
      const priceData = response.data;

      if (priceData) {
        const allEntries = Object.values(priceData);

        // Get today's date
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        // Filter today's entries
        const todaysEntries = allEntries.filter((entry) => {
          const entryDate = new Date(entry.time); // Ensure the date is parsed correctly
          return entryDate >= today; // Only include entries from today
        });

        return calculateBuy(todaysEntries); // Calculate and return the volume

      }
    } catch (error) {}

    return 0; // Return 0 if there's an error or no data
  };


  const fetchSell = async (pairAddress: string) => {
    try {
      const url = pairAddress === "0xCa63D9144b4B66513298DE7b80Ff897ff4ca25C7"
      ? `https://trade-c33f7-default-rtdb.firebaseio.com/nativeTxnHistory/.json`
      : `https://trade-c33f7-default-rtdb.firebaseio.com/txnHistory/${pairAddress}/.json`;

      const response = await axios.get<PriceResponse>(url);
      const priceData = response.data;

      if (priceData) {
        const allEntries = Object.values(priceData);

        // Get today's date
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        // Filter today's entries
        const todaysEntries = allEntries.filter((entry) => {
          const entryDate = new Date(entry.time); // Ensure the date is parsed correctly
          return entryDate >= today; // Only include entries from today
        });

   
        return calculateSell(todaysEntries); // Calculate and return the volume

      }
    } catch (error) {}

    return 0; // Return 0 if there's an error or no data
  };

  const calculateVolume = (entries: any[]): number => {
    let volume = 0;
    let buy = 0;
    let sell = 0;

    entries.forEach((entry) => {
      const amount = Number(entry.amount);
      const received = Number(entry.received) || 0; // Default to 0 if not available

      if (entry.type === "buy") {
        volume += amount; // Add amount for buy transactions
        buy += 1
      } else if (entry.type === "sell") {
        sell += 1

      }
    });

    return volume;
  };

  const calculateBuy = (entries: any[]): number => {
    let buy = 0;

    entries.forEach((entry) => {
      const amount = Number(entry.amount);
      const received = Number(entry.received) || 0; // Default to 0 if not available

      if (entry.type === "buy") {
        buy += 1
      } else if (entry.type === "sell") {

      }
    });

    return buy;
  };

  const calculateSell = (entries: any[]): number => {

    let sell = 0;

    entries.forEach((entry) => {
      const amount = Number(entry.amount);
      const received = Number(entry.received) || 0; // Default to 0 if not available

      if (entry.type === "buy") {
 
      } else if (entry.type === "sell") {
        sell += 1

      }
    });

    return sell;
  };

  useEffect(() => {
    const fetchPrices = async () => {
      const newPrices: { [key: string]: number } = {};
      const previousPrices: { [key: string]: number } = {};
      const newPrice: { [key: string]: number } = {};
      const previousPrice: { [key: string]: number } = {};
      const tradingVolumes: { [key: string]: number } = {}; // Object to hold trading volumes
      const buy: { [key: string]: number } = {}; // Object to hold trading volumes
      const sell: { [key: string]: number } = {}; // Object to hold trading volumes

      // Get today's date
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set time to midnight

      // Assuming you want to fetch data for a specific pairAddress in userData
      const pairAddress = userData.pairAddress; // Change this if you have a different structure in userData

      try {
        tradingVolumes[pairAddress] = await fetchTradingVolume(pairAddress);
        buy[pairAddress] = await fetchBuy(pairAddress);
        sell[pairAddress] = await fetchSell(pairAddress);
      } catch (volumeError) {}

      try {
        const url = pairAddress === "0xCa63D9144b4B66513298DE7b80Ff897ff4ca25C7"
        ? `https://trade-c33f7-default-rtdb.firebaseio.com/nativeTxnHistory/.json`
        : `https://trade-c33f7-default-rtdb.firebaseio.com/txnHistory/${pairAddress}/.json`;
  
        const response = await axios.get<PriceResponse>(url);
        const priceData = response.data;

        if (priceData) {
          const allEntries = Object.values(priceData);

          const latestPriceEntry = allEntries.pop(); // Get the latest price entry
          if (latestPriceEntry) {
            newPrices[pairAddress] = 
            pairAddress === "0xCa63D9144b4B66513298DE7b80Ff897ff4ca25C7"
        ? latestPriceEntry.price
        : latestPriceEntry.priceUsd;            newPrice[pairAddress] = latestPriceEntry.priceNative;

            // Calculate previous prices
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            yesterday.setHours(0, 0, 0, 0); // Set to midnight
            const endOfYesterday = new Date();
            endOfYesterday.setDate(endOfYesterday.getDate() - 1);
            endOfYesterday.setHours(23, 59, 59, 999); // Set to the end of yesterday

            const yesterdayEntries = allEntries.filter((entry) => {
              const entryDate = new Date(entry.time);
              return entryDate >= yesterday && entryDate <= endOfYesterday; // Ensure 'yesterday' is in scope
            });

            if (yesterdayEntries.length > 0) {
              yesterdayEntries.sort(
                (a, b) =>
                  new Date(a.time).getTime() - new Date(b.time).getTime()
              );

              const previousPriceEntry =
                yesterdayEntries[yesterdayEntries.length - 1];

              if (previousPriceEntry) {
                previousPrice[pairAddress] = previousPriceEntry.priceNative;
                previousPrices[pairAddress] = previousPriceEntry.priceUsd;
              } else {
              }
            } else {
            }
          } else {
          }
        } else {
        }
      } catch (error) {}

      setPrices(newPrices);
      setNativePrices(newPrice);
      setPPrices(previousPrices);
      setPNativePrices(previousPrice);
      setTradingVolumes(tradingVolumes); // Set trading volumes state
      setBuy(buy); // Set trading volumes state
      setSell(sell); // Set trading volumes state

      // Calculate price change
      const priceChanges: { [key: string]: number } = {};
      const latestPrice = newPrice[pairAddress];
      const prevPrice = previousPrice[pairAddress];

      if (prevPrice) {
        priceChanges[pairAddress] =
          ((latestPrice - prevPrice) / prevPrice) * 100;
      } else {
        priceChanges[pairAddress] = 0;
      }

      setPriceChanges(priceChanges);
    };

    if (userData && userData.pairAddress) {
      fetchPrices();
    }
  }, [userData]);



  return (
    <div className="pool-info-wrapper">
      <div className="pool-info-container">
        
      {isLoading ? (
        <p>Loading...</p> // Show a loading indicator while data is being fetched
      ) : (
        <>
          {userData ? (
            <>
              <div className="poolInfo-content">
                <p>Pair Address</p>
                <p>{userData.pairAddress.slice(0, 5)}...{userData.pairAddress.slice(userData.pairAddress.length - 5)}</p>
              </div>

              <div className="poolInfo-content">
                <p>Total Volume (24h)</p>
                <p> $ {tradingVolumes[userData.pairAddress] && nativePrice
                ? (tradingVolumes[userData.pairAddress] * nativePrice).toFixed(6)
                : "N/A"}</p>
              </div>

              <div className="poolInfo-content">
                <p>Liquidity</p>
                <p>  <Reserves pairAddress={userData.pairAddress} where="info"/>
</p>
              </div>

              <div className="poolInfo-content">
              <FetchFirstReserve pairAddress={userData.pairAddress} token={0} />
              </div>

              <div className="poolInfo-content">
              <FetchFirstReserve pairAddress={userData.pairAddress} token={1} />

              </div>

              <div className="poolInfo-content">
                <p>Total Buys (24h)</p>
                <p>{buy[userData.pairAddress] 
                ? (buy[userData.pairAddress])
                : "N/A"}</p>
              </div>

              <div className="poolInfo-content">
                <p>Total Sells (24h)</p>
                <p>{sell[userData.pairAddress] 
                ? (sell[userData.pairAddress])
                : "N/A"}</p>
              </div>

              <div className="poolInfo-content">
                <p>Market Cap</p>
                <p>              $
              {prices[userData.pairAddress]
                ? (prices[userData.pairAddress]*userData.supply).toFixed(2)
                : "N/A"}</p>
              </div>
            </>
          ) : (
            <p>No data available.</p>
          )}
        </>
      )}

      </div>
      
    </div>
  );
};

export default PoolInfo;
