import React, { useState, useEffect } from 'react';
import { ReactComponent as HotPair } from "../../assets/icons/hotPairs.svg";
import { ReactComponent as IIcon } from "../../assets/icons/i.svg";
import { ReactComponent as ChevronDownIcon } from "../../assets/icons/chevron-down.svg";
import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";
import { ReactComponent as ProfitIcon } from "../../assets/icons/profit.svg";
import { ReactComponent as LossIcon } from "../../assets/icons/loss.svg";
import tagImage from "../../assets/images/profile-img2.png";
import ProfileImg from "../../assets/images/profile-img.png";
import { hotPairs } from "../../data/hotPairs";
import "./HotPairs.scss";
import Avatar from "../Avatar";
import BNB from "../../assets/logos/bsc.png";
import BIT from "../../assets/images/LogoImg.png";
import { initializeApp } from 'firebase/app';
import axios from "axios";
import { Link } from 'react-router-dom';

import { getDatabase, ref, onValue } from 'firebase/database';
import styled from 'styled-components';
import millify from "millify";
import { formatDistanceToNow } from 'date-fns';


interface PriceData {
  priceUsd: number;
  priceNative: number;
  type: string;
  amount: number;
  received: number;
  time: string;
  price: number;
}

interface PriceResponse {
  [key: string]: PriceData; // This allows indexing by pair address
}


interface PairData {
  pairAddress: string;

  baseToken: {
    symbol: string;
  };
  quoteToken: {
    symbol: string;
  };
  priceUsd: number;
  priceNative: number;

  volume: {
    h24: number;
  };
  priceChange: {
    h24: number;
  };

  liquidity:{
    base: number;
    quote:number;
    usd:number;
  }
  pairCreatedAt:any;

  txns:{
    h24:{
      buys:number;
      sells:number;
    }
  }
  // Add other properties based on the actual structure of your data
}


const Section = styled.section`
  padding: 20px 0;
  @media (max-width: 768px) {
    max-width:100%;
    overflow:auto
    margin-left:50px;
  }
  
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const SearchContainer = styled.div`
  margin: 20px 0;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 30px;
  @media (max-width: 768px) {
    max-width:100%;
    overflow:auto;

  }
 
`;
const TableRow = styled.tr`
`;

const TableData = styled.td`
  padding: 10px;
  font-size:14px;
  // width:fit-content;

`;

const ProfileImage = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;

const Button = styled.button`
  background-color: #23323c;
  color: #ffba00;
  border:1px solid #000;
  padding: 8px 15px;
  border-radius: 7px;
  cursor: pointer;
`;
const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const PaginationButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const PageNumber = styled.div`
  font-size: 14px;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px; /* Adjust the height as needed */
`;


const Header = styled.div`
  display:grid;
  grid-template-columns:1fr 1fr;
  margin-bottom:20px;

  @media (max-width: 768px) {
    grid-template-columns:1fr;

  }

`;


const MainTable = styled.div`
  width: 100%; /* Adjust the width for mobile screens */
  background: var(--background);
  padding: 0 20px 20px;
  border-radius: 10px;
 


  .tablescroll{
    overflow-x: auto;
   
    &::-webkit-scrollbar {
      height: 2px; /* Adjust the scrollbar width as needed */
    }

    &::-webkit-scrollbar-track {
      background: #000; /* Track color */
    }
    
    /* Scrollbar Handle */
    &::-webkit-scrollbar-thumb {
      background: #a37800; /* Handle color */
    }
    
    /* Scrollbar Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #a37800; /* Handle color on hover */
    }
    
  
    // Add mobile styles here
    @media (max-width: 768px) {
      // Adjust styles for smaller screens
      width: 100%;
    }

  }
`;



const TableHead = styled.thead`
  background-color: #000;
  padding:5px;
  height:55px;

  border:none;

  th{
    padding: 10px;
    text-align: left;
    font-weight: bold;
    margin-bottom:20px;
  }

  tr{
    padding:5px;
  }
`;

const HotPairs: React.FC = () => {


// Firebase Configuration
const firebaseConfig = {
   apiKey: "AIzaSyBtG7oiNd9DLuA80uwsWzGG7zX7legmGPE",
  authDomain: "protracker-6c490.firebaseapp.com",
  databaseURL: "https://protracker-6c490-default-rtdb.firebaseio.com",
  projectId: "protracker-6c490",
  storageBucket: "protracker-6c490.appspot.com",
  messagingSenderId: "776220672739",
  appId: "1:776220672739:web:d71e515bb8f33673403bd6",
  measurementId: "G-GMYRXFBSRK"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const userDataRef = ref(database, 'buzz');

const userDataRefs = ref(database, 'projects');


const ITEMS_PER_PAGE = 10;

const [searchTerm, setSearchTerm] = useState('');
const [searchTerms, setSearchTerms] = useState('');

const [userData, setUserData] = useState<any[]>([]);
const [userDatas, setUserDatas] = useState<any[]>([]);

const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true); // Add this line for loading state
  const [pairData, setPairData] = useState<PairData | null>(null);
  const [pairDataList, setPairDataList] = useState<PairData[]>([]);
  const [eightBitUserDatas, setEightBitUserDatas] = useState<any[]>([]); // State for 8bit projects
  const bitUserDataRefs = ref(database, "8bitprojects");



useEffect(() => {
  onValue(userDataRef, (snapshot) => {
    const data = snapshot.val();
    const filteredData = data ? Object.values(data) : [];

    if (searchTerm) {
      const searchTermLC = searchTerm.toLowerCase();
      const searchResults = filteredData.filter((user: any) =>
        user.name.toLowerCase().includes(searchTermLC)
      );
      setUserData(searchResults.reverse());
    } else {
      setUserData(filteredData.reverse());
    }
          setLoading(false); // Data loading is complete

  });
}, [searchTerm]);


useEffect(() => {
  onValue(userDataRefs, (snapshot) => {
    const datas = snapshot.val();
    const filteredDatas = datas ? Object.values(datas) : [];

    if (searchTerms) {
      const searchTermLCs = searchTerms.toLowerCase();
      const searchResultss = filteredDatas.filter((user: any) =>
        user.name.toLowerCase().includes(searchTermLCs)
      );
      setUserDatas(searchResultss.reverse());
    } else {
      setUserDatas(filteredDatas.reverse());
    }
          setLoading(false); // Data loading is complete

  });
}, [searchTerms]);

useEffect(() => {
  onValue(bitUserDataRefs, (snapshot) => {
    const datas = snapshot.val();
    const filteredDatas = datas ? Object.values(datas) : [];

    if (searchTerms) {
      const searchTermLCs = searchTerms.toLowerCase();
      const searchResultss = filteredDatas.filter((user: any) =>
        user.name.toLowerCase().includes(searchTermLCs)
      );
      setEightBitUserDatas(searchResultss.reverse());
    } else {
      setEightBitUserDatas(filteredDatas.reverse());
    }
    setLoading(false); // Data loading is complete
  });
}, [searchTerms]);

const totalPages = Math.ceil(userData.length / ITEMS_PER_PAGE);
const lastItemIndex = currentPage * ITEMS_PER_PAGE;
const firstItemIndex = lastItemIndex - ITEMS_PER_PAGE;
const displayedUserData = userData;
const [isFocused, setIsFocused] = useState(false);

const handleFocus = () => {
  setIsFocused(true);
};

const handleBlur = () => {
  setIsFocused(false);
};

const handlePageChange = (newPage: number) => {
  setCurrentPage(newPage);
};

const handlePreviousPage = () => {
  if (currentPage > 1) {
    setCurrentPage(currentPage - 1);
  }
};

const handleNextPage = () => {
  if (currentPage < totalPages) {
    setCurrentPage(currentPage + 1);
  }
};


const [priceChanges, setPriceChanges] = useState<{ [key: string]: number }>(
  {}
); // Store price changes

const [prices, setPrices] = useState<{ [key: string]: number }>({});
const [pprices, setPPrices] = useState<{ [key: string]: number }>({});
const [pnativeprices, setPNativePrices] = useState<{ [key: string]: number }>(
  {}
);
const [nativeprices, setNativePrices] = useState<{ [key: string]: number }>(
  {}
);
const [tradingVolumes, setTradingVolumes] = useState<{
  [key: string]: number;
}>({});

useEffect(() => {
  const fetchPrices = async () => {
    const newPrices: { [key: string]: number } = {};
    const previousPrices: { [key: string]: number } = {};
    const newPrice: { [key: string]: number } = {};
    const previousPrice: { [key: string]: number } = {};

    // Get today's date
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set time to midnight

    await Promise.all(
      eightBitUserDatas.map(async (user) => {
        const { pairAddress } = user;

        // Fetch trading volume separately

        try {
          const url =
            pairAddress === "0xCa63D9144b4B66513298DE7b80Ff897ff4ca25C7"
              ? `https://trade-c33f7-default-rtdb.firebaseio.com/nativeTxnHistory/.json`
              : `https://trade-c33f7-default-rtdb.firebaseio.com/txnHistory/${pairAddress}/.json`;

          const response = await axios.get<PriceResponse>(url);
          const priceData = response.data;

          if (priceData) {
            const allEntries = Object.values(priceData);

            const latestPriceEntry = allEntries.pop(); // Get the latest price entry
            if (latestPriceEntry) {
              newPrices[pairAddress] =
                pairAddress === "0xCa63D9144b4B66513298DE7b80Ff897ff4ca25C7"
                  ? latestPriceEntry.price
                  : latestPriceEntry.priceUsd;
              newPrice[pairAddress] = latestPriceEntry.priceNative;

              // Calculate previous prices
              const yesterday = new Date();
              yesterday.setDate(yesterday.getDate() - 1);
              yesterday.setHours(0, 0, 0, 0); // Set to midnight
              const endOfYesterday = new Date();
              endOfYesterday.setDate(endOfYesterday.getDate() - 1);
              endOfYesterday.setHours(23, 59, 59, 999); // Set to the end of yesterday

              const yesterdayEntries = allEntries.filter((entry) => {
                const entryDate = new Date(entry.time);
                return entryDate >= yesterday && entryDate <= endOfYesterday; // Ensure 'yesterday' is in scope
              });

              if (yesterdayEntries.length > 0) {
                yesterdayEntries.sort(
                  (a, b) =>
                    new Date(a.time).getTime() - new Date(b.time).getTime()
                );

                const previousPriceEntry =
                  yesterdayEntries[yesterdayEntries.length - 1];

                if (previousPriceEntry) {
                  previousPrice[pairAddress] = previousPriceEntry.priceNative;
                  previousPrices[pairAddress] = previousPriceEntry.priceUsd;
                } else {
                }
              } else {
              }
            }
          } else {
          }
        } catch (error) {}
      })
    );

    console.log(newPrices);
    setPrices(newPrices);
    setNativePrices(newPrice);
    setPPrices(previousPrices);
    setPNativePrices(previousPrice);
    setTradingVolumes(tradingVolumes); // Set trading volumes state

    const priceChanges: { [key: string]: number } = {};
    Object.keys(newPrices).forEach((pairAddress) => {
      const latestPrice = newPrice[pairAddress];
      const prevPrice = previousPrice[pairAddress];

      if (prevPrice) {
        priceChanges[pairAddress] =
          ((latestPrice - prevPrice) / prevPrice) * 100;
      } else {
        priceChanges[pairAddress] = 0;
      }
    });

    setPriceChanges(priceChanges);
  };

  if (userData.length > 0) {
    fetchPrices();
  }
}, [userData]);


const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setSearchTerm(event.target.value);
};


useEffect(() => {
  const fetchPairData = async (user: any) => {
    try {
      const pairAddress = user.pairAddress;
      const chain = user.chain;

      const response = await axios.get(`https://openapi.dexview.com/latest/dex/pairs/${chain}/${pairAddress}`);
      const pairData: PairData = response.data.pair;
      setPairDataList((prevPairDataList) => [...prevPairDataList, pairData]);
    } catch (error) {
      console.error("Error fetching pair data:", error);
    }
  };

  const fetchDataSequentially = async () => {
    setPairDataList([]); // Reset pairDataList before fetching new data

    for (const user of userDatas) {
      await fetchPairData(user);
    }
    setLoading(false);
  };

  fetchDataSequentially();
}, [userDatas]);



const formatVolume = (volume:any) => {
  const abbreviations = ['K', 'M', 'B'];

  const format = (num:any, precision:any) => {
    const index = Math.floor(Math.log10(num) / 3);
    return (num / Math.pow(1000, index)).toFixed(precision) + abbreviations[index - 1];
  };

  return format(volume, 2); // You can adjust the precision as needed
};



const noProjectsMessage = (
  <tr>
    <TableData colSpan={4} style={{ textAlign: 'center',height:'100px' }}>
      No Project Found
    </TableData>
  </tr>
);

// Existing code...
const createTableRow = (user: any, index: number) => (
  <tr key={user.userId || index} style={{ cursor: 'pointer' }}>
    {userData && (
      <>
     
        
          
          {/* Add the necessary data from 'projects' */}
          {userDatas.map((project: any, index: number) => {
  if (project.userId === user.nft1 && user.chain1 === "bsc") {
    return (
      <tr key={project.userId}>
        {userDatas && (
          <>
          
          <TableData style={{color:'#ffba00'}}>#1</TableData>

          <TableData>
          <div style={{display:'flex',alignItems:'start'}}>
                        <img className="poklo" src={project.logoPicture}  />
                        <img className="pokool" src={BNB}  />
                        </div></TableData>

            {pairDataList[index] && pairDataList[index].baseToken && pairDataList[index].quoteToken && (
              <TableData>
                <Link to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('bsc')}`}>
                  {pairDataList[index].baseToken.symbol}/
                  <span style={{ fontSize: 10, color: '#cccccc' }}>
                    {pairDataList[index].quoteToken.symbol}
                  </span>
                
                </Link>
              </TableData>
            )}




            {pairDataList[index] && pairDataList[index].priceUsd != null && (
          <TableData >
            <Link className="percents" to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('8bit')}`} style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red", fontSize:12 }}>
              $ {millify(Number(pairDataList[index].priceUsd), { precision: 5 })}
            </Link>
          </TableData>
        )}

{pairDataList[index] && pairDataList[index].priceChange && (
          <TableData className="percent" style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red", fontSize:12 }}>
            {new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              }).format(pairDataList[index].priceChange.h24)}{" "} %
          </TableData>
        )}
          </>
        )}
      </tr>
    );
  }
  return null;
})}


{eightBitUserDatas.map((project: any, index: number) => {
  if (project.userId === user.nft1 && user.chain1 === "8bit") {
    return (
      <tr key={project.userId}>
        {eightBitUserDatas && (
          <>
          
          <TableData style={{color:'#ffba00'}}>#1</TableData>

          <TableData> <div style={{display:'flex',alignItems:'start'}}>
                        <img className="poklo" src={project.logoPicture}  />
                        <img className="pokool" src={BIT}  />
                        </div></TableData>

              <TableData>
                <Link to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('8bit')}`}>
                  {project.token0symbol}/
                  <span style={{ fontSize: 10, color: '#cccccc' }}>
                    {project.token1symbol}
                  </span>
                
                </Link>
              </TableData>
   




          <TableData >
          <Link
                          className="percents"
                          to={`/pair-explorers?id=${encodeURIComponent(
                            project.userId
                          )}`}
                          style={{
                            color:
                              priceChanges[project.pairAddress] >= 0
                                ? "green"
                                : "red", fontSize:12
                          }}
                        >
                          $
                          {prices[project.pairAddress]
                            ? prices[project.pairAddress].toFixed(6)
                            : "N/A"}
                        </Link>
          </TableData>


          <TableData    className="percent"
                        style={{
                          color:
                            priceChanges[project.pairAddress] >= 0
                              ? "green"
                              : "red", fontSize:12
                        }}
                      >
                        {" "}
                        {priceChanges[project.pairAddress] !== undefined
                          ? `${priceChanges[project.pairAddress]?.toFixed(2)}%`
                          : "N/A"}
          </TableData>
     
          </>
        )}
      </tr>
    );
  }
  return null;
})}


        
          {/* Add the necessary data from 'projects' */}
          {userDatas.map((project: any, index: number) => {
  if (project.userId === user.nft2 && user.chain2 === "bsc") {
    return (
      <tr key={project.userId}>
        {userDatas && (
          <>
                    <TableData style={{color:'#ffba00'}}>#2</TableData>

                   <TableData><div style={{display:'flex',alignItems:'start'}}>
                        <img className="poklo" src={project.logoPicture}  />
                        <img className="pokool" src={BNB}  />
                        </div></TableData>


            {pairDataList[index] && pairDataList[index].baseToken && pairDataList[index].quoteToken && (
                              <Link to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('bsc')}`}>

              <TableData style={{cursor:'pointer'}}>
                  {pairDataList[index].baseToken.symbol}/
                  <span style={{ fontSize: 10, color: '#cccccc' }}>
                    {pairDataList[index].quoteToken.symbol}
                  </span>
                
            
              </TableData>
              </Link>
            )}




            {pairDataList[index] && pairDataList[index].priceUsd != null && (
          <TableData>
            <Link className="percents" to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('bsc')}`} style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red", fontSize:12 }}>
              $ {millify(Number(pairDataList[index].priceUsd), { precision: 5 })}
            </Link>
          </TableData>
        )}

{pairDataList[index] && pairDataList[index].priceChange && (
          <TableData className="percent" style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red", fontSize:12 }}>
            {new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              }).format(pairDataList[index].priceChange.h24)}{" "} %
          </TableData>
        )}
          </>
        )}
      </tr>
    );
  }
  return null;
})}




{eightBitUserDatas.map((project: any, index: number) => {
  if (project.userId === user.nft2 && user.chain2 === "8bit") {
    return (
      <tr key={project.userId}>
        {eightBitUserDatas && (
          <>
          
          <TableData style={{color:'#ffba00'}}>#2</TableData>

          <TableData><div style={{display:'flex',alignItems:'start'}}>
                        <img className="poklo" src={project.logoPicture}  />
                        <img className="pokool" src={BIT}  />
                        </div></TableData>

              <TableData>
                <Link to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('8bit')}`}>
                  {project.token0symbol}/
                  <span style={{ fontSize: 10, color: '#cccccc' }}>
                    {project.token1symbol}
                  </span>
                
                </Link>
              </TableData>
   




          <TableData >
          <Link
                          className="percents"
                          to={`/pair-explorers?id=${encodeURIComponent(
                            project.userId
                          )}`}
                          style={{
                            color:
                              priceChanges[project.pairAddress] >= 0
                                ? "green"
                                : "red", fontSize:12
                          }}
                        >
                          $
                          {prices[project.pairAddress]
                            ? prices[project.pairAddress].toFixed(6)
                            : "N/A"}
                        </Link>
          </TableData>


          <TableData    className="percent"
                        style={{
                          color:
                            priceChanges[project.pairAddress] >= 0
                              ? "green"
                              : "red", fontSize:12
                        }}
                      >
                        {" "}
                        {priceChanges[project.pairAddress] !== undefined
                          ? `${priceChanges[project.pairAddress]?.toFixed(2)}%`
                          : "N/A"}
          </TableData>
     
          </>
        )}
      </tr>
    );
  }
  return null;
})}


          {/* Add the necessary data from 'projects' */}
          {userDatas.map((project: any, index: number) => {
  if (project.userId === user.nft3 && user.chain3 === "bsc") {
    return (
      <tr key={project.userId}>
        {userDatas && (
          <>
                    <TableData style={{color:'#ffba00'}}>#3</TableData>

                   <TableData><div style={{display:'flex',alignItems:'start'}}>
                        <img className="poklo" src={project.logoPicture}  />
                        <img className="pokool" src={BNB}  />
                        </div></TableData>


            {pairDataList[index] && pairDataList[index].baseToken && pairDataList[index].quoteToken && (
                              <Link to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('bsc')}`}>

              <TableData style={{cursor:'pointer'}}>
                  {pairDataList[index].baseToken.symbol}/
                  <span style={{ fontSize: 10, color: '#cccccc' }}>
                    {pairDataList[index].quoteToken.symbol}
                  </span>
                
            
              </TableData>
              </Link>
            )}




            {pairDataList[index] && pairDataList[index].priceUsd != null && (
          <TableData>
            <Link className="percents" to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('bsc')}`} style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red", fontSize:12 }}>
              $ {millify(Number(pairDataList[index].priceUsd), { precision: 5 })}
            </Link>
          </TableData>
        )}

{pairDataList[index] && pairDataList[index].priceChange && (
          <TableData className="percent" style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red", fontSize:12 }}>
            {new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              }).format(pairDataList[index].priceChange.h24)}{" "} %
          </TableData>
        )}
          </>
        )}
      </tr>
    );
  }
  return null;
})}



{eightBitUserDatas.map((project: any, index: number) => {
  if (project.userId === user.nft3 && user.chain3 === "8bit") {
    return (
      <tr key={project.userId}>
        {eightBitUserDatas && (
          <>
          
          <TableData style={{color:'#ffba00'}}>#3</TableData>

          <TableData><div style={{display:'flex',alignItems:'start'}}>
                        <img className="poklo" src={project.logoPicture}  />
                        <img className="pokool" src={BIT}  />
                        </div></TableData>

              <TableData>
                <Link to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('8bit')}`}>
                  {project.token0symbol}/
                  <span style={{ fontSize: 10, color: '#cccccc' }}>
                    {project.token1symbol}
                  </span>
                
                </Link>
              </TableData>
   




          <TableData >
          <Link
                          className="percents"
                          to={`/pair-explorers?id=${encodeURIComponent(
                            project.userId
                          )}`}
                          style={{
                            color:
                              priceChanges[project.pairAddress] >= 0
                                ? "green"
                                : "red", fontSize:12
                          }}
                        >
                          $
                          {prices[project.pairAddress]
                            ? prices[project.pairAddress].toFixed(6)
                            : "N/A"}
                        </Link>
          </TableData>


          <TableData    className="percent"
                        style={{
                          color:
                            priceChanges[project.pairAddress] >= 0
                              ? "green"
                              : "red", fontSize:12
                        }}
                      >
                        {" "}
                        {priceChanges[project.pairAddress] !== undefined
                          ? `${priceChanges[project.pairAddress]?.toFixed(2)}%`
                          : "N/A"}
          </TableData>
     
          </>
        )}
      </tr>
    );
  }
  return null;
})}


          {/* Add the necessary data from 'projects' */}
          {userDatas.map((project: any, index: number) => {
  if (project.userId === user.nft4 && user.chain4 === "bsc") {
    return (
      <tr key={project.userId}>
        {userDatas && (
          <>
                    <TableData style={{color:'#ffba00'}}>#4</TableData>

                   <TableData><div style={{display:'flex',alignItems:'start'}}>
                        <img className="poklo" src={project.logoPicture}  />
                        <img className="pokool" src={BNB}  />
                        </div></TableData>


            {pairDataList[index] && pairDataList[index].baseToken && pairDataList[index].quoteToken && (
                              <Link to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('bsc')}`}>

              <TableData style={{cursor:'pointer'}}>
                  {pairDataList[index].baseToken.symbol}/
                  <span style={{ fontSize: 10, color: '#cccccc' }}>
                    {pairDataList[index].quoteToken.symbol}
                  </span>
                
            
              </TableData>
              </Link>
            )}




            {pairDataList[index] && pairDataList[index].priceUsd != null && (
          <TableData>
            <Link className="percents" to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('bsc')}`} style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red", fontSize:12 }}>
              $ {millify(Number(pairDataList[index].priceUsd), { precision: 5 })}
            </Link>
          </TableData>
        )}

{pairDataList[index] && pairDataList[index].priceChange && (
          <TableData className="percent" style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red", fontSize:12 }}>
            {new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              }).format(pairDataList[index].priceChange.h24)}{" "} %
          </TableData>
        )}
          </>
        )}
      </tr>
    );
  }
  return null;
})}


{eightBitUserDatas.map((project: any, index: number) => {
  if (project.userId === user.nft4 && user.chain4 === "8bit") {
    return (
      <tr key={project.userId}>
        {eightBitUserDatas && (
          <>
          
          <TableData style={{color:'#ffba00'}}>#4</TableData>

          <TableData><div style={{display:'flex',alignItems:'start'}}>
                        <img className="poklo" src={project.logoPicture}  />
                        <img className="pokool" src={BIT}  />
                        </div></TableData>

              <TableData>
                <Link to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('8bit')}`}>
                  {project.token0symbol}/
                  <span style={{ fontSize: 10, color: '#cccccc' }}>
                    {project.token1symbol}
                  </span>
                
                </Link>
              </TableData>
   




          <TableData >
          <Link
                          className="percents"
                          to={`/pair-explorers?id=${encodeURIComponent(
                            project.userId
                          )}`}
                          style={{
                            color:
                              priceChanges[project.pairAddress] >= 0
                                ? "green"
                                : "red", fontSize:12
                          }}
                        >
                          $
                          {prices[project.pairAddress]
                            ? prices[project.pairAddress].toFixed(6)
                            : "N/A"}
                        </Link>
          </TableData>


          <TableData    className="percent"
                        style={{
                          color:
                            priceChanges[project.pairAddress] >= 0
                              ? "green"
                              : "red", fontSize:12
                        }}
                      >
                        {" "}
                        {priceChanges[project.pairAddress] !== undefined
                          ? `${priceChanges[project.pairAddress]?.toFixed(2)}%`
                          : "N/A"}
          </TableData>
     
          </>
        )}
      </tr>
    );
  }
  return null;
})}



          {/* Add the necessary data from 'projects' */}
          {userDatas.map((project: any, index: number) => {
  if (project.userId === user.nft5 && user.chain5 === "bsc") {
    return (
      <tr key={project.userId}>
        {userDatas && (
          <>
                    <TableData style={{color:'#ffba00'}}>#5</TableData>

                   <TableData><div style={{display:'flex',alignItems:'start'}}>
                        <img className="poklo" src={project.logoPicture}  />
                        <img className="pokool" src={BNB}  />
                        </div></TableData>


            {pairDataList[index] && pairDataList[index].baseToken && pairDataList[index].quoteToken && (
                              <Link to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('bsc')}`}>

              <TableData style={{cursor:'pointer'}}>
                  {pairDataList[index].baseToken.symbol}/
                  <span style={{ fontSize: 10, color: '#cccccc' }}>
                    {pairDataList[index].quoteToken.symbol}
                  </span>
                
            
              </TableData>
              </Link>
            )}




            {pairDataList[index] && pairDataList[index].priceUsd != null && (
          <TableData>
            <Link className="percents" to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('8bit')}`} style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red", fontSize:12 }}>
              $ {millify(Number(pairDataList[index].priceUsd), { precision: 5 })}
            </Link>
          </TableData>
        )}

{pairDataList[index] && pairDataList[index].priceChange && (
          <TableData className="percent" style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red", fontSize:12 }}>
            {new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              }).format(pairDataList[index].priceChange.h24)}{" "} %
          </TableData>
        )}
          </>
        )}
      </tr>
    );
  }
  return null;
})}



{eightBitUserDatas.map((project: any, index: number) => {
  if (project.userId === user.nft5 && user.chain5 === "8bit") {
    return (
      <tr key={project.userId}>
        {eightBitUserDatas && (
          <>
          
          <TableData style={{color:'#ffba00'}}>#5</TableData>

          <TableData><div style={{display:'flex',alignItems:'start'}}>
                        <img className="poklo" src={project.logoPicture}  />
                        <img className="pokool" src={BIT}  />
                        </div></TableData>

              <TableData>
                <Link to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('8bit')}`}>
                  {project.token0symbol}/
                  <span style={{ fontSize: 10, color: '#cccccc' }}>
                    {project.token1symbol}
                  </span>
                
                </Link>
              </TableData>
   




          <TableData >
          <Link
                          className="percents"
                          to={`/pair-explorers?id=${encodeURIComponent(
                            project.userId
                          )}`}
                          style={{
                            color:
                              priceChanges[project.pairAddress] >= 0
                                ? "green"
                                : "red", fontSize:12
                          }}
                        >
                          $
                          {prices[project.pairAddress]
                            ? prices[project.pairAddress].toFixed(6)
                            : "N/A"}
                        </Link>
          </TableData>


          <TableData    className="percent"
                        style={{
                          color:
                            priceChanges[project.pairAddress] >= 0
                              ? "green"
                              : "red", fontSize:12
                        }}
                      >
                        {" "}
                        {priceChanges[project.pairAddress] !== undefined
                          ? `${priceChanges[project.pairAddress]?.toFixed(2)}%`
                          : "N/A"}
          </TableData>
     
          </>
        )}
      </tr>
    );
  }
  return null;
})}


          {/* Add the necessary data from 'projects' */}
          {userDatas.map((project: any, index: number) => {
  if (project.userId === user.nft6 && user.chain6 === "bsc") {
    return (
      <tr key={project.userId}>
        {userDatas && (
          <>
                    <TableData style={{color:'#ffba00'}}>#6</TableData>

                   <TableData><div style={{display:'flex',alignItems:'start'}}>
                        <img className="poklo" src={project.logoPicture}  />
                        <img className="pokool" src={BNB}  />
                        </div></TableData>


            {pairDataList[index] && pairDataList[index].baseToken && pairDataList[index].quoteToken && (
                              <Link to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('bsc')}`}>

              <TableData style={{cursor:'pointer'}}>
                  {pairDataList[index].baseToken.symbol}/
                  <span style={{ fontSize: 10, color: '#cccccc' }}>
                    {pairDataList[index].quoteToken.symbol}
                  </span>
                
            
              </TableData>
              </Link>
            )}




            {pairDataList[index] && pairDataList[index].priceUsd != null && (
          <TableData>
            <Link className="percents" to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('bsc')}`} style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red", fontSize:12 }}>
              $ {millify(Number(pairDataList[index].priceUsd), { precision: 5 })}
            </Link>
          </TableData>
        )}

{pairDataList[index] && pairDataList[index].priceChange && (
          <TableData className="percent" style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red", fontSize:12 }}>
            {new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              }).format(pairDataList[index].priceChange.h24)}{" "} %
          </TableData>
        )}
          </>
        )}
      </tr>
    );
  }
  return null;
})}



{eightBitUserDatas.map((project: any, index: number) => {
  if (project.userId === user.nft6 && user.chain6 === "8bit") {
    return (
      <tr key={project.userId}>
        {eightBitUserDatas && (
          <>
          
          <TableData style={{color:'#ffba00'}}>#6</TableData>

          <TableData><div style={{display:'flex',alignItems:'start'}}>
                        <img className="poklo" src={project.logoPicture}  />
                        <img className="pokool" src={BIT}  />
                        </div></TableData>

              <TableData>
                <Link to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('8bit')}`}>
                  {project.token0symbol}/
                  <span style={{ fontSize: 10, color: '#cccccc' }}>
                    {project.token1symbol}
                  </span>
                
                </Link>
              </TableData>
   




          <TableData >
          <Link
                          className="percents"
                          to={`/pair-explorers?id=${encodeURIComponent(
                            project.userId
                          )}`}
                          style={{
                            color:
                              priceChanges[project.pairAddress] >= 0
                                ? "green"
                                : "red", fontSize:12
                          }}
                        >
                          $
                          {prices[project.pairAddress]
                            ? prices[project.pairAddress].toFixed(6)
                            : "N/A"}
                        </Link>
          </TableData>


          <TableData    className="percent"
                        style={{
                          color:
                            priceChanges[project.pairAddress] >= 0
                              ? "green"
                              : "red", fontSize:12
                        }}
                      >
                        {" "}
                        {priceChanges[project.pairAddress] !== undefined
                          ? `${priceChanges[project.pairAddress]?.toFixed(2)}%`
                          : "N/A"}
          </TableData>
     
          </>
        )}
      </tr>
    );
  }
  return null;
})}


          {/* Add the necessary data from 'projects' */}
          {userDatas.map((project: any, index: number) => {
  if (project.userId === user.nft7 && user.chain7 === "bsc") {
    return (
      <tr key={project.userId}>
        {userDatas && (
          <>
                    <TableData style={{color:'#ffba00'}}>#7</TableData>

                   <TableData><div style={{display:'flex',alignItems:'start'}}>
                        <img className="poklo" src={project.logoPicture}  />
                        <img className="pokool" src={BNB}  />
                        </div></TableData>


            {pairDataList[index] && pairDataList[index].baseToken && pairDataList[index].quoteToken && (
                              <Link to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('bsc')}`}>

              <TableData style={{cursor:'pointer'}}>
                  {pairDataList[index].baseToken.symbol}/
                  <span style={{ fontSize: 10, color: '#cccccc' }}>
                    {pairDataList[index].quoteToken.symbol}
                  </span>
                
            
              </TableData>
              </Link>
            )}




            {pairDataList[index] && pairDataList[index].priceUsd != null && (
          <TableData>
            <Link className="percents" to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('bsc')}`} style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red", fontSize:12 }}>
              $ {millify(Number(pairDataList[index].priceUsd), { precision: 5 })}
            </Link>
          </TableData>
        )}

{pairDataList[index] && pairDataList[index].priceChange && (
          <TableData className="percent" style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red", fontSize:12 }}>
            {new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              }).format(pairDataList[index].priceChange.h24)}{" "} %
          </TableData>
        )}
          </>
        )}
      </tr>
    );
  }
  return null;
})}



{eightBitUserDatas.map((project: any, index: number) => {
  if (project.userId === user.nft7 && user.chain7 === "8bit") {
    return (
      <tr key={project.userId}>
        {eightBitUserDatas && (
          <>
          
          <TableData style={{color:'#ffba00'}}>#7</TableData>

          <TableData><div style={{display:'flex',alignItems:'start'}}>
                        <img className="poklo" src={project.logoPicture}  />
                        <img className="pokool" src={BIT}  />
                        </div></TableData>

              <TableData>
                <Link to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('8bit')}`}>
                  {project.token0symbol}/
                  <span style={{ fontSize: 10, color: '#cccccc' }}>
                    {project.token1symbol}
                  </span>
                
                </Link>
              </TableData>
   




          <TableData >
          <Link
                          className="percents"
                          to={`/pair-explorers?id=${encodeURIComponent(
                            project.userId
                          )}`}
                          style={{
                            color:
                              priceChanges[project.pairAddress] >= 0
                                ? "green"
                                : "red", fontSize:12
                          }}
                        >
                          $
                          {prices[project.pairAddress]
                            ? prices[project.pairAddress].toFixed(6)
                            : "N/A"}
                        </Link>
          </TableData>


          <TableData    className="percent"
                        style={{
                          color:
                            priceChanges[project.pairAddress] >= 0
                              ? "green"
                              : "red", fontSize:12
                        }}
                      >
                        {" "}
                        {priceChanges[project.pairAddress] !== undefined
                          ? `${priceChanges[project.pairAddress]?.toFixed(2)}%`
                          : "N/A"}
          </TableData>
     
          </>
        )}
      </tr>
    );
  }
  return null;
})}


          {/* Add the necessary data from 'projects' */}
          {userDatas.map((project: any, index: number) => {
  if (project.userId === user.nft8 && user.chain8 === "bsc") {
    return (
      <tr key={project.userId}>
        {userDatas && (
          <>
                    <TableData style={{color:'#ffba00'}}>#8</TableData>

                   <TableData><div style={{display:'flex',alignItems:'start'}}>
                        <img className="poklo" src={project.logoPicture}  />
                        <img className="pokool" src={BNB}  />
                        </div></TableData>


            {pairDataList[index] && pairDataList[index].baseToken && pairDataList[index].quoteToken && (
                              <Link to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('bsc')}`}>

              <TableData style={{cursor:'pointer'}}>
                  {pairDataList[index].baseToken.symbol}/
                  <span style={{ fontSize: 10, color: '#cccccc' }}>
                    {pairDataList[index].quoteToken.symbol}
                  </span>
                
            
              </TableData>
              </Link>
            )}




            {pairDataList[index] && pairDataList[index].priceUsd != null && (
          <TableData>
            <Link className="percents" to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('bsc')}`} style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red", fontSize:12 }}>
              $ {millify(Number(pairDataList[index].priceUsd), { precision: 5 })}
            </Link>
          </TableData>
        )}

{pairDataList[index] && pairDataList[index].priceChange && (
          <TableData className="percent" style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red", fontSize:12 }}>
            {new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              }).format(pairDataList[index].priceChange.h24)}{" "} %
          </TableData>
        )}
          </>
        )}
      </tr>
    );
  }
  return null;
})}



{eightBitUserDatas.map((project: any, index: number) => {
  if (project.userId === user.nft8 && user.chain8 === "8bit") {
    return (
      <tr key={project.userId}>
        {eightBitUserDatas && (
          <>
          
          <TableData style={{color:'#ffba00'}}>#8</TableData>

          <TableData><div style={{display:'flex',alignItems:'start'}}>
                        <img className="poklo" src={project.logoPicture}  />
                        <img className="pokool" src={BIT}  />
                        </div></TableData>

              <TableData>
                <Link to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('8bit')}`}>
                  {project.token0symbol}/
                  <span style={{ fontSize: 10, color: '#cccccc' }}>
                    {project.token1symbol}
                  </span>
                
                </Link>
              </TableData>
   




          <TableData >
          <Link
                          className="percents"
                          to={`/pair-explorers?id=${encodeURIComponent(
                            project.userId
                          )}`}
                          style={{
                            color:
                              priceChanges[project.pairAddress] >= 0
                                ? "green"
                                : "red",  fontSize:12
                          }}
                        >
                          $
                          {prices[project.pairAddress]
                            ? prices[project.pairAddress].toFixed(6)
                            : "N/A"}
                        </Link>
          </TableData>


          <TableData    className="percent"
                        style={{
                          color:
                            priceChanges[project.pairAddress] >= 0
                              ? "green"
                              : "red",  fontSize:12
                        }}
                      >
                        {" "}
                        {priceChanges[project.pairAddress] !== undefined
                          ? `${priceChanges[project.pairAddress]?.toFixed(2)}%`
                          : "N/A"}
          </TableData>
     
          </>
        )}
      </tr>
    );
  }
  return null;
})}


          {/* Add the necessary data from 'projects' */}
          {userDatas.map((project: any, index: number) => {
  if (project.userId === user.nft9 && user.chain9 === "bsc") {
    return (
      <tr key={project.userId}>
        {userDatas && (
          <>
                    <TableData style={{color:'#ffba00'}}>#9</TableData>

                   <TableData><div style={{display:'flex',alignItems:'start'}}>
                        <img className="poklo" src={project.logoPicture}  />
                        <img className="pokool" src={BNB}  />
                        </div></TableData>


            {pairDataList[index] && pairDataList[index].baseToken && pairDataList[index].quoteToken && (
                              <Link to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('bsc')}`}>

              <TableData style={{cursor:'pointer'}}>
                  {pairDataList[index].baseToken.symbol}/
                  <span style={{ fontSize: 10, color: '#cccccc' }}>
                    {pairDataList[index].quoteToken.symbol}
                  </span>
                
            
              </TableData>
              </Link>
            )}




            {pairDataList[index] && pairDataList[index].priceUsd != null && (
          <TableData>
            <Link className="percents" to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('bsc')}`} style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red", fontSize:12 }}>
              $ {millify(Number(pairDataList[index].priceUsd), { precision: 5 })}
            </Link>
          </TableData>
        )}

{pairDataList[index] && pairDataList[index].priceChange && (
          <TableData className="percent" style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red", fontSize:12 }}>
            {new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              }).format(pairDataList[index].priceChange.h24)}{" "} %
          </TableData>
        )}
          </>
        )}
      </tr>
    );
  }
  return null;
})}




{eightBitUserDatas.map((project: any, index: number) => {
  if (project.userId === user.nft9 && user.chain9 === "8bit") {
    return (
      <tr key={project.userId}>
        {eightBitUserDatas && (
          <>
          
          <TableData style={{color:'#ffba00'}}>#9</TableData>

          <TableData><div style={{display:'flex',alignItems:'start'}}>
                        <img className="poklo" src={project.logoPicture}  />
                        <img className="pokool" src={BIT}  />
                        </div></TableData>

              <TableData>
                <Link to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('8bit')}`}>
                  {project.token0symbol}/
                  <span style={{ fontSize: 10, color: '#cccccc' }}>
                    {project.token1symbol}
                  </span>
                
                </Link>
              </TableData>
   




          <TableData >
          <Link
                          className="percents"
                          to={`/pair-explorers?id=${encodeURIComponent(
                            project.userId
                          )}`}
                          style={{
                            color:
                              priceChanges[project.pairAddress] >= 0
                                ? "green"
                                : "red",  fontSize:12
                          }}
                        >
                          $
                          {prices[project.pairAddress]
                            ? prices[project.pairAddress].toFixed(6)
                            : "N/A"}
                        </Link>
          </TableData>


          <TableData    className="percent"
                        style={{
                          color:
                            priceChanges[project.pairAddress] >= 0
                              ? "green"
                              : "red",  fontSize:12
                        }}
                      >
                        {" "}
                        {priceChanges[project.pairAddress] !== undefined
                          ? `${priceChanges[project.pairAddress]?.toFixed(2)}%`
                          : "N/A"}
          </TableData>
     
          </>
        )}
      </tr>
    );
  }
  return null;
})}

          {/* Add the necessary data from 'projects' */}
          {userDatas.map((project: any, index: number) => {
  if (project.userId === user.nft10 && user.chain10 === "bsc") {
    return (
      <tr key={project.userId}>
        {userDatas && (
          <>
                    <TableData style={{color:'#ffba00'}}>#10</TableData>

                   <TableData><div style={{display:'flex',alignItems:'start'}}>
                        <img className="poklo" src={project.logoPicture}  />
                        <img className="pokool" src={BNB}  />
                        </div></TableData>


            {pairDataList[index] && pairDataList[index].baseToken && pairDataList[index].quoteToken && (
                              <Link to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('bsc')}`}>

              <TableData style={{cursor:'pointer'}}>
                  {pairDataList[index].baseToken.symbol}/
                  <span style={{ fontSize: 10, color: '#cccccc' }}>
                    {pairDataList[index].quoteToken.symbol}
                  </span>
                
            
              </TableData>
              </Link>
            )}




            {pairDataList[index] && pairDataList[index].priceUsd != null && (
          <TableData>
            <Link className="percents" to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('bsc')}`} style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red", fontSize:12 }}>
              $ {millify(Number(pairDataList[index].priceUsd), { precision: 5 })}
            </Link>
          </TableData>
        )}

{pairDataList[index] && pairDataList[index].priceChange && (
          <TableData className="percent" style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red", fontSize:12 }}>
            {new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              }).format(pairDataList[index].priceChange.h24)}{" "} %
          </TableData>
        )}
          </>
        )}
      </tr>
    );
  }
  return null;
})}



{eightBitUserDatas.map((project: any, index: number) => {
  if (project.userId === user.nft10 && user.chain10 === "8bit") {
    return (
      <tr key={project.userId}>
        {eightBitUserDatas && (
          <>
          
          <TableData style={{color:'#ffba00'}}>#10</TableData>

          <TableData><div style={{display:'flex',alignItems:'start'}}>
                        <img className="poklo" src={project.logoPicture}  />
                        <img className="pokool" src={BIT}  />
                        </div></TableData>

              <TableData>
                <Link to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('8bit')}`}>
                  {project.token0symbol}/
                  <span style={{ fontSize: 10, color: '#cccccc' }}>
                    {project.token1symbol}
                  </span>
                
                </Link>
              </TableData>
   




          <TableData >
          <Link
                          className="percents"
                          to={`/pair-explorers?id=${encodeURIComponent(
                            project.userId
                          )}`}
                          style={{
                            color:
                              priceChanges[project.pairAddress] >= 0
                                ? "green"
                                : "red",  fontSize:12
                          }}
                        >
                          $
                          {prices[project.pairAddress]
                            ? prices[project.pairAddress].toFixed(6)
                            : "N/A"}
                        </Link>
          </TableData>


          <TableData    className="percent"
                        style={{
                          color:
                            priceChanges[project.pairAddress] >= 0
                              ? "green"
                              : "red",  fontSize:12
                        }}
                      >
                        {" "}
                        {priceChanges[project.pairAddress] !== undefined
                          ? `${priceChanges[project.pairAddress]?.toFixed(2)}%`
                          : "N/A"}
          </TableData>
     
          </>
        )}
      </tr>
    );
  }
  return null;
})}


          {/* Add the necessary data from 'projects' */}
          {userDatas.map((project: any, index: number) => {
  if (project.userId === user.nft11 && user.chain11 === "bsc") {
    return (
      <tr key={project.userId}>
        {userDatas && (
          <>
                    <TableData style={{color:'#ffba00'}}>#11</TableData>

                   <TableData><div style={{display:'flex',alignItems:'start'}}>
                        <img className="poklo" src={project.logoPicture}  />
                        <img className="pokool" src={BNB}  />
                        </div></TableData>


            {pairDataList[index] && pairDataList[index].baseToken && pairDataList[index].quoteToken && (
                              <Link to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('bsc')}`}>

              <TableData style={{cursor:'pointer'}}>
                  {pairDataList[index].baseToken.symbol}/
                  <span style={{ fontSize: 10, color: '#cccccc' }}>
                    {pairDataList[index].quoteToken.symbol}
                  </span>
                
            
              </TableData>
              </Link>
            )}




            {pairDataList[index] && pairDataList[index].priceUsd != null && (
          <TableData>
            <Link className="percents" to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('bsc')}`} style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red", fontSize:12 }}>
              $ {millify(Number(pairDataList[index].priceUsd), { precision: 5 })}
            </Link>
          </TableData>
        )}

{pairDataList[index] && pairDataList[index].priceChange && (
          <TableData className="percent" style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red", fontSize:12 }}>
            {new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              }).format(pairDataList[index].priceChange.h24)}{" "} %
          </TableData>
        )}
          </>
        )}
      </tr>
    );
  }
  return null;
})}



{eightBitUserDatas.map((project: any, index: number) => {
  if (project.userId === user.nft11 && user.chain11 === "8bit") {
    return (
      <tr key={project.userId}>
        {eightBitUserDatas && (
          <>
          
          <TableData style={{color:'#ffba00'}}>#11</TableData>

          <TableData><div style={{display:'flex',alignItems:'start'}}>
                        <img className="poklo" src={project.logoPicture}  />
                        <img className="pokool" src={BIT}  />
                        </div></TableData>

              <TableData>
                <Link to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('8bit')}`}>
                  {project.token0symbol}/
                  <span style={{ fontSize: 10, color: '#cccccc' }}>
                    {project.token1symbol}
                  </span>
                
                </Link>
              </TableData>
   




          <TableData >
          <Link
                          className="percents"
                          to={`/pair-explorers?id=${encodeURIComponent(
                            project.userId
                          )}`}
                          style={{
                            color:
                              priceChanges[project.pairAddress] >= 0
                                ? "green"
                                : "red",  fontSize:12
                          }}
                        >
                          $
                          {prices[project.pairAddress]
                            ? prices[project.pairAddress].toFixed(6)
                            : "N/A"}
                        </Link>
          </TableData>


          <TableData    className="percent"
                        style={{
                          color:
                            priceChanges[project.pairAddress] >= 0
                              ? "green"
                              : "red",  fontSize:12
                        }}
                      >
                        {" "}
                        {priceChanges[project.pairAddress] !== undefined
                          ? `${priceChanges[project.pairAddress]?.toFixed(2)}%`
                          : "N/A"}
          </TableData>
     
          </>
        )}
      </tr>
    );
  }
  return null;
})}


          {/* Add the necessary data from 'projects' */}
          {userDatas.map((project: any, index: number) => {
  if (project.userId === user.nft12 && user.chain12 === "bsc") {
    return (
      <tr key={project.userId}>
        {userDatas && (
          <>
                    <TableData style={{color:'#ffba00'}}>#12</TableData>

                   <TableData><div style={{display:'flex',alignItems:'start'}}>
                        <img className="poklo" src={project.logoPicture}  />
                        <img className="pokool" src={BNB}  />
                        </div></TableData>


            {pairDataList[index] && pairDataList[index].baseToken && pairDataList[index].quoteToken && (
                              <Link to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('bsc')}`}>

              <TableData style={{cursor:'pointer'}}>
                  {pairDataList[index].baseToken.symbol}/
                  <span style={{ fontSize: 10, color: '#cccccc' }}>
                    {pairDataList[index].quoteToken.symbol}
                  </span>
                
            
              </TableData>
              </Link>
            )}




            {pairDataList[index] && pairDataList[index].priceUsd != null && (
          <TableData>
            <Link className="percents" to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('bsc')}`} style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red", fontSize:12 }}>
              $ {millify(Number(pairDataList[index].priceUsd), { precision: 5 })}
            </Link>
          </TableData>
        )}

{pairDataList[index] && pairDataList[index].priceChange && (
          <TableData className="percent" style={{ color: pairDataList[index].priceChange.h24 >= 0 ? "green" : "red", fontSize:12 }}>
            {new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              }).format(pairDataList[index].priceChange.h24)}{" "} %
          </TableData>
        )}
          </>
        )}
      </tr>
    );
  }
  return null;
})}



{eightBitUserDatas.map((project: any, index: number) => {
  if (project.userId === user.nft12 && user.chain12 === "8bit") {
    return (
      <tr key={project.userId}>
        {eightBitUserDatas && (
          <>
          
          <TableData style={{color:'#ffba00'}}>#12</TableData>

          <TableData><div style={{display:'flex',alignItems:'start'}}>
                        <img className="poklo" src={project.logoPicture}  />
                        <img className="pokool" src={BIT}  />
                        </div></TableData>

              <TableData>
                <Link to={`/pair-explorers?id=${encodeURIComponent(project.userId)}&chain=${encodeURIComponent('8bit')}`}>
                  {project.token0symbol}/
                  <span style={{ fontSize: 10, color: '#cccccc' }}>
                    {project.token1symbol}
                  </span>
                
                </Link>
              </TableData>
   




          <TableData >
          <Link
                          className="percents"
                          to={`/pair-explorers?id=${encodeURIComponent(
                            project.userId
                          )}`}
                          style={{
                            color:
                              priceChanges[project.pairAddress] >= 0
                                ? "green"
                                : "red",  fontSize:12
                          }}
                        >
                          $
                          {prices[project.pairAddress]
                            ? prices[project.pairAddress].toFixed(6)
                            : "N/A"}
                        </Link>
          </TableData>


          <TableData    className="percent"
                        style={{
                          color:
                            priceChanges[project.pairAddress] >= 0
                              ? "green"
                              : "red",  fontSize:12
                        }}
                      >
                        {" "}
                        {priceChanges[project.pairAddress] !== undefined
                          ? `${priceChanges[project.pairAddress]?.toFixed(2)}%`
                          : "N/A"}
          </TableData>
     
          </>
        )}
      </tr>
    );
  }
  return null;
})}


  
      </>
    )}
  </tr>
);

  return (
    <div className="hotpairs-container">
      <div className="mx">
        <div className="live-data-card-container">
          <div className="border-animation-wrapper">
            <div className="border-animation"></div>
          </div>
          <div className="live-data-card flex-item">
            <div className="daily-gainers-heading flex-item">
              <div className="chat-icon">
                <HotPair />
              </div>
              <p>Buzzing Pairs</p>
              <div className="iicon">
               
              </div>
            </div>
            <div className="more-content flex-item">
            
            </div>
          </div>
          <div className="row-content flex-item">
            <div className="live-data-content">
             
{userData.length > 0 ? (
          userData.map((user: any, index: number) => {
            return createTableRow(user, index);
          })
        ) : (
          noProjectsMessage
        )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotPairs;
