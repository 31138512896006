import React, { FC, useState, useEffect } from "react";
import { useContractReads } from "wagmi";
import axios from "axios";
import { formatEther } from "ethers";
import { pairABI } from "../../utils/abis/pairABI";

interface ReservesProps {
  pairAddress: string;
  where: string;

}

const FetchReserves: FC<ReservesProps> = ({ pairAddress , where}) => {
  const targetTokenAddress = "0x0cA45E0779FAE177F26f6abF459CF03d9B23D012";

  const [nativePrice, setNativePrice] = useState<number | null>(null); // Initialize nativePrice state
  const { data, isError, isLoading } = useContractReads({
    contracts: [
      {
        abi: pairABI,
        address: pairAddress as `0x${string}`, 
        functionName: "getReserves",
      },
      {
        abi: pairABI,
        address: pairAddress as `0x${string}`,
        functionName: "token0",
      },
      {
        abi: pairABI,
        address: pairAddress as `0x${string}`,
        functionName: "token1",
      },
    ],
  });

  useEffect(() => {
    const fetchNativePrice = async () => {
      try {
        const response = await axios.get(
          "https://trade-c33f7-default-rtdb.firebaseio.com/nativePrice/.json"
        );
        const priceData = response.data;
        console.log("Fetched native price:", priceData);
        setNativePrice(priceData.price); 
      } catch (error) {
        console.error("Error fetching native price:", error);
      }
    };
    fetchNativePrice();
  }, []);

  if (isLoading) return <div>Loading reserves...</div>;
  if (isError) return <div>Error fetching reserves</div>;

  const reservesData = data && data[0]?.status === "success" ? data[0].result : null;
  const token0 = data && data[1]?.status === "success" ? data[1].result : null;
  const token1 = data && data[2]?.status === "success" ? data[2].result : null;

  if (!reservesData || !token0 || !token1 || nativePrice === null) {
    return <p></p>; 
  }

  const targetReserve =
    token0.toLowerCase() === targetTokenAddress.toLowerCase()
      ? reservesData[0]
      : token1.toLowerCase() === targetTokenAddress.toLowerCase()
      ? reservesData[1]
      : null;

  return (
    <div>
      {where === "list" && targetReserve ? (
        <div>
          <p style={{ fontSize: 14, color: 'white' }}>
           $ {(parseFloat(formatEther(targetReserve.toString())) * nativePrice).toFixed(2)} 
          </p>
        </div>
      ) : (
<></>      )}
       {where === "info" && targetReserve ? (
        <div>
          <p style={{ color: 'white' }}>
           $ {(parseFloat(formatEther(targetReserve.toString())) * nativePrice).toFixed(2)} 
          </p>
        </div>
      ) : (
<></>      )}
    </div>
  );
};

export default FetchReserves;
