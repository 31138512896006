import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";
import Logo from "../../assets/images/LogoImg.png";
import Transaction from "./TransactionNative";
import TransactionNormal from "./TransactionNormal";
import HolderNormal from "./HoldersNormal";
import HolderNative from "./HoldersNative";

import { ReactComponent as Telescope } from "../../assets/icons/telescope.svg";
import { ReactComponent as CopyIcon } from "../../assets/icons/copy.svg";
import { ReactComponent as Star } from "../../assets/icons/star.svg";
import { ReactComponent as Forward } from "../../assets/icons/forword.svg";
import { ReactComponent as Laptop } from "../../assets/icons/website.svg";
import { ReactComponent as Telegram } from "../../assets/icons/telegramIcon.svg";
import { ReactComponent as Twitter } from "../../assets/icons/twitterIcon.svg";
import { ReactComponent as Facebook } from "../../assets/icons/facbook.svg";

import "./PairExplorerDetails.scss";
import axios from "axios";
import { Link } from "react-router-dom";
import TradeHistory from "../TradeHistory";
import Chart from "./Chart";
import ChartNative from "./ChartNative";

import Holders from "../Holders";
import CandlestickChart from "./Trading";
import millify from "millify";

interface PriceData {
  priceUsd: number;
  price: number;

  priceNative: number;
  type: string;
  amount: number;
  received: number;
  time: string;
}

interface PriceResponse {
  [key: string]: PriceData; // This allows indexing by pair address
}

const PairExplorerDetails = () => {
  const [selectedTab, setSelectedTab] = useState("trade-history");
  const [userData, setUserData] = useState<any>({}); // Change 'any' to a more appropriate type
  const [isLoading, setIsLoading] = useState(true);
  const [copied, setCopied] = useState(false); // State to track if copied

  const [iframeUrl, setIframeUrl] = useState<string | null>(null); // State for iframe URL
  const [iframeChart, setIframeChart] = useState<string | null>(null); // State for iframe URL

  useEffect(() => {
    // Your Firebase configuration
    const firebaseConfig = {
      apiKey: "AIzaSyBtG7oiNd9DLuA80uwsWzGG7zX7legmGPE",
      authDomain: "protracker-6c490.firebaseapp.com",
      databaseURL: "https://protracker-6c490-default-rtdb.firebaseio.com",
      projectId: "protracker-6c490",
      storageBucket: "protracker-6c490.appspot.com",
      messagingSenderId: "776220672739",
      appId: "1:776220672739:web:d71e515bb8f33673403bd6",
      measurementId: "G-GMYRXFBSRK",
    };

    const app = initializeApp(firebaseConfig);
    const database = getDatabase(app);

    const id = new URLSearchParams(window.location.search).get("id");

    if (id) {
      const userDataRef = ref(database, `8bitprojects/${id}`);
      onValue(userDataRef, (snapshot) => {
        const fetchedData = snapshot.val();
        if (fetchedData) {
          setUserData(fetchedData);
        }
        setIsLoading(false); // Data fetched, loading is done
      });
    }

    // Simulate loading for 2 seconds
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    // Clean up the timeout when the component unmounts or when data is fetched
    return () => {
      clearTimeout(loadingTimeout);
      if (!isLoading) {
        clearTimeout(loadingTimeout);
      }
    };
  }, []);

  const copyContractAddress = () => {
      navigator.clipboard.writeText(userData.contract);
      setCopied(true); // Update state to indicate copying action
      // Reset the text to "Copy" after 2 seconds
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    
  };
  // Dummy data
  // const dummyData = {
  //   token0: { symbol: "Token0" },
  //   token1: { symbol: "Token1" },
  //   id: "DummyPairId",
  //   token1Price: 123.456, // Replace with actual data
  // };

  const [priceChanges, setPriceChanges] = useState<{ [key: string]: number }>(
    {}
  ); // Store price changes

  const [prices, setPrices] = useState<{ [key: string]: number }>({});
  const [pprices, setPPrices] = useState<{ [key: string]: number }>({});
  const [pnativeprices, setPNativePrices] = useState<{ [key: string]: number }>(
    {}
  );
  const [nativeprices, setNativePrices] = useState<{ [key: string]: number }>(
    {}
  );
  const [tradingVolumes, setTradingVolumes] = useState<{
    [key: string]: number;
  }>({});
  const [nativePrice, setNativePrice] = useState<number | null>(null);
  const fetchNativePrice = async () => {
    try {
      const response = await axios.get(
        "https://trade-c33f7-default-rtdb.firebaseio.com/nativePrice/.json"
      );
      const priceData = response.data;
      setNativePrice(priceData.price); // Assuming priceData is { price: <value> }
    } catch (error) {}
  };

  useEffect(() => {
    fetchNativePrice(); // Fetch native price on component mount
  }, []);

  const fetchTradingVolume = async (pairAddress: string) => {
    try {
      const url = pairAddress === "0xCa63D9144b4B66513298DE7b80Ff897ff4ca25C7"
      ? `https://trade-c33f7-default-rtdb.firebaseio.com/nativeTxnHistory/.json`
      : `https://trade-c33f7-default-rtdb.firebaseio.com/txnHistory/${pairAddress}/.json`;

      const response = await axios.get<PriceResponse>(url);
      const priceData = response.data;

      if (priceData) {
        const allEntries = Object.values(priceData);

        // Get today's date
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        // Filter today's entries
        const todaysEntries = allEntries.filter((entry) => {
          const entryDate = new Date(entry.time); // Ensure the date is parsed correctly
          return entryDate >= today; // Only include entries from today
        });

        return calculateVolume(todaysEntries); // Calculate and return the volume
      }
    } catch (error) {}

    return 0; // Return 0 if there's an error or no data
  };

  const calculateVolume = (entries: any[]): number => {
    let volume = 0;

    entries.forEach((entry) => {
      const amount = Number(entry.amount);
      const received = Number(entry.received) || 0; // Default to 0 if not available

      if (entry.type === "buy") {
        volume += amount; // Add amount for buy transactions
      } else if (entry.type === "sell") {
      }
    });

    return volume;
  };

  useEffect(() => {
    const fetchPrices = async () => {
      const newPrices: { [key: string]: number } = {};
      const previousPrices: { [key: string]: number } = {};
      const newPrice: { [key: string]: number } = {};
      const previousPrice: { [key: string]: number } = {};
      const tradingVolumes: { [key: string]: number } = {}; // Object to hold trading volumes

      // Get today's date
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set time to midnight

      // Assuming you want to fetch data for a specific pairAddress in userData
      const pairAddress = userData.pairAddress; // Change this if you have a different structure in userData

      try {
        // Fetch trading volume separately
        tradingVolumes[pairAddress] = await fetchTradingVolume(pairAddress);
      } catch (volumeError) {}

      try {
        const url = pairAddress === "0xCa63D9144b4B66513298DE7b80Ff897ff4ca25C7"
        ? `https://trade-c33f7-default-rtdb.firebaseio.com/nativeTxnHistory/.json`
        : `https://trade-c33f7-default-rtdb.firebaseio.com/txnHistory/${pairAddress}/.json`;
  
        const response = await axios.get<PriceResponse>(url);
        const priceData = response.data;

        if (priceData) {
          const allEntries = Object.values(priceData);

          const latestPriceEntry = allEntries.pop(); // Get the latest price entry
          if (latestPriceEntry) {
            newPrices[pairAddress] = 
            pairAddress === "0xCa63D9144b4B66513298DE7b80Ff897ff4ca25C7"
        ? latestPriceEntry.price
        : latestPriceEntry.priceUsd;            newPrice[pairAddress] = latestPriceEntry.priceNative;

            // Calculate previous prices
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            yesterday.setHours(0, 0, 0, 0); // Set to midnight
            const endOfYesterday = new Date();
            endOfYesterday.setDate(endOfYesterday.getDate() - 1);
            endOfYesterday.setHours(23, 59, 59, 999); // Set to the end of yesterday

            const yesterdayEntries = allEntries.filter((entry) => {
              const entryDate = new Date(entry.time);
              return entryDate >= yesterday && entryDate <= endOfYesterday; // Ensure 'yesterday' is in scope
            });

            if (yesterdayEntries.length > 0) {
              yesterdayEntries.sort(
                (a, b) =>
                  new Date(a.time).getTime() - new Date(b.time).getTime()
              );

              const previousPriceEntry =
                yesterdayEntries[yesterdayEntries.length - 1];

              if (previousPriceEntry) {
                previousPrice[pairAddress] = previousPriceEntry.priceNative;
                previousPrices[pairAddress] = previousPriceEntry.priceUsd;
              } else {
              }
            } else {
            }
          } else {
          }
        } else {
        }
      } catch (error) {}

      setPrices(newPrices);
      setNativePrices(newPrice);
      setPPrices(previousPrices);
      setPNativePrices(previousPrice);
      setTradingVolumes(tradingVolumes); // Set trading volumes state

      // Calculate price change
      const priceChanges: { [key: string]: number } = {};
      const latestPrice = newPrice[pairAddress];
      const prevPrice = previousPrice[pairAddress];

      if (prevPrice) {
        priceChanges[pairAddress] =
          ((latestPrice - prevPrice) / prevPrice) * 100;
      } else {
        priceChanges[pairAddress] = 0;
      }

      setPriceChanges(priceChanges);
    };

    if (userData && userData.pairAddress) {
      fetchPrices();
    }
  }, [userData]);

  return (
    <div className="pariExplorer-container">
      <div className="pairExplorer-details-wrapper">
        <div className="pairExplorer-details-container">
          <div className="first-step">
            <div className="flex-item">
              <img src={userData.logoPicture} alt="" />
              <h4>
                {userData.token0symbol}/{userData.token1symbol}
              </h4>
            </div>
            <div className="dext-tool-content">
              <h6>ProTrackers</h6>
              <div className="flex-item">
                <p>
                  CA:{" "}
                  {userData.contract
                    ? `${userData.contract.substring(
                        0,
                        5
                      )}...${userData.contract.substring(
                        userData.contract.length - 5
                      )}`
                    : "N/A"}
                  <span onClick={copyContractAddress}>
                      {copied ? "COPIED!" : <CopyIcon />}
                    </span>
                </p>
              </div>
              {userData && (
                <div className="icons flex-item">
                  <div className="circle flex-item">
                    <Link to="../">
                      <Forward />
                    </Link>
                  </div>
                  {userData.website !== "-" && (
                    <Link to={userData.website}>
                      {" "}
                      <Laptop />
                    </Link>
                  )}
                  {userData.telegram !== "-" && (
                    <Link to={userData.telegram}>
                      {" "}
                      <Telegram />
                    </Link>
                  )}
                  {userData.twitter !== "-" && (
                    <Link to={userData.twitter}>
                      {" "}
                      <Twitter />
                    </Link>
                  )}
                  {userData.facebook !== "-" && (
                    <Link to={userData.facebook}>
                      {" "}
                      <Facebook />
                    </Link>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="second-step">
            <div className="flex-item">
              <h1>
                $
                {prices[userData.pairAddress]
                  ? prices[userData.pairAddress].toFixed(8)
                  : "N/A"}
              </h1>
              <div className="eth">
                <h6
                  style={{
                    color:
                      priceChanges[userData.pairAddress] >= 0 ? "green" : "red",
                  }}
                >
                  {priceChanges[userData.pairAddress] !== undefined
                    ? `${priceChanges[userData.pairAddress]?.toFixed(2)}%`
                    : "N/A"}
                </h6>
                {userData.pairAddress !== "0xCa63D9144b4B66513298DE7b80Ff897ff4ca25C7" && 
                 <p>
                 NATIVE{" "}
                 {nativeprices[userData.pairAddress]
                   ? nativeprices[userData.pairAddress].toFixed(4)
                   : "N/A"} 8BIT
               </p>}
               
              </div>
            </div>

            {/* <div className="date-and-hour flex-item">
              <p>1m</p>
              <p>5m</p>
              <p>1h</p>
              <p>6h</p>
              <p>24h</p>
            </div> */}
          </div>
        </div>
        { userData.pairAddress !== '0xCa63D9144b4B66513298DE7b80Ff897ff4ca25C7' ? (
     <Chart token0={userData.token0} token1={userData.token1}/>
        ):(<ChartNative token0={userData.token0} token1={userData.token1}/>)}
      </div>
      <div className="pairExplorer-details-tabs-wrapper">
  <div className="pairExplorer-details-tabs-wrapper-header">
    <ul className="tab-container">
      {["trade-history", "your-txns"].map((type, index) => (
        <li key={index.toString()} className={selectedTab === type ? "active" : ""}>
          <button onClick={() => setSelectedTab(type)}>{type}</button>
        </li>
      ))}
    </ul>
  </div>
  {selectedTab === "trade-history" ? (
    userData.pairAddress !== '0xCa63D9144b4B66513298DE7b80Ff897ff4ca25C7' ? (
      <TransactionNormal
        token0={userData.token0}
        token1={userData.token1}
        pairAddress={userData.pairAddress || ''}
      />
    ) : (
      <Transaction
        token0={userData.token0}
        token1={userData.token1}
      />
    )
  ) : (
    userData.pairAddress !== '0xCa63D9144b4B66513298DE7b80Ff897ff4ca25C7' ? (
      <HolderNormal
        token0={userData.token0}
        token1={userData.token1}
        pairAddress={userData.pairAddress || ''}
      />
    ) : (
      <HolderNative
      token0={userData.token0}
      token1={userData.token1}
      pairAddress={userData.pairAddress || ''}
    />
    )  )}
</div>

    </div>
  );
};

export default PairExplorerDetails;
