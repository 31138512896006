import React, { useState,useEffect } from "react";
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';
import Logo from "../../assets/images/LogoImg.png";
import { ReactComponent as Telescope } from "../../assets/icons/telescope.svg";
import { ReactComponent as CopyIcon } from "../../assets/icons/copy.svg";
import { ReactComponent as Star } from "../../assets/icons/star.svg";
import { ReactComponent as Forward } from "../../assets/icons/forword.svg";
import { ReactComponent as Laptop } from "../../assets/icons/website.svg";
import { ReactComponent as Telegram } from "../../assets/icons/telegramIcon.svg";
import { ReactComponent as Twitter } from "../../assets/icons/twitterIcon.svg";
import { ReactComponent as Facebook } from "../../assets/icons/facbook.svg";

import "./PairExplorerDetails.scss";
import axios from "axios";
import { Link } from "react-router-dom";
import TradeHistory from "../TradeHistory";
import Holders from "../Holders";
import CandlestickChart from "./Trading";
import millify from "millify";

interface PairData {
  pairAddress: string;

  baseToken: {
    symbol: string;
  };
  quoteToken: {
    symbol: string;
  };
  priceUsd: number;
  priceNative: number;

  volume: {
    h24: number;
  };
  priceChange: {
    h24: number;
  };
  // Add other properties based on the actual structure of your data
}

const PairExplorerDetails = () => {
  const [selectedTab, setSelectedTab] = useState("trade-history");
  const [pairData, setPairData] = useState<PairData | null>(null);
  const [userData, setUserData] = useState<any>({}); // Change 'any' to a more appropriate type
  const [isLoading, setIsLoading] = useState(true);
  const [copied, setCopied] = useState(false); // State to track if copied

  const [iframeUrl, setIframeUrl] = useState<string | null>(null); // State for iframe URL
  const [iframeChart, setIframeChart] = useState<string | null>(null); // State for iframe URL


  useEffect(() => {
    // Your Firebase configuration
    const firebaseConfig = {
      apiKey: "AIzaSyBtG7oiNd9DLuA80uwsWzGG7zX7legmGPE",
      authDomain: "protracker-6c490.firebaseapp.com",
      databaseURL: "https://protracker-6c490-default-rtdb.firebaseio.com",
      projectId: "protracker-6c490",
      storageBucket: "protracker-6c490.appspot.com",
      messagingSenderId: "776220672739",
      appId: "1:776220672739:web:d71e515bb8f33673403bd6",
      measurementId: "G-GMYRXFBSRK"
    };

        const app = initializeApp(firebaseConfig);
        const database = getDatabase(app);
    
        const id = new URLSearchParams(window.location.search).get('id');
    
        if (id) {
          const userDataRef = ref(database, `projects/${id}`);
          onValue(userDataRef, (snapshot) => {
            const fetchedData = snapshot.val();
            if (fetchedData) {
              setUserData(fetchedData);
            }
            setIsLoading(false); // Data fetched, loading is done
          });
        }
        
        // Simulate loading for 2 seconds
        const loadingTimeout = setTimeout(() => {
            setIsLoading(false);
          }, 3000);
      
          // Clean up the timeout when the component unmounts or when data is fetched
          return () => {
            clearTimeout(loadingTimeout);
            if (!isLoading) {
              clearTimeout(loadingTimeout);
            }
          };
        }, []);


        useEffect(() => {
          const fetchPairData = async (userData: any) => {
            try {
              if (userData.pairAddress && userData.chain) {
                const pairAddress = userData.pairAddress;
                const chain = userData.chain;
                const chainChart = userData.tradingView;

                const response = await axios.get(`https://openapi.dexview.com/latest/dex/pairs/${chain}/${pairAddress}`);
                setPairData(response.data.pair);
                setIframeChart(chainChart);

                // const iframeUrl = `https://www.dextools.io/widget-chart/en/bnb/pe-light/${pairAddress}?theme=dark&chartType=2&chartResolution=30&drawingToolbars=false`;
                setIframeUrl(pairAddress);
              } else {
                console.error("Pair address or chain is undefined");
              }
            } catch (error) {
              console.error("Error fetching pair data:", error);
            }
          };
        
          fetchPairData(userData);
        }, [userData]);
        

        const copyContractAddress = () => {
          if (pairData) {
            navigator.clipboard.writeText(userData.pairAddress);
            setCopied(true); // Update state to indicate copying action
            // Reset the text to "Copy" after 2 seconds
            setTimeout(() => {
              setCopied(false);
            }, 2000);
          }
        };
  // Dummy data
  // const dummyData = {
  //   token0: { symbol: "Token0" },
  //   token1: { symbol: "Token1" },
  //   id: "DummyPairId",
  //   token1Price: 123.456, // Replace with actual data
  // };

  return (
    <div className="pariExplorer-container">

      <div className="pairExplorer-details-wrapper">
 
      {pairData && (

        <div className="pairExplorer-details-container">
          <div className="first-step">
            <div className="flex-item">
              <img src={userData.logoPicture} alt="" />
              <h4>
              {pairData.baseToken.symbol}/{pairData.quoteToken.symbol}

              </h4>
            
            </div>
            <div className="dext-tool-content">
              <h6>ProTrackers</h6>
              <div className="flex-item">
                <p>
                  CA: {userData.contract.slice(0, 5)}...{userData.contract.slice(userData.contract.length - 5)}
                  <span onClick={copyContractAddress}>
                      {copied ? "COPIED!" : <CopyIcon />}
                    </span>
                </p>
              </div>
              {userData && (
 <div className="icons flex-item">
 <div className="circle flex-item">
   <Link to="../"><Forward /></Link>
 </div>
<Link to={userData.website}> <Laptop /></Link>
<Link to={userData.telegram}> <Telegram /></Link>
<Link to={userData.twitter}> <Twitter /></Link>
<Link to={userData.facebook}> <Facebook /></Link>


</div>
              )}
             
            </div>
          </div>
          <div className="second-step">
            <div className="flex-item">
              <h1>$ {millify(Number(pairData.priceUsd), { precision: 5 })}</h1>
              <div className="eth">
              {pairData && pairData.priceChange ? (

                <h6 style={{ color: pairData.priceChange.h24 >= 0 ? "green" : "red" }}>    {pairData.priceChange.h24 ? pairData.priceChange.h24.toFixed(2) : "0.00"}%
                </h6>
                ) : (
                  <h6 style={{ color: "green" }}>    0.00%</h6>
                  )}
                  <p>NATIVE {millify(Number(pairData.priceNative), { precision: 8 })}</p>
              </div>
            </div>

            {/* <div className="date-and-hour flex-item">
              <p>1m</p>
              <p>5m</p>
              <p>1h</p>
              <p>6h</p>
              <p>24h</p>
            </div> */}
          </div>
        </div>
        )}
    <div id="dexscreener-embeds">
        </div>
        <div id="dexscreener-embed">
        <iframe src={`https://www.dextools.io/widget-chart/en/${iframeChart}/pe-light/${iframeUrl}?theme=dark&chartType=2&chartResolution=30&drawingToolbars=false`} />
  </div>        </div>
      <div className="pairExplorer-details-tabs-wrapper">
        <div className="pairExplorer-details-tabs-wrapper-header">
          <ul className="tab-container">
            {["trade-history", "your-txns"].map((type, index) => (
              <li key={index.toString()} className={selectedTab === type ? "active" : ""}>
                <button onClick={() => setSelectedTab(type)}>{type}</button>
              </li>
            ))}
          </ul>
        </div>
        {
          {
            "trade-history": <TradeHistory />,
            "your-txns": <Holders />,
          }[selectedTab]
        }

      </div>
            
    </div>
  );
};

export default PairExplorerDetails;
