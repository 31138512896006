import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import { erc20ABI, useChainId, useContractRead, useContractReads } from "wagmi";
import { formatUnits } from "ethers";
import { zeroAddress } from "viem";
import { pairABI } from "../../utils/abis/pairABI";
import "firebase/database";
import { ReactComponent as eyeIcon } from "../../assets/icons/eye.svg";
import { Link } from "react-router-dom";
import { initializeApp } from "firebase/app";
import axios from "axios";
import { getDatabase, ref, onValue } from "firebase/database";
import styled from "styled-components";
import millify from "millify";
import { formatDistanceToNow } from "date-fns";
import Reserves from "./fetchReserves"

interface PriceData {
  priceUsd: number;
  priceNative: number;
  type: string;
  amount: number;
  received: number;
  time: string;
  price:number;
}

interface PriceResponse {
  [key: string]: PriceData; // This allows indexing by pair address
}

const Section = styled.section`
  padding: 20px 0;
  @media (max-width: 768px) {
    max-width:100%;
    overflow:auto
    margin-left:50px;
  }
  
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const SearchContainer = styled.div`
  margin: 20px 0;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 30px;
  @media (max-width: 768px) {
    max-width: 100%;
    overflow: auto;
  }
`;
const TableRow = styled.tr``;

const TableData = styled.td`
  padding: 10px;
  font-size: 14px;
  // width:fit-content;
  border-bottom: 1px solid #000;
`;

const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

const Button = styled.button`
  background-color: #23323c;
  color: #ffba00;
  border: 1px solid #000;
  padding: 8px 15px;
  border-radius: 7px;
  cursor: pointer;
`;
const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const PaginationButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const PageNumber = styled.div`
  font-size: 14px;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px; /* Adjust the height as needed */
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const MainTable = styled.div`
  width: 100%; /* Adjust the width for mobile screens */
  background: var(--background);
  padding: 0 20px 20px;
  border-radius: 10px;
  margin-top: 20px;
  .tablescroll {
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 2px; /* Adjust the scrollbar width as needed */
    }

    &::-webkit-scrollbar-track {
      background: #000; /* Track color */
    }

    /* Scrollbar Handle */
    &::-webkit-scrollbar-thumb {
      background: #a37800; /* Handle color */
    }

    /* Scrollbar Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #a37800; /* Handle color on hover */
    }

    // Add mobile styles here
    @media (max-width: 768px) {
      // Adjust styles for smaller screens
      width: 100%;
    }
  }
`;

const TableHead = styled.thead`
  background-color: #000;
  padding: 5px;
  height: 55px;

  border: none;

  th {
    padding: 10px;
    text-align: left;
    font-weight: bold;
    margin-bottom: 20px;
  }

  tr {
    padding: 5px;
  }
`;

// Firebase Configuration
const firebaseConfig = {
  apiKey: "AIzaSyBtG7oiNd9DLuA80uwsWzGG7zX7legmGPE",
  authDomain: "protracker-6c490.firebaseapp.com",
  databaseURL: "https://protracker-6c490-default-rtdb.firebaseio.com",
  projectId: "protracker-6c490",
  storageBucket: "protracker-6c490.appspot.com",
  messagingSenderId: "776220672739",
  appId: "1:776220672739:web:d71e515bb8f33673403bd6",
  measurementId: "G-GMYRXFBSRK",
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const userDataRef = ref(database, "8bitprojects");

const ITEMS_PER_PAGE = 10;

const AuditedProjects: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [userData, setUserData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true); // Add this line for loading state

  useEffect(() => {
    onValue(userDataRef, (snapshot) => {
      const data = snapshot.val();
      const filteredData = data ? Object.values(data) : [];

      if (searchTerm) {
        const searchTermLC = searchTerm.toLowerCase();
        const searchResults = filteredData.filter((user: any) =>
          user.name.toLowerCase().includes(searchTermLC)
        );
        setUserData(searchResults.reverse());
      } else {
        setUserData(filteredData.reverse());
      }
      setLoading(false); // Data loading is complete
    });
  }, [searchTerm]);

  const totalPages = Math.ceil(userData.length / ITEMS_PER_PAGE);
  const lastItemIndex = currentPage * ITEMS_PER_PAGE;
  const firstItemIndex = lastItemIndex - ITEMS_PER_PAGE;
  const displayedUserData = userData;
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const formatVolume = (volume: any) => {
    const abbreviations = ["K", "M", "B"];

    const format = (num: any, precision: any) => {
      const index = Math.floor(Math.log10(num) / 3);
      const scaledNum = num / Math.pow(1000, index);

      const formatter = new Intl.NumberFormat(undefined, {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
      });

      return formatter.format(scaledNum) + abbreviations[index - 1];
    };

    return format(volume, 2); // You can adjust the precision as needed
  };
  const [priceChanges, setPriceChanges] = useState<{ [key: string]: number }>(
    {}
  ); // Store price changes

  const [prices, setPrices] = useState<{ [key: string]: number }>({});
  const [pprices, setPPrices] = useState<{ [key: string]: number }>({});
  const [pnativeprices, setPNativePrices] = useState<{ [key: string]: number }>(
    {}
  );
  const [nativeprices, setNativePrices] = useState<{ [key: string]: number }>(
    {}
  );
  const [tradingVolumes, setTradingVolumes] = useState<{
    [key: string]: number;
  }>({});
  const [nativePrice, setNativePrice] = useState<number | null>(null);
  const fetchNativePrice = async () => {
    try {
      const response = await axios.get(
        "https://trade-c33f7-default-rtdb.firebaseio.com/nativePrice/.json"
      );
      const priceData = response.data;
      setNativePrice(priceData.price); // Assuming priceData is { price: <value> }
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchNativePrice(); // Fetch native price on component mount
  }, []);

  const fetchTradingVolume = async (pairAddress: string) => {
    try {
      
      const url = pairAddress === "0xCa63D9144b4B66513298DE7b80Ff897ff4ca25C7"
      ? `https://trade-c33f7-default-rtdb.firebaseio.com/nativeTxnHistory/.json`
      : `https://trade-c33f7-default-rtdb.firebaseio.com/txnHistory/${pairAddress}/.json`;

      const response = await axios.get<PriceResponse>(url);
      const priceData = response.data;

      if (priceData) {
        const allEntries = Object.values(priceData);

        // Get today's date
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        // Filter today's entries
        const todaysEntries = allEntries.filter((entry) => {
          const entryDate = new Date(entry.time); // Ensure the date is parsed correctly
          return entryDate >= today; // Only include entries from today
        });


        return calculateVolume(todaysEntries); // Calculate and return the volume
      }
    } catch (error) {
      console.error(`Error fetching data for ${pairAddress}:`, error);
    }

    return 0; // Return 0 if there's an error or no data
  };

  const calculateVolume = (entries: any[]): number => {
    let volume = 0;

    entries.forEach((entry) => {
      const amount = Number(entry.amount);
      const received = Number(entry.received) || 0; // Default to 0 if not available

      if (entry.type === "buy") {
        volume += amount; // Add amount for buy transactions
      } else if (entry.type === "sell") {
      }
    });

    return volume;
  };

  useEffect(() => {
    const fetchPrices = async () => {
      const newPrices: { [key: string]: number } = {};
      const previousPrices: { [key: string]: number } = {};
      const newPrice: { [key: string]: number } = {};
      const previousPrice: { [key: string]: number } = {};
      const tradingVolumes: { [key: string]: number } = {}; // Object to hold trading volumes
  
      // Get today's date
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set time to midnight
  
  
      await Promise.all(
        userData.map(async (user) => {
          const { pairAddress } = user;
  
          // Fetch trading volume separately
          tradingVolumes[pairAddress] = await fetchTradingVolume(pairAddress);
  
          try {
            const url = pairAddress === "0xCa63D9144b4B66513298DE7b80Ff897ff4ca25C7"
            ? `https://trade-c33f7-default-rtdb.firebaseio.com/nativeTxnHistory/.json`
            : `https://trade-c33f7-default-rtdb.firebaseio.com/txnHistory/${pairAddress}/.json`;
      
            const response = await axios.get<PriceResponse>(url);
            const priceData = response.data;
  
  
            if (priceData) {
              const allEntries = Object.values(priceData);
  
              const latestPriceEntry = allEntries.pop(); // Get the latest price entry
              if (latestPriceEntry) {
                newPrices[pairAddress] = 
                pairAddress === "0xCa63D9144b4B66513298DE7b80Ff897ff4ca25C7"
            ? latestPriceEntry.price
            : latestPriceEntry.priceUsd;
                newPrice[pairAddress] = latestPriceEntry.priceNative;
  
  
                // Calculate previous prices
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                yesterday.setHours(0, 0, 0, 0); // Set to midnight
                const endOfYesterday = new Date();
                endOfYesterday.setDate(endOfYesterday.getDate() - 1);
                endOfYesterday.setHours(23, 59, 59, 999); // Set to the end of yesterday
  
              
                const yesterdayEntries = allEntries.filter((entry) => {
                  const entryDate = new Date(entry.time);
                  return entryDate >= yesterday && entryDate <= endOfYesterday; // Ensure 'yesterday' is in scope
                });
  
                if (yesterdayEntries.length > 0) {
                  yesterdayEntries.sort(
                    (a, b) =>
                      new Date(a.time).getTime() - new Date(b.time).getTime()
                  );
  
                  const previousPriceEntry =
                    yesterdayEntries[yesterdayEntries.length - 1];
  
                  if (previousPriceEntry) {
                    previousPrice[pairAddress] = previousPriceEntry.priceNative;
                    previousPrices[pairAddress] = previousPriceEntry.priceUsd;
  
                  } else {
                  }
                } else {
                }
              }
            } else {
            }
          } catch (error) {
          }
        })
      );
  
      setPrices(newPrices);
      setNativePrices(newPrice);
      setPPrices(previousPrices);
      setPNativePrices(previousPrice);
      setTradingVolumes(tradingVolumes); // Set trading volumes state
  
      const priceChanges: { [key: string]: number } = {};
      Object.keys(newPrices).forEach((pairAddress) => {
        const latestPrice = newPrice[pairAddress];
        const prevPrice = previousPrice[pairAddress];
  
        if (prevPrice) {
          priceChanges[pairAddress] =
            ((latestPrice - prevPrice) / prevPrice) * 100;
        } else {
          priceChanges[pairAddress] = 0;
        }
  
      });
  
      setPriceChanges(priceChanges);
    };
  
    if (userData.length > 0) {
      fetchPrices();
    }
  }, [userData]);
  

  
  const noProjectsMessage = (
    <TableRow>
      <TableData colSpan={4} style={{ textAlign: "center", height: "100px" }}>
        No Project Found
      </TableData>
    </TableRow>
  );

  // Existing code...

  const createTableRow = (user: any, index: number) => {

  
    return (
      <TableRow key={user.userId || index} style={{ cursor: "pointer" }}>
        <>
          <TableData>
          <Link to={`/pair-explorers?id=${encodeURIComponent(user.userId)}&chain=${encodeURIComponent('8bit')}`}>
              <ProfileImage src={user.logoPicture} alt="Logo Picture" />
            </Link>
          </TableData>

          {/* Check if pairDataList[index] and its properties are not null before accessing them */}

          <TableData>
          <Link to={`/pair-explorers?id=${encodeURIComponent(user.userId)}&chain=${encodeURIComponent('8bit')}`}>
              {user.token0symbol} /{" "}
              <span style={{ fontSize: 12, color: "#cccccc" }}>
                {user.token1symbol}
              </span>
              <br />
              <span style={{ fontSize: 12, color: "#cccccc" }}>
                {user.pairAddress.slice(0, 5)}...
                {user.pairAddress.slice(user.pairAddress.length - 5)}
              </span>
            </Link>
          </TableData>

          <TableData>
            <span>{user.name}</span>
          </TableData>

          <TableData>
          <Link to={`/pair-explorers?id=${encodeURIComponent(user.userId)}&chain=${encodeURIComponent('8bit')}`}>
              $
              {prices[user.pairAddress]
                ? prices[user.pairAddress].toFixed(6)
                : "N/A"}
            </Link>
          </TableData>

          <TableData
            style={{
              color: priceChanges[user.pairAddress] >= 0 ? "green" : "red",
            }}
          >
            {priceChanges[user.pairAddress] !== undefined
              ? `${priceChanges[user.pairAddress]?.toFixed(2)}%`
              : "N/A"}
          </TableData>

          {/* Additional checks for other properties of pairDataList[index] */}
          {/* {pairDataList[index] && pairDataList[index].priceUsd != null && (
           
          )}

          {pairDataList[index] && pairDataList[index].priceChange && (
            <TableData
              style={{
                color:
                  pairDataList[index].priceChange.h24 >= 0 ? "green" : "red",
              }}
            >
              {new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
              }).format(pairDataList[index].priceChange.h24)}{" "}
              %
            </TableData>
          )} */}

          <TableData>
            {formatDistanceToNow(new Date(user.created * 1000), {
              addSuffix: true,
            })}
          </TableData>
          <TableData>
          <Link to={`/pair-explorers?id=${encodeURIComponent(user.userId)}&chain=${encodeURIComponent('8bit')}`}>
              $
              {tradingVolumes[user.pairAddress] && nativePrice
                ? (tradingVolumes[user.pairAddress] * nativePrice).toFixed(2)
                : "N/A"}
            </Link>
          </TableData>
<TableData>
  <Reserves pairAddress={user.pairAddress} where="list" />
</TableData>
       
<TableData>
<Link to={`/pair-explorers?id=${encodeURIComponent(user.userId)}&chain=${encodeURIComponent('8bit')}`}>
              $
              {prices[user.pairAddress]
                ? (prices[user.pairAddress]*user.supply).toFixed(2)
                : "N/A"}
            </Link>
          </TableData>
          {/* {pairDataList[index] && pairDataList[index].priceChange && (
            <TableData>
              ${" "}
              {new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
              }).format(pairDataList[index].volume.h24)}{" "}
            </TableData>
          )}

          {pairDataList[index] && pairDataList[index].priceChange && (
            <TableData>
              {formatVolume(pairDataList[index].liquidity.usd)}
            </TableData>
          )}

          {pairDataList[index] && pairDataList[index].priceChange && (
            <TableData>
              $ {formatVolume(pairDataList[index].priceUsd * user.supply)}
            </TableData>
          )} */}
        </>
      </TableRow>
    );
  };

  // Existing code...

  return (
    <MainTable>
      {loading ? ( // Conditional rendering based on loading state
        <LoaderContainer>
          <p>Loading...</p>
        </LoaderContainer>
      ) : (
        <div className="tablescroll">
          <Table>
            <TableHead>
              <tr>
                <th style={{ borderRadius: "10px 0 0 10px" }}>
                  <span style={{ marginLeft: "5px" }}>Logo</span>
                </th>
                <th style={{ minWidth: "fit-content" }}>Pair</th>
                <th>Name</th>

                <th>Price</th>
                <th>%24h</th>
                <th>Created</th>

                <th>Volume</th>

                <th>Liquidity</th>

                <th style={{ borderRadius: "0 10px 10px 0" }}>Mkt. Cap.</th>
              </tr>
            </TableHead>
            <tbody>
              {displayedUserData.map((user, index) =>
                createTableRow(user, index)
              )}
            </tbody>
          </Table>
        </div>
      )}
      <PaginationContainer>
        <PageNumber>
          Page {currentPage} of {totalPages}
        </PageNumber>

        <PaginationButtons>
          <Button onClick={handlePreviousPage}>Previous</Button>

          <Button onClick={handleNextPage}>Next</Button>
        </PaginationButtons>
      </PaginationContainer>
    </MainTable>
  );
};

export default AuditedProjects;
