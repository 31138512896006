import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts';
import axios from 'axios';
import { erc20ABI, useChainId, useContractRead, useContractReads } from 'wagmi';
import { pairABI } from '../../utils/abis/pairABI';
import { factoryABI } from '../../utils/abis/factoryABI';
import { FACTORY_ADDRESS } from '../../utils/address';

interface Trade {
    time: string;
    price: number;
}

interface ILiquidityInfoCardProps {
    token0: string;
    token1: string;
}

const TradeChart: React.FC<ILiquidityInfoCardProps> = ({ token0, token1 }) => {
    const [chartData, setChartData] = useState<{ labels: string[], prices: number[] }>({ labels: [], prices: [] });
    const [previousDayLastTrade, setPreviousDayLastTrade] = useState<Trade | null>(null);
    const [latestTrade, setLatestTrade] = useState<Trade | null>(null);
    const [priceChangePercent, setPriceChangePercent] = useState<string>('');
    const [currentDate, setCurrentDate] = useState<string>('');
    const [token0Symbol, setToken0Symbol] = useState<string>('');
    const [token1Symbol, setToken1Symbol] = useState<string>('');
    const [selectedRange, setSelectedRange] = useState<string>('all');

    const fetchTradeData = async (pairAddress: string) => {
        try {
            let trades: Trade[] = [];
            let previousDayLastTrade: Trade | null = null;

            const baseURL = 'https://trade-c33f7-default-rtdb.firebaseio.com';

            if (pairAddress === '0xCa63D9144b4B66513298DE7b80Ff897ff4ca25C7') {
                const response = await axios.get(`${baseURL}/nativeTrades.json`);
                if (response.data) {
                    trades = Object.values(response.data) as Trade[];
                }
            } else if (pairAddress !== '0x0000000000000000000000000000000000000000') {
                const response = await axios.get(`${baseURL}/prices/${pairAddress}.json`);
                if (response.data) {
                    trades = Object.values(response.data) as Trade[];
                }
            }

            const today = new Date();
            const currentYear = today.getFullYear();
            const currentMonth = today.getMonth();
            const currentDay = today.getDate();

            const previousDayTrades = trades.filter(trade => {
                const tradeDate = new Date(trade.time);
                return tradeDate.getFullYear() === currentYear &&
                    tradeDate.getMonth() === currentMonth &&
                    tradeDate.getDate() === (currentDay - 1);
            });

            if (previousDayTrades.length > 0) {
                previousDayLastTrade = previousDayTrades[previousDayTrades.length - 1];
            }

            const latestTrade = trades.length > 0 ? trades[trades.length - 1] : null;

            return { trades, previousDayLastTrade, latestTrade };

        } catch (error) {
            console.error('Error fetching data:', error);
            return { trades: [], previousDayLastTrade: null, latestTrade: null };
        }
    };

    const chainId = useChainId() as keyof typeof FACTORY_ADDRESS;

    const { data: pair } = useContractRead({
        abi: factoryABI,
        address: FACTORY_ADDRESS[chainId] as `0x${string}`,
        functionName: 'getPair',
        args: [token0 as any, token1 as any],
    });

    const { data: token0Address } = useContractRead({
        abi: pairABI,
        address: pair as any,
        functionName: 'token0',
    });

    const { data: token1Address } = useContractRead({
        abi: pairABI,
        address: pair as any,
        functionName: 'token1',
    });

    const { data: tokenData } = useContractReads({
        contracts: [
            {
                abi: erc20ABI,
                address: token0Address || (token0 as any),
                functionName: 'symbol',
            },
            {
                abi: erc20ABI,
                address: token1Address || (token1 as any),
                functionName: 'symbol',
            },
        ],
    });

    useEffect(() => {
        if (tokenData) {
            setToken0Symbol(tokenData[0]?.result || token0);
            setToken1Symbol(tokenData[1]?.result || token1);
        }
    }, [tokenData, token0, token1]);

    useEffect(() => {
        const getData = async () => {
            if (!pair) return;

            try {
                const { trades, previousDayLastTrade, latestTrade } = await fetchTradeData(pair as string);

                const currentDate = new Date();
                setCurrentDate(currentDate.toLocaleDateString());

                let filteredTrades = trades;
                if (selectedRange === '1d') {
                    filteredTrades = trades.filter(trade => {
                        const tradeDate = new Date(trade.time);
                        return tradeDate.toDateString() === currentDate.toDateString(); // Compare only date
                    });
                } else if (selectedRange === '5d') {
                    const fiveDaysAgo = new Date();
                    fiveDaysAgo.setDate(currentDate.getDate() - 5);
                    filteredTrades = trades.filter(trade => new Date(trade.time) >= fiveDaysAgo);
                }

                setPreviousDayLastTrade(previousDayLastTrade);
                setLatestTrade(latestTrade);

                if (previousDayLastTrade && latestTrade) {
                    const priceChange = ((latestTrade.price - previousDayLastTrade.price) / previousDayLastTrade.price) * 100;
                    const formattedChange = priceChange.toFixed(2);
                    setPriceChangePercent(formattedChange);
                } else {
                    setPriceChangePercent('');
                }

                const labels = filteredTrades.map(trade => {
                    const tradeDate = new Date(trade.time);
                    if (selectedRange === '1d') {
                        return tradeDate.toLocaleTimeString(); // Show only time
                    } else {
                        return tradeDate.toLocaleString(); // Show both date and time
                    }
                });
                const prices = filteredTrades.map(trade => parseFloat(trade.price.toFixed(7)));

                setChartData({ labels, prices });

            } catch (error) {
                console.error('Error fetching trade data:', error);
            }
        };

        getData();
        const interval = setInterval(getData, 60000); // Refresh data every minute

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, [pair, selectedRange]);

    useEffect(() => {
        const chartContainer = document.getElementById('chart')!;
        const chart = echarts.init(chartContainer);

        const option = {
            tooltip: {
                trigger: 'axis',
                formatter: (params: any) => {
                    const { axisValue, data } = params[0];
                    return `Price: $ ${data}</br/>Time: ${axisValue}`;
                },
                backgroundColor: 'rgba(0, 0, 0, 0.7)', // Tooltip background color
                borderColor: '#ffba00', // Tooltip border color
                borderWidth: 1, // Tooltip border width
                textStyle: { color: '#ffffff' }, // Text color in tooltip
            },
            grid: {
                top: 20,
                right: 40,
                bottom: 20,
                left: 10,
                containLabel: true
            },
            xAxis: {
                type: 'category',
                data: chartData.labels,
                axisLine: { lineStyle: { color: '#cccccc' } },
                axisLabel: { color: '#cccccc' },
                splitLine: { show: false }  // Remove grid lines on the x-axis
            },
            yAxis: {
                type: 'value',
                axisLine: { lineStyle: { color: '#cccccc' } },
                axisLabel: { color: '#cccccc' },
                splitLine: { show: false }  // Remove grid lines on the y-axis
            },
            series: [
                {
                    name: 'Price',
                    type: 'line',
                    data: chartData.prices,
                    areaStyle: {
                        gradient: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                                { offset: 0, color: 'rgba(255, 186, 0, 0.7)' }, // Start color with 0.7 opacity
                                { offset: 1, color: 'rgba(255, 186, 0, 0)' }  // End color with 0 opacity
                            ]
                        }
                    },
                    lineStyle: {
                        color: '#ffba00'
                    },
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            { offset: 0, color: 'rgba(255, 186, 0, 0.6)' }, // Top color (yellow)
                            { offset: 1, color: 'rgba(255, 186, 0, 0.2)' }  // Bottom color (lighter yellow)
                        ])
                    }
                }
            ],
        };

        chart.setOption(option);
        return () => {
            chart.dispose();
        };
    }, [chartData]);

    const handleRangeChange = (range: string) => {
        setSelectedRange(range);
    };

    return (
        <div>
            <div className='poko-first' >
                <div></div>
                <div>
    <button onClick={() => handleRangeChange('all')} className={`option-button ${selectedRange === 'all' ? 'active-opt' : ''}`}>All</button>
    <button onClick={() => handleRangeChange('1d')} className={`option-button ${selectedRange === '1d' ? 'active-opt' : ''}`}>1D</button>
    <button onClick={() => handleRangeChange('5d')} className={`option-button ${selectedRange === '5d' ? 'active-opt' : ''}`}>5D</button>
</div>

            </div>
            <div id="chart" className='poko-chart' />
           
        </div>
    );
};

export default TradeChart;
