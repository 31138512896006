import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/database";
import { Link } from "react-router-dom";
import { initializeApp } from "firebase/app";
import axios from "axios";
import { getDatabase, ref, onValue } from "firebase/database";
import styled from "styled-components";

interface PriceData {
  priceUsd: number;
  priceNative: number;
  type: string;
  amount: number;
  received: number;
  time: string;
  price: number;
}

interface PriceResponse {
  [key: string]: PriceData; // This allows indexing by pair address
}

const TableRow = styled.tr``;

const TableData = styled.td`
  padding: 10px;
  font-size: 14px;
`;

const firebaseConfig = {
  apiKey: "AIzaSyBtG7oiNd9DLuA80uwsWzGG7zX7legmGPE",
  authDomain: "protracker-6c490.firebaseapp.com",
  databaseURL: "https://protracker-6c490-default-rtdb.firebaseio.com",
  projectId: "protracker-6c490",
  storageBucket: "protracker-6c490.appspot.com",
  messagingSenderId: "776220672739",
  appId: "1:776220672739:web:d71e515bb8f33673403bd6",
  measurementId: "G-GMYRXFBSRK",
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const userDataRef = ref(database, "8bitprojects");

const AuditedProjects: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [userData, setUserData] = useState<any[]>([]);
  const [priceChanges, setPriceChanges] = useState<{ [key: string]: number }>(
    {}
  );
  const [prices, setPrices] = useState<{ [key: string]: number }>({});

  useEffect(() => {
    onValue(userDataRef, (snapshot) => {
      const data = snapshot.val();
      const filteredData = data ? Object.values(data) : [];

      if (searchTerm) {
        const searchTermLC = searchTerm.toLowerCase();
        const searchResults = filteredData.filter((user: any) =>
          user.name.toLowerCase().includes(searchTermLC)
        );
        setUserData(searchResults.reverse());
      } else {
        setUserData(filteredData.reverse());
      }
    });
  }, [searchTerm]);

  useEffect(() => {
    const fetchPrices = async () => {
      const newPrices: { [key: string]: number } = {};
      const previousPrices: { [key: string]: number } = {};
      const priceChanges: { [key: string]: number } = {};

      await Promise.all(
        userData.map(async (user) => {
          const { pairAddress } = user;
          try {
            const url =
              pairAddress === "0xCa63D9144b4B66513298DE7b80Ff897ff4ca25C7"
                ? `https://trade-c33f7-default-rtdb.firebaseio.com/nativeTxnHistory/.json`
                : `https://trade-c33f7-default-rtdb.firebaseio.com/txnHistory/${pairAddress}/.json`;

            const response = await axios.get<PriceResponse>(url);
            const priceData = response.data;

            if (priceData) {
              const allEntries = Object.values(priceData);
              const latestPriceEntry = allEntries.pop();
              if (latestPriceEntry) {
                newPrices[pairAddress] =
                  pairAddress === "0xCa63D9144b4B66513298DE7b80Ff897ff4ca25C7"
                    ? latestPriceEntry.price
                    : latestPriceEntry.priceUsd;

                // Calculate price changes (using a simple example of previous day's price)
                const previousPriceEntry = allEntries[allEntries.length - 1];
                if (previousPriceEntry) {
                  const prevPrice =
                    pairAddress === "0xCa63D9144b4B66513298DE7b80Ff897ff4ca25C7"
                      ? previousPriceEntry.priceNative
                      : previousPriceEntry.priceUsd;
                  priceChanges[pairAddress] =
                    ((newPrices[pairAddress] - prevPrice) / prevPrice) * 100;
                }
              }
            }
          } catch (error) {
            console.error(`Error fetching data for ${pairAddress}:`, error);
          }
        })
      );

      setPrices(newPrices);
      setPriceChanges(priceChanges);
    };

    if (userData.length > 0) {
      fetchPrices();
    }
  }, [userData]);

  const createTableRow = (user: any, index: number) => {
    return (
      <TableRow key={user.userId || index} style={{ cursor: "pointer" }}>
        <>
          <TableData>
            <Link
              to={`/pair-explorers?id=${encodeURIComponent(
                user.userId
              )}&chain=${encodeURIComponent("8bit")}`}
            >
              {user.token0symbol} /{" "}
              <span style={{ fontSize: 12, color: "#cccccc" }}>
                {user.token1symbol}
              </span>
            </Link>
          </TableData>

          <TableData>
            <Link
              style={{
                color: priceChanges[user.pairAddress] >= 0 || isNaN(priceChanges[user.pairAddress]) ? "green" : "red",
              }}
              to={`/pair-explorers?id=${encodeURIComponent(
                user.userId
              )}&chain=${encodeURIComponent("8bit")}`}
            >
              $
              {prices[user.pairAddress]
                ? prices[user.pairAddress].toFixed(6)
                : "N/A"}
            </Link>
          </TableData>

          <TableData
          style={{
            color: priceChanges[user.pairAddress] >= 0 || isNaN(priceChanges[user.pairAddress]) ? "green" : "red",
          }}
        >
          {priceChanges[user.pairAddress] !== undefined && !isNaN(priceChanges[user.pairAddress])
            ? `${priceChanges[user.pairAddress]?.toFixed(2)}%`
            : "0%"}
        </TableData>
        </>
      </TableRow>
    );
  };

  const sortedUserData = [...userData].sort(
    (a, b) =>
      (priceChanges[a.pairAddress] || 0) - (priceChanges[b.pairAddress] || 0)
  );

  // Limit to top 3 gainers
  const topGainers = sortedUserData.slice(0, 3);

  return (
    <table>
      <tbody>
        {topGainers.map((user, index) => createTableRow(user, index))}
      </tbody>
    </table>
  );
};

export default AuditedProjects;
