import React, { useState, useEffect } from "react";
import firebase from "firebase/app";

import "firebase/database";
import { ReactComponent as eyeIcon } from "../../assets/icons/eye.svg";
import { Link } from "react-router-dom";
import { initializeApp } from "firebase/app";
import axios from "axios";
import { getDatabase, ref, onValue } from "firebase/database";
import styled from "styled-components";
import millify from "millify";
import { formatDistanceToNow } from "date-fns";

interface PairData {
  pairAddress: string;

  baseToken: {
    symbol: string;
  };
  quoteToken: {
    symbol: string;
  };
  priceUsd: number;
  priceNative: number;

  volume: {
    h24: number;
  };
  priceChange: {
    h24: number;
  };

  liquidity: {
    base: number;
    quote: number;
    usd: number;
  };
  pairCreatedAt: any;

  txns: {
    h24: {
      buys: number;
      sells: number;
    };
  };
  // Add other properties based on the actual structure of your data
}
// Styled Components
const Section = styled.section`
  padding: 20px 0;
  @media (max-width: 768px) {
    max-width:100%;
    overflow:auto
    margin-left:50px;
  }
  
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const SearchContainer = styled.div`
  margin: 20px 0;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 30px;
  @media (max-width: 768px) {
    max-width: 100%;
    overflow: auto;
  }
`;
const TableRow = styled.tr``;

const TableData = styled.td`
  padding: 10px;
  font-size: 14px;
  // width:fit-content;
  border-bottom: 1px solid #000;
`;

const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

const Button = styled.button`
  background-color: #23323c;
  color: #ffba00;
  border: 1px solid #000;
  padding: 8px 15px;
  border-radius: 7px;
  cursor: pointer;
`;
const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const PaginationButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const PageNumber = styled.div`
  font-size: 14px;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px; /* Adjust the height as needed */
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const MainTable = styled.div`
  width: 100%; /* Adjust the width for mobile screens */
  background: var(--background);
  padding: 0 20px 20px;
  border-radius: 10px;
margin-top:20px;
  .tablescroll {
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 2px; /* Adjust the scrollbar width as needed */
    }

    &::-webkit-scrollbar-track {
      background: #000; /* Track color */
    }

    /* Scrollbar Handle */
    &::-webkit-scrollbar-thumb {
      background: #a37800; /* Handle color */
    }

    /* Scrollbar Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #a37800; /* Handle color on hover */
    }

    // Add mobile styles here
    @media (max-width: 768px) {
      // Adjust styles for smaller screens
      width: 100%;
    }
  }
`;

const TableHead = styled.thead`
  background-color: #000;
  padding: 5px;
  height: 55px;

  border: none;

  th {
    padding: 10px;
    text-align: left;
    font-weight: bold;
    margin-bottom: 20px;
  }

  tr {
    padding: 5px;
  }
`;

// Firebase Configuration
const firebaseConfig = {
  apiKey: "AIzaSyBtG7oiNd9DLuA80uwsWzGG7zX7legmGPE",
  authDomain: "protracker-6c490.firebaseapp.com",
  databaseURL: "https://protracker-6c490-default-rtdb.firebaseio.com",
  projectId: "protracker-6c490",
  storageBucket: "protracker-6c490.appspot.com",
  messagingSenderId: "776220672739",
  appId: "1:776220672739:web:d71e515bb8f33673403bd6",
  measurementId: "G-GMYRXFBSRK",
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const userDataRef = ref(database, "projects");

const ITEMS_PER_PAGE = 10;

const AuditedProjects: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [userData, setUserData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true); // Add this line for loading state
  const [pairData, setPairData] = useState<PairData | null>(null);
  const [pairDataList, setPairDataList] = useState<PairData[]>([]);

  useEffect(() => {
    onValue(userDataRef, (snapshot) => {
      const data = snapshot.val();
      const filteredData = data ? Object.values(data) : [];

      if (searchTerm) {
        const searchTermLC = searchTerm.toLowerCase();
        const searchResults = filteredData.filter((user: any) =>
          user.name.toLowerCase().includes(searchTermLC)
        );
        setUserData(searchResults.reverse());
      } else {
        setUserData(filteredData.reverse());
      }
      setLoading(false); // Data loading is complete
    });
  }, [searchTerm]);

  const totalPages = Math.ceil(userData.length / ITEMS_PER_PAGE);
  const lastItemIndex = currentPage * ITEMS_PER_PAGE;
  const firstItemIndex = lastItemIndex - ITEMS_PER_PAGE;
  const displayedUserData = userData;
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const fetchPairData = async (user: any) => {
      try {
        const pairAddress = user.pairAddress;
        const chain = user.chain;

        const response = await axios.get(
          `https://openapi.dexview.com/latest/dex/pairs/${chain}/${pairAddress}`
        );
        const pairData: PairData = response.data.pair;
        setPairDataList((prevPairDataList) => [...prevPairDataList, pairData]);
      } catch (error) {
        console.error("Error fetching pair data:", error);
      }
    };

    const fetchDataSequentially = async () => {
      setPairDataList([]); // Reset pairDataList before fetching new data

      for (const user of userData) {
        await fetchPairData(user);
      }
      setLoading(false);
    };

    fetchDataSequentially();
  }, [userData]);

  const formatVolume = (volume: any) => {
    const abbreviations = ["K", "M", "B"];

    const format = (num: any, precision: any) => {
      const index = Math.floor(Math.log10(num) / 3);
      const scaledNum = num / Math.pow(1000, index);

      const formatter = new Intl.NumberFormat(undefined, {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
      });

      return formatter.format(scaledNum) + abbreviations[index - 1];
    };

    return format(volume, 2); // You can adjust the precision as needed
  };

  const noProjectsMessage = (
    <TableRow>
      <TableData colSpan={4} style={{ textAlign: "center", height: "100px" }}>
        No Project Found
      </TableData>
    </TableRow>
  );

  // Existing code...

  const createTableRow = (user: any, index: number) => (
    <TableRow key={user.userId || index} style={{ cursor: "pointer" }}>
      {pairDataList[index] && (
        <>
          <TableData>
          <Link to={`/pair-explorers?id=${encodeURIComponent(user.userId)}&chain=${encodeURIComponent('bsc')}`}>
              <ProfileImage src={user.logoPicture} alt="Logo Picture" />
            </Link>
          </TableData>

          {/* Check if pairDataList[index] and its properties are not null before accessing them */}
          {pairDataList[index] &&
            pairDataList[index].baseToken &&
            pairDataList[index].quoteToken && (
              <TableData>
                           <Link to={`/pair-explorers?id=${encodeURIComponent(user.userId)}&chain=${encodeURIComponent('bsc')}`}>

                  {pairDataList[index].baseToken.symbol}/
                  <span style={{ fontSize: 12, color: "#cccccc" }}>
                    {pairDataList[index].quoteToken.symbol}
                  </span>
                  <br />
                  <span style={{ fontSize: 12, color: "#cccccc" }}>
                    {pairDataList[index].pairAddress.slice(0, 5)}...
                    {pairDataList[index].pairAddress.slice(
                      pairDataList[index].pairAddress.length - 5
                    )}
                  </span>
                </Link>
              </TableData>
            )}
          <TableData>
            <span>{user.name}</span>
          </TableData>

          {/* Additional checks for other properties of pairDataList[index] */}
          {pairDataList[index] && pairDataList[index].priceUsd != null && (
            <TableData>
            <Link to={`/pair-explorers?id=${encodeURIComponent(user.userId)}&chain=${encodeURIComponent('bsc')}`}>

                ${" "}
                {millify(Number(pairDataList[index].priceUsd), {
                  precision: 5,
                })}
              </Link>
            </TableData>
          )}

          {pairDataList[index] && pairDataList[index].priceChange && (
            <TableData
              style={{
                color:
                  pairDataList[index].priceChange.h24 >= 0 ? "green" : "red",
              }}
            >
              {new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
              }).format(pairDataList[index].priceChange.h24)}{" "}
              %
            </TableData>
          )}

          {pairDataList[index] && pairDataList[index].priceChange && (
            <TableData>
              {formatDistanceToNow(
                new Date(pairDataList[index].pairCreatedAt * 1000),
                { addSuffix: true }
              )}
            </TableData>
          )}

          {pairDataList[index] && pairDataList[index].priceChange && (
            <TableData>
              ${" "}
              {new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
              }).format(pairDataList[index].volume.h24)}{" "}
            </TableData>
          )}

          {pairDataList[index] && pairDataList[index].priceChange && (
            <TableData>
              {formatVolume(pairDataList[index].liquidity.usd)}
            </TableData>
          )}

          {pairDataList[index] && pairDataList[index].priceChange && (
            <TableData>
              $ {formatVolume(pairDataList[index].priceUsd * user.supply)}
            </TableData>
          )}
        </>
      )}
    </TableRow>
  );

  // Existing code...

  const redirectToReport = (userId: string) => {
    const encodedName = encodeURIComponent(userId);
    window.location.href = `kycproject.html?name=${encodedName}`;
  };

  return (

        <MainTable>
          {loading ? ( // Conditional rendering based on loading state
            <LoaderContainer>
              <p>Loading...</p>
            </LoaderContainer>
          ) : (
            <div className="tablescroll">
              <Table>
                <TableHead>
                  <tr>
                    <th style={{ borderRadius: "10px 0 0 10px" }}>
                      <span style={{ marginLeft: "5px" }}>Logo</span>
                    </th>
                    <th style={{ minWidth: "fit-content" }}>Pair</th>
                    <th>Name</th>

                    <th>Price</th>
                    <th>%24h</th>
                    <th>Created</th>

                    <th>Volume</th>

                    <th>Liquidity</th>

                    <th style={{ borderRadius: "0 10px 10px 0" }}>Mkt. Cap.</th>
                  </tr>
                </TableHead>
                <tbody>
                  {displayedUserData.map((user, index) =>
                    createTableRow(user, index)
                  )}
                </tbody>
              </Table>
            </div>
          )}
          <PaginationContainer>
            <PageNumber>
              Page {currentPage} of {totalPages}
            </PageNumber>

            <PaginationButtons>
              <Button onClick={handlePreviousPage}>Previous</Button>

              <Button onClick={handleNextPage}>Next</Button>
            </PaginationButtons>
          </PaginationContainer>
        </MainTable>
 
  );
};

export default AuditedProjects;
