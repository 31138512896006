export const formatNumber = (value: number, min = 0, max = 6) => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: min,
    maximumFractionDigits: max,
  }).format(value);
};

export const formatAddress = (value: string) => {
  return `${value?.slice(0, 6)}...${value?.slice(value?.length - 6)}`;
};
