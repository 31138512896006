import React, { useState, useEffect } from "react";
import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";
import "./TopPairDataCard.scss";
import { ILiveData } from "../../constants/types";
import { ReactComponent as Icon } from "../../assets/icons/vr.svg";
import BNB from "../../assets/logos/bsc.png";
import BIT from "../../assets/images/LogoImg.png";

import { initializeApp } from "firebase/app";
import axios from "axios";
import { Link } from "react-router-dom";

import { getDatabase, ref, onValue } from "firebase/database";
import styled from "styled-components";
import millify from "millify";
import { formatDistanceToNow } from "date-fns";

interface PriceData {
  priceUsd: number;
  priceNative: number;
  type: string;
  amount: number;
  received: number;
  time: string;
  price: number;
}

interface PriceResponse {
  [key: string]: PriceData; // This allows indexing by pair address
}

interface PairData {
  pairAddress: string;

  baseToken: {
    symbol: string;
  };
  quoteToken: {
    symbol: string;
  };
  priceUsd: number;
  priceNative: number;

  volume: {
    h24: number;
  };
  priceChange: {
    h24: number;
  };

  liquidity: {
    base: number;
    quote: number;
    usd: number;
  };
  pairCreatedAt: any;

  txns: {
    h24: {
      buys: number;
      sells: number;
    };
  };
  // Add other properties based on the actual structure of your data
}

const LiveDataCard: React.FC = () => {
  // Firebase Configuration
  const firebaseConfig = {
    apiKey: "AIzaSyBtG7oiNd9DLuA80uwsWzGG7zX7legmGPE",
    authDomain: "protracker-6c490.firebaseapp.com",
    databaseURL: "https://protracker-6c490-default-rtdb.firebaseio.com",
    projectId: "protracker-6c490",
    storageBucket: "protracker-6c490.appspot.com",
    messagingSenderId: "776220672739",
    appId: "1:776220672739:web:d71e515bb8f33673403bd6",
    measurementId: "G-GMYRXFBSRK",
  };

  const app = initializeApp(firebaseConfig);
  const database = getDatabase(app);
  const userDataRef = ref(database, "Vr");

  const userDataRefs = ref(database, "projects");
  const bitUserDataRefs = ref(database, "8bitprojects");

  const ITEMS_PER_PAGE = 10;

  const [searchTerm, setSearchTerm] = useState("");
  const [searchTerms, setSearchTerms] = useState("");

  const [userData, setUserData] = useState<any[]>([]);
  const [userDatas, setUserDatas] = useState<any[]>([]);
  const [eightBitUserDatas, setEightBitUserDatas] = useState<any[]>([]); // State for 8bit projects

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true); // Add this line for loading state
  const [pairData, setPairData] = useState<PairData | null>(null);
  const [pairDataList, setPairDataList] = useState<PairData[]>([]);

  useEffect(() => {
    onValue(userDataRef, (snapshot) => {
      const data = snapshot.val();
      const filteredData = data ? Object.values(data) : [];

      if (searchTerm) {
        const searchTermLC = searchTerm.toLowerCase();
        const searchResults = filteredData.filter((user: any) =>
          user.name.toLowerCase().includes(searchTermLC)
        );
        setUserData(searchResults.reverse());
      } else {
        setUserData(filteredData.reverse());
      }
      setLoading(false); // Data loading is complete
    });
  }, [searchTerm]);

  useEffect(() => {
    onValue(userDataRefs, (snapshot) => {
      const datas = snapshot.val();
      const filteredDatas = datas ? Object.values(datas) : [];

      if (searchTerms) {
        const searchTermLCs = searchTerms.toLowerCase();
        const searchResultss = filteredDatas.filter((user: any) =>
          user.name.toLowerCase().includes(searchTermLCs)
        );
        setUserDatas(searchResultss.reverse());
      } else {
        setUserDatas(filteredDatas.reverse());
      }
      setLoading(false); // Data loading is complete
    });
  }, [searchTerms]);

  useEffect(() => {
    onValue(bitUserDataRefs, (snapshot) => {
      const datas = snapshot.val();
      const filteredDatas = datas ? Object.values(datas) : [];

      if (searchTerms) {
        const searchTermLCs = searchTerms.toLowerCase();
        const searchResultss = filteredDatas.filter((user: any) =>
          user.name.toLowerCase().includes(searchTermLCs)
        );
        setEightBitUserDatas(searchResultss.reverse());
      } else {
        setEightBitUserDatas(filteredDatas.reverse());
      }
      setLoading(false); // Data loading is complete
    });
  }, [searchTerms]);

  const totalPages = Math.ceil(userData.length / ITEMS_PER_PAGE);
  const lastItemIndex = currentPage * ITEMS_PER_PAGE;
  const firstItemIndex = lastItemIndex - ITEMS_PER_PAGE;
  const displayedUserData = userData;
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const [priceChanges, setPriceChanges] = useState<{ [key: string]: number }>(
    {}
  ); // Store price changes

  const [prices, setPrices] = useState<{ [key: string]: number }>({});
  const [pprices, setPPrices] = useState<{ [key: string]: number }>({});
  const [pnativeprices, setPNativePrices] = useState<{ [key: string]: number }>(
    {}
  );
  const [nativeprices, setNativePrices] = useState<{ [key: string]: number }>(
    {}
  );
  const [tradingVolumes, setTradingVolumes] = useState<{
    [key: string]: number;
  }>({});

  useEffect(() => {
    const fetchPrices = async () => {
      const newPrices: { [key: string]: number } = {};
      const previousPrices: { [key: string]: number } = {};
      const newPrice: { [key: string]: number } = {};
      const previousPrice: { [key: string]: number } = {};

      // Get today's date
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set time to midnight

      await Promise.all(
        eightBitUserDatas.map(async (user) => {
          const { pairAddress } = user;

          // Fetch trading volume separately

          try {
            const url =
              pairAddress === "0xCa63D9144b4B66513298DE7b80Ff897ff4ca25C7"
                ? `https://trade-c33f7-default-rtdb.firebaseio.com/nativeTxnHistory/.json`
                : `https://trade-c33f7-default-rtdb.firebaseio.com/txnHistory/${pairAddress}/.json`;

            const response = await axios.get<PriceResponse>(url);
            const priceData = response.data;

            if (priceData) {
              const allEntries = Object.values(priceData);

              const latestPriceEntry = allEntries.pop(); // Get the latest price entry
              if (latestPriceEntry) {
                newPrices[pairAddress] =
                  pairAddress === "0xCa63D9144b4B66513298DE7b80Ff897ff4ca25C7"
                    ? latestPriceEntry.price
                    : latestPriceEntry.priceUsd;
                newPrice[pairAddress] = latestPriceEntry.priceNative;

                // Calculate previous prices
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                yesterday.setHours(0, 0, 0, 0); // Set to midnight
                const endOfYesterday = new Date();
                endOfYesterday.setDate(endOfYesterday.getDate() - 1);
                endOfYesterday.setHours(23, 59, 59, 999); // Set to the end of yesterday

                const yesterdayEntries = allEntries.filter((entry) => {
                  const entryDate = new Date(entry.time);
                  return entryDate >= yesterday && entryDate <= endOfYesterday; // Ensure 'yesterday' is in scope
                });

                if (yesterdayEntries.length > 0) {
                  yesterdayEntries.sort(
                    (a, b) =>
                      new Date(a.time).getTime() - new Date(b.time).getTime()
                  );

                  const previousPriceEntry =
                    yesterdayEntries[yesterdayEntries.length - 1];

                  if (previousPriceEntry) {
                    previousPrice[pairAddress] = previousPriceEntry.priceNative;
                    previousPrices[pairAddress] = previousPriceEntry.priceUsd;
                  } else {
                  }
                } else {
                }
              }
            } else {
            }
          } catch (error) {}
        })
      );

      console.log(newPrices);
      setPrices(newPrices);
      setNativePrices(newPrice);
      setPPrices(previousPrices);
      setPNativePrices(previousPrice);
      setTradingVolumes(tradingVolumes); // Set trading volumes state

      const priceChanges: { [key: string]: number } = {};
      Object.keys(newPrices).forEach((pairAddress) => {
        const latestPrice = newPrice[pairAddress];
        const prevPrice = previousPrice[pairAddress];

        if (prevPrice) {
          priceChanges[pairAddress] =
            ((latestPrice - prevPrice) / prevPrice) * 100;
        } else {
          priceChanges[pairAddress] = 0;
        }
      });

      setPriceChanges(priceChanges);
    };

    if (userData.length > 0) {
      fetchPrices();
    }
  }, [userData]);

  useEffect(() => {
    const fetchPairData = async (user: any) => {
      try {
        const pairAddress = user.pairAddress;
        const chain = user.chain;

        const response = await axios.get(
          `https://openapi.dexview.com/latest/dex/pairs/${chain}/${pairAddress}`
        );
        const pairData: PairData = response.data.pair;
        setPairDataList((prevPairDataList) => [...prevPairDataList, pairData]);
      } catch (error) {
        console.error("Error fetching pair data:", error);
      }
    };

    const fetchDataSequentially = async () => {
      setPairDataList([]); // Reset pairDataList before fetching new data

      for (const user of userDatas) {
        await fetchPairData(user);
      }
      setLoading(false);
    };

    fetchDataSequentially();
  }, [userDatas]);

  const formatVolume = (volume: any) => {
    const abbreviations = ["K", "M", "B"];

    const format = (num: any, precision: any) => {
      const index = Math.floor(Math.log10(num) / 3);
      return (
        (num / Math.pow(1000, index)).toFixed(precision) +
        abbreviations[index - 1]
      );
    };

    return format(volume, 2); // You can adjust the precision as needed
  };

  const noProjectsMessage = (
    <tr>
      <td colSpan={4} style={{ textAlign: "center", height: "70px" }}>
        No Project Found
      </td>
    </tr>
  );

  // Existing code...
  const createTableRow = (user: any, index: number) => {

    const noProjects = user.nft1 === "-" && user.nft2 === "-" && user.nft3 === "-";

  // If no projects found, show the no project message
  if (noProjects) {
    return noProjectsMessage;
  }

  return (
    <tr key={user.userId || index} style={{ cursor: "pointer" }}>
      {userData && (
        <>
          {/* Add the necessary data from 'projects' */}
          {userDatas.map((project: any, index: number) => {
            if (project.userId === user.nft1 && user.chain1 === "bsc") {
              return (
                <tr key={project.userId}>
                  {userDatas && (
                    <>
                      <td style={{ color: "#ffba00" }}>#1</td>

                      <td>
                      <div style={{display:'flex',alignItems:'start'}}>

                        <img className="poklo" src={project.logoPicture}  />
                        <img className="pokool" src={BNB}  />
</div>
                      </td>

                      {pairDataList[index] &&
                        pairDataList[index].baseToken &&
                        pairDataList[index].quoteToken && (
                          <td>
                            <Link
                              to={`/pair-explorers?id=${encodeURIComponent(
                                project.userId
                              )}`}
                            >
                              {pairDataList[index].baseToken.symbol}/
                              <span style={{ fontSize: 12, color: "#cccccc" }}>
                                {pairDataList[index].quoteToken.symbol}
                              </span>
                            </Link>
                          </td>
                        )}

                      {pairDataList[index] &&
                        pairDataList[index].priceUsd != null && (
                          <td>
                            <Link
                              className="percents"
                              to={`/pair-explorers?id=${encodeURIComponent(
                                project.userId
                              )}`}
                              style={{
                                color:
                                  pairDataList[index].priceChange.h24 >= 0
                                    ? "green"
                                    : "red",
                              }}
                            >
                              ${" "}
                              {millify(Number(pairDataList[index].priceUsd), {
                                precision: 2,
                              })}
                            </Link>
                          </td>
                        )}

                      {pairDataList[index] &&
                        pairDataList[index].priceChange && (
                          <td
                            className="percent"
                            style={{
                              color:
                                pairDataList[index].priceChange.h24 >= 0
                                  ? "green"
                                  : "red",
                            }}
                          >
                            {new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 4,
                            }).format(pairDataList[index].priceChange.h24)}{" "}
                            %{" "}
                          </td>
                        )}
                    </>
                  )}
                </tr>
              );
            }
            return null;
          })}

          {/* Add the necessary data from 'projects' */}

          {eightBitUserDatas.map((project: any, index: number) => {
            if (project.userId === user.nft1 && user.chain1 === "8bit") {
              return (
                <tr key={project.userId}>
                  {eightBitUserDatas && (
                    <>
                      <td style={{ color: "#ffba00" }}>#1</td>

                      <td>
                        <div style={{display:'flex',alignItems:'start'}}>
                        <img className="poklo" src={project.logoPicture}  />
                        <img className="pokool" src={BIT}  />
                        </div>
                      </td>

                      <td>
                        <Link
                          to={`/pair-explorers?id=${encodeURIComponent(
                            project.userId
                          )}`}
                        >
                          {project.token0symbol}/
                          <span style={{ fontSize: 12, color: "#cccccc" }}>
                            {project.token1symbol}
                          </span>
                        </Link>
                      </td>

                      <td>
                        <Link
                          className="percents"
                          to={`/pair-explorers?id=${encodeURIComponent(
                            project.userId
                          )}`}
                          style={{
                            color:
                              priceChanges[project.pairAddress] >= 0
                                ? "green"
                                : "red",
                          }}
                        >
                          $
                          {prices[project.pairAddress]
                            ? prices[project.pairAddress].toFixed(6)
                            : "N/A"}
                        </Link>
                      </td>

                      <td
                        className="percent"
                        style={{
                          color:
                            priceChanges[project.pairAddress] >= 0
                              ? "green"
                              : "red",
                        }}
                      >
                        {" "}
                        {priceChanges[project.pairAddress] !== undefined
                          ? `${priceChanges[project.pairAddress]?.toFixed(2)}%`
                          : "N/A"}
                      </td>
                    </>
                  )}
                </tr>
              );
            }
            return null;
          })}

          {/* Add the necessary data from 'projects' */}
          {userDatas.map((project: any, index: number) => {
            if (project.userId === user.nft2 && user.chain2 === "bsc") {
              return (
                <tr key={project.userId}>
                  {userDatas && (
                    <>
                      <td style={{ color: "#ffba00" }}>#2</td>

                      <td>
                      <div style={{display:'flex',alignItems:'start'}}>
                        <img className="poklo" src={project.logoPicture}  />
                        <img className="pokool" src={BNB}  />
                        </div>                      </td>

                      {pairDataList[index] &&
                        pairDataList[index].baseToken &&
                        pairDataList[index].quoteToken && (
                          <Link
                            to={`/pair-explorers?id=${encodeURIComponent(
                              project.userId
                            )}`}
                          >
                            <td style={{ cursor: "pointer" }}>
                              {pairDataList[index].baseToken.symbol}/
                              <span style={{ fontSize: 12, color: "#cccccc" }}>
                                {pairDataList[index].quoteToken.symbol}
                              </span>
                            </td>
                          </Link>
                        )}

                      {pairDataList[index] &&
                        pairDataList[index].priceUsd != null && (
                          <td>
                            <Link
                              className="percents"
                              to={`/pair-explorers?id=${encodeURIComponent(
                                project.userId
                              )}`}
                              style={{
                                color:
                                  pairDataList[index].priceChange.h24 >= 0
                                    ? "green"
                                    : "red",
                              }}
                            >
                              ${" "}
                              {millify(Number(pairDataList[index].priceUsd), {
                                precision: 2,
                              })}
                            </Link>
                          </td>
                        )}

                      {pairDataList[index] &&
                        pairDataList[index].priceChange && (
                          <td
                            className="percent"
                            style={{
                              color:
                                pairDataList[index].priceChange.h24 >= 0
                                  ? "green"
                                  : "red",
                            }}
                          >
                            {new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 4,
                            }).format(pairDataList[index].priceChange.h24)}{" "}
                            %{" "}
                          </td>
                        )}
                    </>
                  )}
                </tr>
              );
            }
            return null;
          })}

          {eightBitUserDatas.map((project: any, index: number) => {
            if (project.userId === user.nft2 && user.chain2 === "8bit") {
              return (
                <tr key={project.userId}>
                  {eightBitUserDatas && (
                    <>
                      <td style={{ color: "#ffba00" }}>#2</td>

                      <td>
                      <div style={{display:'flex',alignItems:'start'}}>
                        <img className="poklo" src={project.logoPicture}  />
                        <img className="pokool" src={BIT}  />
                        </div>                      </td>

                      <td>
                        <Link
                          to={`/pair-explorers?id=${encodeURIComponent(
                            project.userId
                          )}`}
                        >
                          {project.token0symbol}/
                          <span style={{ fontSize: 12, color: "#cccccc" }}>
                            {project.token1symbol}
                          </span>
                        </Link>
                      </td>

                      <td>
                        <Link
                          className="percents"
                          to={`/pair-explorers?id=${encodeURIComponent(
                            project.userId
                          )}`}
                          style={{
                            color:
                              priceChanges[project.pairAddress] >= 0
                                ? "green"
                                : "red",
                          }}
                        >
                          $
                          {prices[project.pairAddress]
                            ? prices[project.pairAddress].toFixed(6)
                            : "N/A"}
                        </Link>
                      </td>

                      <td
                        className="percent"
                        style={{
                          color:
                            priceChanges[project.pairAddress] >= 0
                              ? "green"
                              : "red",
                        }}
                      >
                        {" "}
                        {priceChanges[project.pairAddress] !== undefined
                          ? `${priceChanges[project.pairAddress]?.toFixed(2)}%`
                          : "N/A"}
                      </td>
                    </>
                  )}
                </tr>
              );
            }
            return null;
          })}

          {userDatas.map((project: any, index: number) => {
            if (project.userId === user.nft3 && user.chain3 === "bsc") {
              return (
                <tr key={project.userId}>
                  {userDatas && (
                    <>
                      <td style={{ color: "#ffba00" }}>#3</td>

                      <td>
                      <div style={{display:'flex',alignItems:'start'}}>
                        <img className="poklo" src={project.logoPicture}  />
                        <img className="pokool" src={BNB}  />
                        </div>                      </td>

                      {pairDataList[index] &&
                        pairDataList[index].baseToken &&
                        pairDataList[index].quoteToken && (
                          <Link
                            to={`/pair-explorers?id=${encodeURIComponent(
                              project.userId
                            )}`}
                          >
                            <td style={{ cursor: "pointer" }}>
                              {pairDataList[index].baseToken.symbol}/
                              <span style={{ fontSize: 12, color: "#cccccc" }}>
                                {pairDataList[index].quoteToken.symbol}
                              </span>
                            </td>
                          </Link>
                        )}

                      {pairDataList[index] &&
                        pairDataList[index].priceUsd != null && (
                          <td>
                            <Link
                              className="percents"
                              to={`/pair-explorers?id=${encodeURIComponent(
                                project.userId
                              )}`}
                              style={{
                                color:
                                  pairDataList[index].priceChange.h24 >= 0
                                    ? "green"
                                    : "red",
                              }}
                            >
                              ${" "}
                              {millify(Number(pairDataList[index].priceUsd), {
                                precision: 2,
                              })}
                            </Link>
                          </td>
                        )}

                      {pairDataList[index] &&
                        pairDataList[index].priceChange && (
                          <td
                            className="percent"
                            style={{
                              color:
                                pairDataList[index].priceChange.h24 >= 0
                                  ? "green"
                                  : "red",
                            }}
                          >
                            {new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 4,
                            }).format(pairDataList[index].priceChange.h24)}{" "}
                            %{" "}
                          </td>
                        )}
                    </>
                  )}
                </tr>
              );
            }
            return null;
          })}

          {eightBitUserDatas.map((project: any, index: number) => {
            if (project.userId === user.nft3 && user.chain3 === "8bit") {
              return (
                <tr key={project.userId}>
                  {eightBitUserDatas && (
                    <>
                      <td style={{ color: "#ffba00" }}>#3</td>

                      <td>
                      <div style={{display:'flex',alignItems:'start'}}>
                        <img className="poklo" src={project.logoPicture}  />
                        <img className="pokool" src={BIT}  />
                        </div>                      </td>

                      <td>
                        <Link
                          to={`/pair-explorers?id=${encodeURIComponent(
                            project.userId
                          )}`}
                        >
                          {project.token0symbol}/
                          <span style={{ fontSize: 12, color: "#cccccc" }}>
                            {project.token1symbol}
                          </span>
                        </Link>
                      </td>

                      <td>
                        <Link
                          className="percents"
                          to={`/pair-explorers?id=${encodeURIComponent(
                            project.userId
                          )}`}
                          style={{
                            color:
                              priceChanges[project.pairAddress] >= 0
                                ? "green"
                                : "red",
                          }}
                        >
                          $
                          {prices[project.pairAddress]
                            ? prices[project.pairAddress].toFixed(6)
                            : "N/A"}
                        </Link>
                      </td>

                      <td
                        className="percent"
                        style={{
                          color:
                            priceChanges[project.pairAddress] >= 0
                              ? "green"
                              : "red",
                        }}
                      >
                        {" "}
                        {priceChanges[project.pairAddress] !== undefined
                          ? `${priceChanges[project.pairAddress]?.toFixed(2)}%`
                          : "N/A"}
                      </td>
                    </>
                  )}
                </tr>
              );
            }
            return null;
          })}
        </>
      )}
    </tr>
  )};

  return (
    <div className="top-data-card-wrapper">
      <div className="border-animation-wrapper">
        <div className="border-animation"></div>
      </div>
      <div className="live-data-card-container">
        <div className="chat-ico">
          <div className="inner-chat">
            <Icon />
          </div>
        </div>

        <div className="live-data-card flex-item">
          <div className="daily-gainers-heading flex-item">
            <p>Vr Projects</p>
          </div>
          <div className="more-content flex-item"></div>
        </div>

        <table>
          <tbody>
            {displayedUserData.map((user, index) =>
              createTableRow(user, index)
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LiveDataCard;
