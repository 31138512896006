import React, { useState, useEffect } from 'react';
import { erc20ABI, useChainId, useContractRead, useContractReads } from 'wagmi';
import { pairABI } from '../../utils/abis/pairABI';
import { factoryABI } from '../../utils/abis/factoryABI';
import { FACTORY_ADDRESS } from '../../utils/address';
import styled from 'styled-components';
import axios from "axios";

interface ILiquidityInfoCardProps {
    token0: string;
    token1: string;
}


const Section = styled.section`
  padding: 0px 0;
  @media (max-width: 768px) {
    max-width:100%;
    overflow:auto;
    margin-left:0px;
  }
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 30px;
  @media (max-width: 768px) {
    max-width:100%;
    overflow:auto;
  }
`;

const TableRow = styled.tr``;

const TableData = styled.td`
  padding: 3px 10px !important;
  
  font-size: 12px;
  border-bottom: 1px solid #000;
`;

const Button = styled.button`
  background-color: #000;
  color: #ffba00;
  border: 1px solid #000;
  padding: 6px 10px;
  font-size: 12px;
  border-radius: 7px;
  cursor: pointer;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const PaginationButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const PageNumber = styled.div`
  font-size: 12px;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const MainTable = styled.div`
  width: 100%;
  background: var(--background);
  padding: 0 20px 20px;
  border-radius: 10px;

  .tablescroll {
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 2px;
    }

    &::-webkit-scrollbar-track {
      background: #000;
    }
    
    &::-webkit-scrollbar-thumb {
      background: #a37800;
    }
    
    &::-webkit-scrollbar-thumb:hover {
      background: #a37800;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const TableHead = styled.thead`
  background-color: #000;
  padding: 5px;
  height: 40px;

  th {
    padding: 0px;
    text-align: left;
    font-size:12px;
    font-weight: bold;
  }

  tr {
    padding: 5px;
  }
`;


// Firebase Configuration
const FIREBASE_DATABASE_URL = "https://trade-c33f7-default-rtdb.firebaseio.com/nativeTxnHistory/.json";

const ITEMS_PER_PAGE = 10;

const AuditedProjects: React.FC<ILiquidityInfoCardProps> = ({ token0, token1 }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [userData, setUserData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [token0Symbol, setToken0Symbol] = useState<string>('');
  const [token1Symbol, setToken1Symbol] = useState<string>('');
  const chainId = useChainId() as keyof typeof FACTORY_ADDRESS;

  const { data: pair } = useContractRead({
    abi: factoryABI,
    address: FACTORY_ADDRESS[chainId] as `0x${string}`,
    functionName: 'getPair',
    args: [token0 as any, token1 as any],
  });

  const { data: token0Address } = useContractRead({
    abi: pairABI,
    address: pair as any,
    functionName: 'token0',
  });

  const { data: token1Address } = useContractRead({
    abi: pairABI,
    address: pair as any,
    functionName: 'token1',
  });

  const { data: tokenData } = useContractReads({
    contracts: [
      {
        abi: erc20ABI,
        address: token0Address || (token0 as any),
        functionName: 'symbol',
      },
      {
        abi: erc20ABI,
        address: token1Address || (token1 as any),
        functionName: 'symbol',
      },
    ],
  });

  useEffect(() => {
    if (tokenData) {
      setToken0Symbol(tokenData[0]?.result || token0);
      setToken1Symbol(tokenData[1]?.result || token1);
    }
  }, [tokenData, token0, token1]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(FIREBASE_DATABASE_URL);
        const data = response.data;
        const filteredData = data ? Object.values(data) : [];

        if (searchTerm) {
          const searchTermLC = searchTerm.toLowerCase();
          const searchResults = filteredData.filter((user: any) =>
            user.name.toLowerCase().includes(searchTermLC)
          );
          setUserData(searchResults.reverse());
        } else {
          setUserData(filteredData.reverse());
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data from Firebase:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [searchTerm]);

  const totalPages = Math.ceil(userData.length / ITEMS_PER_PAGE);
  const lastItemIndex = currentPage * ITEMS_PER_PAGE;
  const firstItemIndex = lastItemIndex - ITEMS_PER_PAGE;
  const displayedUserData = userData.slice(firstItemIndex, lastItemIndex);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const shortenHash = (hash: string) => `${hash.slice(0, 6)}...${hash.slice(-4)}`;
  const shortenAddress = (address: string) => `${address.slice(0, 6)}...${address.slice(-4)}`;

  const formatDate = (timestamp: string) => {
    const date = new Date(timestamp);
    return date.toLocaleString(); // Convert to local time string
  };

  const createTableRow = (user: any, index: number) => (
    <TableRow key={user.userId || index}>
      <TableData>
        <a
          href={`https://8bitscan.com/tx/${user.txnHash}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#ffba00', textDecoration: 'none', backgroundColor: 'transparent' }}
        >
          {shortenHash(user.txnHash)}
        </a>
      </TableData>
      <TableData>
        <span>$ {(user.price * user.amount).toFixed(7)}</span>
      </TableData>
      <TableData>
        <span>{user.type === 'sell' ? (Number(user.amount) || 0).toFixed(7) : (Number(user.recieved) || 0).toFixed(7)}</span>
      </TableData>
      <TableData>
        <span>{user.type === 'sell' ? (Number(user.recieved) || 0).toFixed(7) : (Number(user.amount) || 0).toFixed(7)}</span>
      </TableData>
      <TableData style={{ color: user.type === 'buy' ? '#48bb78' : '#f56565' }}>
        <span>{user.type === 'buy' ? 'Buy' : 'Sell'}</span>
      </TableData>
      <TableData>
        <span>{formatDate(user.time)}</span>
      </TableData>
      <TableData>
        <span>{shortenAddress(user.address)}</span>
      </TableData>
    </TableRow>
  );

  return (
    <Section>
      <Container>
       
        {loading ? (
          <LoaderContainer>
            <div>Loading...</div>
          </LoaderContainer>
        ) : displayedUserData.length > 0 ? (
          <MainTable>
            <Table className="tablescroll">
              <TableHead>
                <TableRow>
                <th style={{ borderRadius: '10px 0 0 10px' }}>
                      <span style={{ marginLeft: '10px' }}>Txn. Hash</span>
                    </th>                  <th>Value</th>
                    <th>Amt. W8Bit</th>
                  <th>Amt. 8bitUSD</th>
                  <th>Type</th>
                  <th>Date</th>
                  <th>Address</th>
                </TableRow>
              </TableHead>
              <tbody>
                {displayedUserData.map(createTableRow)}
              </tbody>
            </Table>
            <PaginationContainer>
              <PaginationButtons>
                <Button onClick={handlePreviousPage} disabled={currentPage === 1}>
                  Previous
                </Button>
                <Button onClick={handleNextPage} disabled={currentPage === totalPages}>
                  Next
                </Button>
              </PaginationButtons>
              <PageNumber>
                Page {currentPage} of {totalPages}
              </PageNumber>
            </PaginationContainer>
          </MainTable>
        ) : (
          <div>No transaction history found.</div>
        )}
      </Container>
    </Section>
  );
};

export default AuditedProjects;
