import React, {useRef, useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ReactComponent as Down } from "../../assets/icons/chevron-down.svg";

import { ReactComponent as InfoIcon } from "../../assets/icons/info.svg";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevron-down.svg";
import BitGainer from "./bitpairs";
import BscGainer from "./bscpairs";
import "../../components/PairsList/PairsList.scss";
import "./LiveNewPairs.scss";
import MarkQuee from "../MarkQuee";
import { PANCAKE_SUBGRAPH_API, UNISWAP_SUBGRAPH_API } from "../../utils/api";
import { IPair } from "../../constants/types";
import Pair from "./Pair";

import firebase from 'firebase/app';

import 'firebase/database';
import { ReactComponent as eyeIcon } from "../../assets/icons/eye.svg";
import { Link } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';
import styled from 'styled-components';
import millify from "millify";
import { formatDistanceToNow } from 'date-fns';




// Custom Dropdown Styled Components
const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownButton = styled.button`
  background-color: transparent; /* Background color */
  color: #ffba00; /* Text color */
  border:none;
  border-bottom: 1px solid #ffba00; /* Border for dropdown */
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  width: 130px; /* Adjust width as needed */
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    border-color: #a37800; /* Change border color on hover */
  }
`;

const DropdownList = styled.ul`
  position: absolute;
  background-color: black; /* Background for dropdown options */
  color: #ffba00; /* Text color for options */
  list-style: none;
  padding: 0;
  margin: 0;
  font-size:13px;
  border: 1px solid #a37800; /* Border for dropdown */
  border-radius: 4px;
  z-index: 1;
  width: 100%;
  max-height: 200px; /* Set max height for dropdown */
  overflow-y: auto; /* Enable scrolling */
`;

const DropdownItem = styled.li`
  padding: 10px 15px;
  cursor: pointer;

  &:hover {
  }
`;


// Define the props type for Icon
interface IconProps {
  isOpen: boolean;
}

const Icon = styled.span<IconProps>`
  margin-left: 10px;
  transition: transform 0.2s ease;
  width: 13px; /* Set the width to 10px */
  height: 13px; /* Set the height to 10px */
  ${(props) => props.isOpen && "transform: rotate(180deg);"}

  svg {
    width: 100%; /* Ensure SVG takes the full width */
    height: auto; /* Maintain aspect ratio */
    fill: #ffba00; /* Ensure the SVG is filled with the desired color */
  }
`;


const LiveNewPairs = () => {

  
  const [selectedChain, setSelectedChain] = useState('8BIT Chain');
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleChainChange = (chain: string) => {
    setSelectedChain(chain);
    setIsOpen(false); // Close dropdown after selection
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  return (
    <>
      <MarkQuee />
      <div className="live-new-pairs-wrapper">
      <h2>RECENTLY LISTED PAIRS</h2>

        <div className="polopop">
          <div>
        <div className="para flex-item">
          <p>
            New pairs listed on <span>PROTRACKERS</span> exchanges with pool variation in real time
          </p>
        </div>
        </div>
              <DropdownContainer ref={dropdownRef}>
          <DropdownButton onClick={toggleDropdown}>
            {selectedChain}
            <Icon isOpen={isOpen}><Down /></Icon> {/* Dropdown icon */}
          </DropdownButton>
          {isOpen && (
            <DropdownList>
              <DropdownItem onClick={() => handleChainChange("8BIT Chain")}>
                8BIT Chain
              </DropdownItem>
              <DropdownItem onClick={() => handleChainChange("BSC Chain")}>
                BSC Chain
              </DropdownItem>
            </DropdownList>
          )}
        </DropdownContainer>
        </div>
        {selectedChain === '8BIT Chain' ? <BitGainer /> : <BscGainer />}

      </div>
    </>
  );
};

export default LiveNewPairs;
