import React, { useEffect, useState } from "react";
import { ReactComponent as Infoicon } from "../../../assets/icons/infoIcon.svg";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info.svg";
// import { ReactComponent as SpeedIcon } from "../../../assets/icons/speed.svg";
import { ReactComponent as MintIcon } from "../../../assets/icons/mint.svg";
import { ReactComponent as LockIcon } from "../../../assets/icons/lock.svg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactComponent as ShareIcon } from "../../../assets/icons/shareIcon.svg";
import { ReactComponent as FeesIcon } from "../../../assets/icons/fees.svg";
import { ReactComponent as ChevronDownIcon } from "../../../assets/icons/chevron-down.svg";
import { ReactComponent as TopArrowIcon } from "../../../assets/icons/topArrow.svg";
import { ReactComponent as ForwardIcon } from "../../../assets/icons/Forward.svg";
import { ReactComponent as ThumbUpHandIcon } from "../../../assets/icons/thumbUpHand.svg";
import { ReactComponent as RoundedFlatIcon } from "../../../assets/icons/roundedFlatIcon.svg";
import GoPlusImg from "../../../assets/images/proassure.png";
import { GoPlusSecurity } from "../../../data/goPlusSecurity";
import "./DextScore.scss";
import { initializeApp } from 'firebase/app';
import { getDatabase, ref as dataRef, push, set, Database } from 'firebase/database';

import { get, ref, onValue } from 'firebase/database';
import axios from "axios"; 

import { useAccount } from "wagmi";

interface PairData {
  is_honeypot:number;
  is_mintable:number;
  is_proxy:number;
dex:[{
  pair:string;
}]
  buy_tax:number;
  sell_tax:number;
  code: number;
  message:string;
  
}

const DextScore = () => {
  const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);
  
  const [userData, setUserData] = useState<any>({}); // Change 'any' to a more appropriate type
  const [isLoading, setIsLoading] = useState(true);

  const [token, setToken] = useState<string>("");
  const [pair, setPair] = useState<string>("");
  const [id, setId] = useState<string>("");

  useEffect(() => {
    const idParam = new URLSearchParams(window.location.search).get('id');
    setId(idParam ?? "");
    // ... rest of the code
  }, []);
  const [goPlusSecurityDetails, setGoPlusSecurityDetails] = useState<PairData | null>(null);
  const firebaseConfig = {
    apiKey: "AIzaSyBtG7oiNd9DLuA80uwsWzGG7zX7legmGPE",
    authDomain: "protracker-6c490.firebaseapp.com",
    databaseURL: "https://protracker-6c490-default-rtdb.firebaseio.com",
    projectId: "protracker-6c490",
    storageBucket: "protracker-6c490.appspot.com",
    messagingSenderId: "776220672739",
    appId: "1:776220672739:web:d71e515bb8f33673403bd6",
    measurementId: "G-GMYRXFBSRK"
  };

      const app = initializeApp(firebaseConfig);
      const database = getDatabase(app);

  useEffect(() => {
    // Your Firebase configuration
    const firebaseConfig = {
      apiKey: "AIzaSyBtG7oiNd9DLuA80uwsWzGG7zX7legmGPE",
      authDomain: "protracker-6c490.firebaseapp.com",
      databaseURL: "https://protracker-6c490-default-rtdb.firebaseio.com",
      projectId: "protracker-6c490",
      storageBucket: "protracker-6c490.appspot.com",
      messagingSenderId: "776220672739",
      appId: "1:776220672739:web:d71e515bb8f33673403bd6",
      measurementId: "G-GMYRXFBSRK"
    };
    

        const app = initializeApp(firebaseConfig);
        const database = getDatabase(app);
    
        const id = new URLSearchParams(window.location.search).get('id');
    
        if (id) {
          const userDataRef = ref(database, `8bitprojects/${id}`);
          onValue(userDataRef, (snapshot) => {
            const fetchedData = snapshot.val();
            if (fetchedData) {
              setUserData(fetchedData);
            }
            setIsLoading(false); // Data fetched, loading is done
          });
        }
        
        // Simulate loading for 2 seconds
        const loadingTimeout = setTimeout(() => {
            setIsLoading(false);
          }, 3000);
      
          // Clean up the timeout when the component unmounts or when data is fetched
          return () => {
            clearTimeout(loadingTimeout);
            if (!isLoading) {
              clearTimeout(loadingTimeout);
            }
          };
        }, []);

  
  const { address } = useAccount();



  const toggleInfoDisplay = () => {
    setShowMoreInfo((prevShowMoreInfo) => !prevShowMoreInfo);
  };

  

  const [likes, setLikes] = useState<number>(0);
  const [unlikes, setUnlikes] = useState<number>(0);
  const [hasLiked, setHasLiked] = useState<boolean>(false);
  const [hasUnliked, setHasUnliked] = useState<boolean>(false);
  const [userId, setUserId] = useState<string | null>(null);

  // ... (existing code)

  useEffect(() => {
    // Fetch likes and unlikes from the database
    const likesRef = ref(database, `likes/${id}/likes`);
    const unlikesRef = ref(database, `likes/${id}/unlikes`);

    onValue(likesRef, (snapshot) => {
      const likesData = snapshot.val();
      setLikes(likesData || 0);
    });

    onValue(unlikesRef, (snapshot) => {
      const unlikesData = snapshot.val();
      setUnlikes(unlikesData || 0);
    });

    // Check if the current user has liked or unliked the item
    if (address) {
      setUserId(address);

      const userLikeRef = ref(database, `likes/${id}/users/${address}/like`);
      const userUnlikeRef = ref(database, `likes/${id}/users/${address}/unlike`);

      onValue(userLikeRef, (snapshot) => {
        setHasLiked(snapshot.exists());
      });

      onValue(userUnlikeRef, (snapshot) => {
        setHasUnliked(snapshot.exists());
      });
    }
  }, [address, id]);

  // Function to handle like
  const handleLike = async () => {
    try {
      if (!userId) {
        return toast.error("Connect Wallet to Like", {
          theme: "dark",
        });
      }
      if (hasLiked) {
        return toast.error("You have already liked this token.", {
          theme: "dark",
        });
      }
      if (hasUnliked) {
        return toast.error("You have already unliked this token.", {
          theme: "dark",
        });
      }

      // Like
      setLikes((prevLikes) => prevLikes + 1);
      setHasLiked(true);
      // Add the like to the database
      await set(ref(database, `likes/${id}/users/${userId}/like`), true);
      // Update the total likes in the database
      await set(ref(database, `likes/${id}/likes`), likes + 1);

      // If the user has previously unliked, remove the unlike data
      if (hasUnliked) {
        await set(ref(database, `likes/${id}/users/${userId}/unlike`), null);
        setUnlikes((prevUnlikes) => prevUnlikes - 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Function to handle unlike
  const handleUnlike = async () => {
    try {
      if (!userId) {
        return toast.error("Connect Wallet to Like", {
          theme: "dark",
        });
      }
      if (hasLiked) {
        return toast.error("You have already liked this token.", {
          theme: "dark",
        });
      }
      if (hasUnliked) {
        return toast.error("You have already unliked this token.", {
          theme: "dark",
        });
      }

      // Unlike
      setUnlikes((prevUnlikes) => prevUnlikes + 1);
      setHasUnliked(true);
      // Add the unlike to the database
      await set(ref(database, `likes/${id}/users/${userId}/unlike`), true);
      // Update the total unlikes in the database
      await set(ref(database, `likes/${id}/unlikes`), unlikes + 1);

      // If the user has previously liked, remove the like data
      if (hasLiked) {
        await set(ref(database, `likes/${id}/users/${userId}/like`), null);
        setLikes((prevLikes) => prevLikes - 1);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="dextScore-wrapper">
      <div className="dext-score">
        {/* <div className="score">
          <SpeedIcon />
          <span>--</span>
        </div> */}
        <div className="community-precentage flex-item">
          <div className="flexs-item green-icon">
            <p>0%</p>
          </div>
          <div className="progress-bars">
            <div style={{ width: `${userData.score}%` }}></div>
          </div>
          <div className="flexs-item brown-icon">
            <p>100%</p>
          </div>
        </div>
        <div className="progress-bar-center">
        <div >{userData.score}%</div>

        </div>
        <div className="dext-score-para">
          <h5>Pro Score</h5>
        </div>
        <div className="contract-details">
          <div className="flexs-item">
            <h5>CONTRACT DETAILS</h5>
          </div>

          <div className="go-plus-security-wrapper">
          
              <div className="go-plus-security-item flex-item">
                <div className="info-icon">
                  <p>
                    <span>TOKEN ADDRESS</span>
                  </p>
                </div>
                <div className="flex-item">
                  <p>      {userData.contract ? `${userData.contract.slice(0, 7)}...${userData.contract.slice(-7)}` : 'Loading...'}
</p>
                </div>
              </div>

              <div className="go-plus-security-item flex-item">
                <div className="info-icon">
                  <p>
                    <span>PAIR ADDRESS</span>
                  </p>
                </div>
                <div className="flex-item">
                <p>      {userData.pairAddress ? `${userData.pairAddress.slice(0, 7)}...${userData.pairAddress.slice(-7)}` : 'Loading...'}
</p>      </div>
              </div>

        

             
         
    
        </div>
   
        
    
        </div>
      <div  className="poloko"> 
        <div className="goPlus-imgs flex-item">
          <img src={GoPlusImg} alt="" />
          <p>ProAssure Security</p>
        </div>
        <div style={{marginTop:22}}><p>Data As On<br/><b style={{fontWeight:700}}>{userData.status}</b></p></div>
        </div>
        <div className="go-plus-security-wrapper">
            <>
              <div className="go-plus-security-item flex-item">
                <div className="info-icon">
                  <p>
                    <span>Contract Verified</span>
                  </p>
                </div>
                <div className="flex-item">
                  <p>{userData.verified}</p>
                </div>
              </div>

              <div className="go-plus-security-item flex-item">
                <div className="info-icon">
                  <p>
                    <span>Honeypot</span>
                  </p>
                </div>
                <div className="flex-item">
                  <p>{userData.honeypot}</p>
                </div>
              </div>

              <div className="go-plus-security-item flex-item">
                <div className="info-icon">
                  <p>
                    <span>Buy Tax</span>
                  </p>
                </div>
                <div className="flex-item">
                  <p>{userData.buy} %</p>
                </div>
              </div>

              <div className="go-plus-security-item flex-item">
                <div className="info-icon">
                  <p>
                    <span>Sell Tax</span>
                  </p>
                </div>
                <div className="flex-item">
                  <p>{userData.sell} %</p>
                </div>
              </div>


              <div className="go-plus-security-item flex-item">
                <div className="info-icon">
                  <p>
                    <span>Is Proxy</span>
                  </p>
                </div>
                <div className="flex-item">
                  <p>{userData.proxy}</p>
                </div>
              </div>

              <div className="go-plus-security-item flex-item">
                <div className="info-icon">
                  <p>
                    <span>Is Mintable</span>
                  </p>
                </div>
                <div className="flex-item">
                  <p>{userData.mintable}</p>
                </div>
              </div>
              {/* Add other security details using the provided data */}
            </>
 


     

     
      
             
         
    
        </div>
   
        <div className="flex-item community-head">
          <h5>Trust Metric</h5>
        </div>
        <div className="community-precentage flex-item">
          <div
            onClick={!hasLiked ? handleLike : undefined}

            className="flexs-item green-icon"

          >
            <ThumbUpHandIcon />
            <p>{(likes*100 / (likes+unlikes)).toFixed(2)}%</p>

          </div>
          <div className="progress-bar">
          <div style={{ width: `${(likes*100 / (likes+unlikes)).toFixed(2)}%` }}></div>
          </div>
          <div
            onClick={!hasUnliked ? handleUnlike : undefined}

            className="flexs-item brown-icon"

          >
            <RoundedFlatIcon />
            <p>{(unlikes*100 / (likes+unlikes)).toFixed(2)}%</p>

          </div>
        </div>
        <div className="border-bottom"></div>
        <ToastContainer />

      </div>
    </div>
  );
};

export default DextScore;
