import { Chain, configureChains, createConfig } from "wagmi";
import { w3mConnectors, w3mProvider } from "@web3modal/ethereum";
import { bsc, mainnet } from "wagmi/chains";

export const projectId = "4af4492230df1c074def2de12bdbbb0a";


const EightBitChainMainnet: Chain = {
  id: 8088,
  name: "8Bit Chain Mainnet",
  network: "eightbitchainmainnet", // Add the network property
  nativeCurrency: { name: "8Bit Chain", symbol: "8Bit", decimals: 18 },
  rpcUrls: {
    default: { http: ["https://rpc.8bitscan.com"] },
    public: { http: ["https://rpc.8bitscan.com"] },
  },
  blockExplorers: {
    default: { name: "8Bit Chain Mainnet", url: "https://8bitscan.com" },
  },
};

export const chains = [EightBitChainMainnet,bsc, mainnet];
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});
