import React, { useState, useEffect } from 'react';
import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";
import "../AI/TopPairDataCard.scss";
import { ILiveData } from "../../constants/types";
import { ReactComponent as Icon } from "../../assets/icons/wallofshame.svg";
import { initializeApp } from 'firebase/app';
import axios from "axios";
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { getDatabase, ref, onValue } from 'firebase/database';
import styled from 'styled-components';
import millify from "millify";
import { formatDistanceToNow } from 'date-fns';

interface PairData {
  pairAddress: string;
  baseToken: {
    symbol: string;
  };
  quoteToken: {
    symbol: string;
  };
  priceUsd: number;
  priceNative: number;
  volume: {
    h24: number;
  };
  priceChange: {
    h24: number;
  };
  liquidity: {
    base: number;
    quote: number;
    usd: number;
  };
  pairCreatedAt: any;
  txns: {
    h24: {
      buys: number;
      sells: number;
    };
  };
}

const ProfileImage = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;

const LiveDataCard: React.FC = () => {
  const [isRotated, setIsRotated] = useState<boolean[]>([false, false, false, false, false, false]);
  const [page, setPage] = useState(1);
  const { chainName } = useParams();

  // Firebase Configuration
  const firebaseConfig = {
    apiKey: "AIzaSyBtG7oiNd9DLuA80uwsWzGG7zX7legmGPE",
    authDomain: "protracker-6c490.firebaseapp.com",
    databaseURL: "https://protracker-6c490-default-rtdb.firebaseio.com",
    projectId: "protracker-6c490",
    storageBucket: "protracker-6c490.appspot.com",
    messagingSenderId: "776220672739",
    appId: "1:776220672739:web:d71e515bb8f33673403bd6",
    measurementId: "G-GMYRXFBSRK",
  };

  const app = initializeApp(firebaseConfig);
  const database = getDatabase(app);
  const userDataRef = ref(database, 'scam');
  const userDataRefs = ref(database, 'projects');

  const ITEMS_PER_PAGE = 10;

  const [searchTerm, setSearchTerm] = useState('');
  const [searchTerms, setSearchTerms] = useState('');
  const [userData, setUserData] = useState<any[]>([]);
  const [userDatas, setUserDatas] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [pairData, setPairData] = useState<PairData | null>(null);
  const [pairDataList, setPairDataList] = useState<PairData[]>([]);

  useEffect(() => {
    onValue(userDataRef, (snapshot) => {
      const data = snapshot.val();
      const filteredData = data ? Object.values(data) : [];

      if (searchTerm) {
        const searchTermLC = searchTerm.toLowerCase();
        const searchResults = filteredData.filter((user: any) =>
          user.name.toLowerCase().includes(searchTermLC)
        );
        setUserData(searchResults.reverse());
      } else {
        setUserData(filteredData.reverse());
      }
      setLoading(false);
    });
  }, [searchTerm]);

  useEffect(() => {
    onValue(userDataRefs, (snapshot) => {
      const datas = snapshot.val();
      const filteredDatas = datas ? Object.values(datas) : [];

      if (searchTerms) {
        const searchTermLCs = searchTerms.toLowerCase();
        const searchResultss = filteredDatas.filter((user: any) =>
          user.name.toLowerCase().includes(searchTermLCs)
        );
        setUserDatas(searchResultss.reverse());
      } else {
        setUserDatas(filteredDatas.reverse());
      }
      setLoading(false);
    });
  }, [searchTerms]);

  const totalPages = Math.ceil(userData.length / ITEMS_PER_PAGE);
  const lastItemIndex = currentPage * ITEMS_PER_PAGE;
  const firstItemIndex = lastItemIndex - ITEMS_PER_PAGE;
  const displayedUserData = userData.slice(firstItemIndex, lastItemIndex);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const createtr = (user: any, index: number) => (
    <tr key={user.userId || index} style={{ cursor: 'pointer' }}>
      <>
        <td>#{index + 1}</td>
        {userDatas[index] && (
          <td>
            <Link to={`/pair-explorers?id=${encodeURIComponent(user.userId)}`}>
              <ProfileImage src={userDatas[index].logoPicture} alt="Logo Picture" />
            </Link>
          </td>
        )}
        {userDatas[index] && (
          <td>
            <Link to={`/pair-explorers?id=${encodeURIComponent(user.id)}`} style={{ color: '#fff' }}>
              {userDatas[index].name}
            </Link>
          </td>
        )}
        <td>
          <a href={`${encodeURIComponent(user.link)}`} style={{ color: '#ffba00', textDecoration: 'underline' }}>
            View Report
          </a>
        </td>
      </>
    </tr>
  );

  const noScamMessage = (
    <tr>
      <td colSpan={4} style={{ textAlign: 'center', height: '70px' }}>
        No Scam Found
      </td>
    </tr>
  );

  return (
    <div className="top-data-card-wrapper">
      <div className="border-animation-wrapper">
        <div className="border-animation"></div>
      </div>
      <div className="live-data-card-container">
        <div className="live-data-card flex-item">
          <div className="daily-gainers-heading flex-item">
            <Icon style={{ color: 'white', width: 20 }} />
            <p>Recent Scams</p>
          </div>
          <div className="more-content flex-item">
            <Link to="./scam" style={{ fontSize: 12, color: '#ffba00' }}>
              More {">"}
            </Link>
          </div>
        </div>

        <table>
          <tbody>
            {displayedUserData.length > 0 ? (
              displayedUserData.map((user, index) => createtr(user, index))
            ) : (
              noScamMessage
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LiveDataCard;
