import React, { useState, useEffect, useRef } from "react";
import BscProject from './bscProject'; // Adjust the import path as needed
import BitProject from './bitProject'; // Adjust the import path as needed
import styled from "styled-components";
import { ReactComponent as Down } from "../../assets/icons/chevron-down.svg";

// Styled Components
const Section = styled.section`
  padding: 20px 0;
  @media (max-width: 768px) {
    max-width: 100%;
    overflow: auto;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  @media (max-width: 768px) {
    gap:30px;
  }
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

// Custom Dropdown Styled Components
const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownButton = styled.button`
  background-color: transparent; /* Background color */
  color: #ffba00; /* Text color */
  border:none;
  border-bottom: 1px solid #ffba00; /* Border for dropdown */
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  width: 130px; /* Adjust width as needed */
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    border-color: #a37800; /* Change border color on hover */
  }
`;

const DropdownList = styled.ul`
  position: absolute;
  background-color: black; /* Background for dropdown options */
  color: #ffba00; /* Text color for options */
  list-style: none;
  padding: 0;
  margin: 0;
  font-size:13px;
  border: 1px solid #a37800; /* Border for dropdown */
  border-radius: 4px;
  z-index: 1;
  width: 100%;
  max-height: 200px; /* Set max height for dropdown */
  overflow-y: auto; /* Enable scrolling */
`;

const DropdownItem = styled.li`
  padding: 10px 15px;
  cursor: pointer;

  &:hover {
  }
`;

// Define the props type for Icon
interface IconProps {
  isOpen: boolean;
}

const Icon = styled.span<IconProps>`
  margin-left: 10px;
  transition: transform 0.2s ease;
  width: 13px; /* Set the width to 10px */
  height: 13px; /* Set the height to 10px */
  ${(props) => props.isOpen && "transform: rotate(180deg);"}

  svg {
    width: 100%; /* Ensure SVG takes the full width */
    height: auto; /* Maintain aspect ratio */
    fill: #ffba00; /* Ensure the SVG is filled with the desired color */
  }
`;

const AuditedProjects: React.FC = () => {
  const [selectedChain, setSelectedChain] = useState('8BIT Chain');
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleChainChange = (chain: string) => {
    setSelectedChain(chain);
    setIsOpen(false); // Close dropdown after selection
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Section>
      <Container>
        <Header>
          <header>
            <h2
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: 900,
                color: "#fff",
                marginLeft: 10,
              }}
            >
              Listed Projects
            </h2>
          </header>
        </Header>
        <DropdownContainer ref={dropdownRef}>
          <DropdownButton onClick={toggleDropdown}>
            {selectedChain}
            <Icon isOpen={isOpen}><Down /></Icon> {/* Dropdown icon */}
          </DropdownButton>
          {isOpen && (
            <DropdownList>
              <DropdownItem onClick={() => handleChainChange("8BIT Chain")}>
                8BIT Chain
              </DropdownItem>
              <DropdownItem onClick={() => handleChainChange("BSC Chain")}>
                BSC Chain
              </DropdownItem>
            </DropdownList>
          )}
        </DropdownContainer>
      </Container>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {selectedChain === '8BIT Chain' ? <BitProject /> : <BscProject />}
      </div>
    </Section>
  );
};

export default AuditedProjects;
