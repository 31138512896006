import React, { FC } from "react";
import { useContractReads ,erc20ABI} from "wagmi";
import { formatEther } from "ethers";
import { pairABI } from "../../../utils/abis/pairABI";

interface ReservesProps {
  pairAddress: string;
  token: number;

}

const FetchReserves: FC<ReservesProps> = ({ pairAddress,token }) => {
  const { data, isError, isLoading } = useContractReads({
    contracts: [
      {
        abi: pairABI,
        address: pairAddress as `0x${string}`,
        functionName: "getReserves",
      },
      {
        abi: pairABI,
        address: pairAddress as `0x${string}`,
        functionName: "token0",
      },
      {
        abi: pairABI,
        address: pairAddress as `0x${string}`,
        functionName: "token1",
      },
    ],
  });

  const token0 = data && data[1]?.status === "success" ? data[1].result : null;
  const token1 = data && data[2]?.status === "success" ? data[2].result : null;
  const { data: tokenData } = useContractReads({
    contracts: [
      {
        abi: erc20ABI,
        address: token0 || (token0 as any),
        functionName: "symbol",
      },
      {
        abi: erc20ABI,
        address: token1 || (token1 as any),
        functionName: "symbol",
      },
    ],
  });

  if (isLoading) return <div>Loading reserves...</div>;
  if (isError) return <div>Error fetching reserves</div>;

  const reservesData = data && data[0]?.status === "success" ? data[0].result : null;


  const token0Reserve = reservesData ? reservesData[0] : null; // Reserve for token0
  const formattedToken0Reserve = token0Reserve ? formatEther(token0Reserve.toString()) : null;

  const token1Reserve = reservesData ? reservesData[1] : null; // Reserve for token0
  const formattedToken1Reserve = token1Reserve ? formatEther(token1Reserve.toString()) : null;

  return (
    <>
      {token===0 && token0 && formattedToken0Reserve ? (
<>
          <p style={{ color: 'white' }}>
            Pooled {tokenData?.[0].result}
          </p>
          <p style={{ color: 'white' }}>
      {parseFloat(formattedToken0Reserve).toFixed(2)} {tokenData?.[0].result}
          </p>
          </>      ) : (
        <></>
      )}
            {token===1 && token1 && formattedToken1Reserve ? (
<>
          <p style={{ color: 'white' }}>
            Pooled {tokenData?.[1].result}
          </p>
          <p style={{ color: 'white' }}>
      {parseFloat(formattedToken1Reserve).toFixed(2)} {tokenData?.[1].result}
          </p>
          </>      ) : (
        <></>
      )}
      </>
  );
};

export default FetchReserves;
