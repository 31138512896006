import { IToken } from "../constants/types";


export const ROUTER_ADDRESS = {
  1: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
  56: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
  97: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
  // 97: "0x3BC2945e1AA21eac6D8fFE47c3F5558eAFFAE9f8",
  // 3124: "0x617826746c26Fea45d8eF654496512DA3238763d",
  3124: "0x15b34c554802B7877bA4AA11A0039D61D176c0f9",
  8088: "0x9cf0bA22fcD5a9d850560e89a054a0d0655169e2",
};

export const DEFAULT_FROM_TOKEN = {
  1: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", // Ethereum Mainnet
  56: "0x111111111117dC0aa78b770fA6A738034120C302", // Binance Smart Chain
};

export const DEFAULT_TO_TOKEN = {
  1: "0xbc6378faae98fb2207bb6c35c0f8ce5846fd4c6c", // Ethereum Mainnet
  56: "0xbc6378faae98fb2207bb6c35c0f8ce5846fd4c6c", // Binance Smart Chain
};

export const FACTORY_ADDRESS = {
  1: "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f",
  56: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73",
  97: "0x6725F303b657a9451d8BA641348b6761A6CC7a17",
  // 97: "0x15cd495A653A387Bed23eF390B577c2952E8298b",
  // 3124: "0xFDf3998212001B84cbdD12426E77913bb486db57"
  3124: "0xB868A97f68251910852Ede9A1dF09c7A5020a609",
  8088: "0x6e17d94b0AB4fE406eB70FBFb525eb3C45998591",
};

export const WRAPPED_TOKEN_ADDRESS = {
  1: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  56: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
  97: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
  // 97: "0xaD5e595eEeE56D70Dad0a9E118265Ea4A69febE8",
  // 3124: "0xF921979441cCb7311Fa9f4e648b244D8A4db4fC3",
  3124: "0xb53c0C3f22b42F66c6Dd5567262DD92a307eEDf5",
  8088: "0x0cA45E0779FAE177F26f6abF459CF03d9B23D012",
};
export const WETH_TOKEN = {
  1: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", // Ethereum Mainnet
  56: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c", // WBNB on Binance Smart Chain
  8088:"0x0cA45E0779FAE177F26f6abF459CF03d9B23D012"
};

// Add more configurations or objects as necessary for other chains.


export const DEFAULT_TOKENS = [
  
];

export const WRAPPED_TOKEN = {
  1: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  56: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
};

export const TO_TOKEN = {
  1: "0x111111111117dC0aa78b770fA6A738034120C302",
  56: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
};

const W8BIT_ADDRESS = {
  56: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
};

export { W8BIT_ADDRESS };


export const defaultTokens: IToken[] = [
  {
    tokenAddress: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
    symbol: "WBNB",
    decimals: 18,
    chainId: 97,
  },
  {
    tokenAddress: "0x55d398326f99059fF775485246999027B3197955",
    symbol: "USDT",
    decimals: 18,
    chainId: 56,
  },
  {
    tokenAddress: "0xb7Ae4eA886fAcE39D47a47f46D6AfAbcaAd5AF6E",
    symbol: "w8Bit",
    decimals: 18,
    chainId: 56,
  },

  {
    tokenAddress: "0x0cA45E0779FAE177F26f6abF459CF03d9B23D012",
    symbol: "w8Bit",
    decimals: 18,
    chainId: 8088,
  },
  {
    tokenAddress: "0xAD418b9A3D3D59CEc5BB7737b89cC75daFa198e5",
    symbol: "8bitUSD",
    decimals: 18,
    chainId: 8088,
  },
  {
    tokenAddress: "0x7285352269A1eFC07a0F4A8D6e7424ec4f3680C0",
    symbol: "Vibe",
    decimals: 18,
    chainId: 8088,
  },
];

export const usdtTokenAddress: IToken[] = [
  {
    tokenAddress: "0x021d8204095c0EED8C2C39e4e35a3B084641592D",
    symbol: "MTK",
    decimals: 18,
    chainId: 97,
  },
    {
    tokenAddress: "0xAD418b9A3D3D59CEc5BB7737b89cC75daFa198e5",
    symbol: "8bitUSD",
    decimals: 18,
    chainId: 8088,
  },
  {
    tokenAddress: "0xb7Ae4eA886fAcE39D47a47f46D6AfAbcaAd5AF6E",
    symbol: "W8Bit",
    decimals: 18,
    chainId: 56,
  },
  {
    tokenAddress: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    symbol: "USDC",
    decimals: 6,
    chainId: 1,
  },
  {
    tokenAddress: "0xEAAd3F8F37E052cDF6267338c0D20396Be472E25",
    symbol: "USDT",
    decimals: 18,
    chainId: 3124,
  },
];

export const defaultNativeTokens: IToken[] = [
  {
    tokenAddress: "",
    symbol: "tBNB",
    decimals: 18,
    chainId: 97,
  },
  {
    tokenAddress: "",
    symbol: "ETH",
    decimals: 18,
    chainId: 1,
  },
  {
    tokenAddress: "",
    symbol: "BNB",
    decimals: 18,
    chainId: 56,
  },
  {
    tokenAddress: "",
    symbol: "8BIT",
    decimals: 18,
    chainId: 3124,
  },
  {
    tokenAddress: "",
    symbol: "8BIT",
    decimals: 18,
    chainId: 8088,
  },
];
