import React, { useRef,useState, useEffect } from 'react';
import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";
import "../AI/TopPairDataCard.scss";
import { ILiveData } from "../../constants/types";
import { ReactComponent as Icons } from "../../assets/icons/topChat.svg"
import { ReactComponent as Searchso } from "../../assets/icons/search.svg"
import { ReactComponent as Down } from "../../assets/icons/chevron-down.svg";
import BitGainer from "./bitgainer";
import BscGainer from "./bscgainer";
import { initializeApp } from 'firebase/app';
import axios from "axios";
import { Link } from 'react-router-dom';
import Search from "../Search"
import { getDatabase, ref, onValue } from 'firebase/database';
import styled from 'styled-components';
import millify from "millify";
import { formatDistanceToNow } from 'date-fns';

const ProfileImage = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;



// Custom Dropdown Styled Components
const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownButton = styled.button`
  background-color: transparent; /* Background color */
  color: #ffba00; /* Text color */
  border:none;
  border-bottom: 1px solid #ffba00; /* Border for dropdown */
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  width: 90px; /* Adjust width as needed */
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    border-color: #a37800; /* Change border color on hover */
  }
`;

const DropdownList = styled.ul`
  position: absolute;
  background-color: black; /* Background for dropdown options */
  color: #ffba00; /* Text color for options */
  list-style: none;
  padding: 0;
  margin: 0;
  font-size:13px;
  border: 1px solid #a37800; /* Border for dropdown */
  border-radius: 4px;
  z-index: 1;
  width: 100%;
  max-height: 200px; /* Set max height for dropdown */
  overflow-y: auto; /* Enable scrolling */
`;

const DropdownItem = styled.li`
  padding: 10px 15px;
  cursor: pointer;

  &:hover {
  }
`;


// Define the props type for Icon
interface IconProps {
  isOpen: boolean;
}

const Icon = styled.span<IconProps>`
  margin-left: 10px;
  transition: transform 0.2s ease;
  width: 13px; /* Set the width to 10px */
  height: 13px; /* Set the height to 10px */
  ${(props) => props.isOpen && "transform: rotate(180deg);"}

  svg {
    width: 100%; /* Ensure SVG takes the full width */
    height: auto; /* Maintain aspect ratio */
    fill: #ffba00; /* Ensure the SVG is filled with the desired color */
  }
`;

const LiveDataCard:  React.FC = () => {

  const [selectedChain, setSelectedChain] = useState('8BIT');
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleChainChange = (chain: string) => {
    setSelectedChain(chain);
    setIsOpen(false); // Close dropdown after selection
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="top-data-card-wrapper">
      <div className="border-animation-wrapper">
        <div className="border-animation"></div>
      </div>
      <div className="live-data-card-container">
     

        <div className="live-data-card flex-item">
          <div className="daily-gainers-heading flex-item">
<Icons style={{color:'white',width:20}}/>
            <p>Top Gainers</p>
          </div>
          <div className="more-content flex-item">
          <DropdownContainer ref={dropdownRef}>
          <DropdownButton onClick={toggleDropdown}>
            {selectedChain}
            <Icon isOpen={isOpen}><Down /></Icon> {/* Dropdown icon */}
          </DropdownButton>
          {isOpen && (
            <DropdownList>
              <DropdownItem onClick={() => handleChainChange("8BIT")}>
                8BIT
              </DropdownItem>
              <DropdownItem onClick={() => handleChainChange("BSC")}>
                BSC
              </DropdownItem>
            </DropdownList>
          )}
        </DropdownContainer>
          </div>
        </div>
        
        {selectedChain === '8BIT' ? <BitGainer /> : <BscGainer />}

        
      </div>
    </div>
  );
};

export default LiveDataCard;
