import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import { ReactComponent as FileTextIcon } from '../../../assets/icons/file-text.svg';
import './PostAComment.scss';
import { addComment } from '../../../utils/commentService';
import { useWeb3Modal } from '@web3modal/react';
import { useAccount } from 'wagmi';
import firebase from 'firebase/app';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref as dataRef, push, set, Database } from 'firebase/database';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL, FirebaseStorage } from 'firebase/storage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// ... rest of your code

const PostAComment = () => {
  const { open } = useWeb3Modal();
  const { address } = useAccount();
  const [imageFile, setImageFile] = useState<File | null>(null);

  const handleSubmit = async (values: any, actions: FormikHelpers<any>) => {
    try {
      if (!address) {
        return toast.error("Connect wallet to post a comment", {
          theme: "dark",
        });
      }      const docId = "example-document-id"; // Replace with your logic for generating the docId

      // Firebase configuration
      const firebaseConfig = {
        apiKey: "AIzaSyBtG7oiNd9DLuA80uwsWzGG7zX7legmGPE",
  authDomain: "protracker-6c490.firebaseapp.com",
  databaseURL: "https://protracker-6c490-default-rtdb.firebaseio.com",
  projectId: "protracker-6c490",
  storageBucket: "protracker-6c490.appspot.com",
  messagingSenderId: "776220672739",
  appId: "1:776220672739:web:d71e515bb8f33673403bd6",
  measurementId: "G-GMYRXFBSRK"
      };
      const id = new URLSearchParams(window.location.search).get('id');

      // Initialize Firebase
      const app = initializeApp(firebaseConfig);
      const database: Database = getDatabase(initializeApp(firebaseConfig));

      const storage: FirebaseStorage = getStorage(app);

      const name = values.comment; // Assuming 'comment' is the equivalent of 'name' in your Firebase form

      // ... (other fields from the form)

      const currentDate = new Date();
      const status = currentDate.toISOString(); // You may need to format this date accordingly

      const pdf = address; // Replace with the appropriate form field name

      // Push user data to the Realtime Database
      const newUserRef = push(dataRef(database, `comments/${id}/`));
      const userKey = newUserRef.key;

      // Upload image to Firebase Storage
      const imageRef = storageRef(storage, `logo-pictures/${userKey}`);
      await uploadBytes(imageRef, imageFile!);
      const downloadURL = await getDownloadURL(imageRef);

      const userData = {
        name,
        status,
        pdf,
        logoPicture: downloadURL,
        userId: userKey,
        commentType: values.type === 'bullish' ? 'Bullish' : 'Bearish', // Optional: Use this if you want to store it as 'Bullish' or 'Bearish'
      };
      
      // Push user data to the Realtime Database
  

      set(newUserRef, userData);

      actions.resetForm();
      actions.setSubmitting(false);
      setImageFile(null);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Formik
      initialValues={{ comment: "", type: "bullish" }}
      validationSchema={Yup.object({
        comment: Yup.string().required("This field is required"),
      })}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <Form>
          <div className="post-a-comment-wrapper">
            <div className="comment-head">
              <h5>Post a Comment</h5>
            </div>

            <div className="comment-content">
              <div>
                <Field name="comment" type="text" placeholder="Enter your Comment" />
                <ErrorMessage name="comment" component="div" className="error-input" />
              </div>

              <div className="btns flex-item">
                <button
                  type="button"
                  onClick={() => setFieldValue("type", "bullish")}
                  style={{
                    backgroundColor: values.type === "bullish" ? "#228B22" : "transparent",
                    color: "#fff",
                    border: "1px solid #228B22",
                  }}
                >
                  Bullish
                </button>
                <button
                  type="button"
                  onClick={() => setFieldValue("type", "bearish")}
                  style={{
                    backgroundColor: values.type === "bearish" ? "#D22B2B" : "transparent",
                    color: "#fff",
                    border: "1px solid #D22B2B",
                  }}
                >
                  Bearish
                </button>
              </div>
              <div className="choose-input-file">
                <label htmlFor="choose-file">
                  <input
                    type="file"
                    placeholder="File"
                    required
                    accept=".jpg,.jpeg,.png,.svg,.gif,.webp"
                    id="choose-file"
                    onChange={(e) => {
                      const files = e.target.files;
                      if (files?.length) {
                        setImageFile(files[0]);
                      }
                    }}
                  />
                  <div className="flex-item">
                    <FileTextIcon />
                    <p>{imageFile ? imageFile.name : "Upload a image file"}</p>
                  </div>
                </label>
              </div>

              <div className="comment-btn flex-item">
                {!address ? (
                  <button type="button" onClick={open}>
                    connect wallet
                  </button>
                ) : (
                  <button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? "Commenting..." : "Comment"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default PostAComment;
